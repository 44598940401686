import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import { Mutation, Query } from 'react-apollo'
import { ACTUALIZAR_VISADO_SERVICIO } from '../../../mutations'
import FormVisadoCampos from './FormVisadoCampos'
import { OBTENER_105_ORDEN } from '../../../queries'
import Loading from '../../Loading'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormVisado = ({ handleCloseFormVisado, idServ, idOrden, refetch }) => {
	const [adjunto, setAdjunto] = useState('false')
	const [nombreOriginal, setNombreOriginal] = useState('')
	const handleFileChange = (valor) => {
		const extencion = valor.name.split('.').pop()
		const extraerNombreOriginal = valor.name
		var blob = valor.slice(0, valor.size, extencion)
		var newFile = new File([blob], uuidv4() + '.' + extencion, {
			type: valor.type,
		})
		setNombreOriginal(extraerNombreOriginal)
		setAdjunto(newFile)
	}
	return (
		<Query query={OBTENER_105_ORDEN} variables={{ idServicio: idServ, idOrden }} fetchPolicy={'network-only'}>
			{({ loading, error, data }) => {
				if (loading) return <Loading />
				if (error) return console.log(error.message)
				return (
					<Modal show="true" centered size="xl" onHide={handleCloseFormVisado}>
						<Modal.Header closeButton>
							<Modal.Title>PROTOCOLO DE MEDICIÓN DE LA PUESTA A TIERRA Y CONTINUIDAD DE LAS MASAS</Modal.Title>
						</Modal.Header>
						<Mutation
							mutation={ACTUALIZAR_VISADO_SERVICIO}
							onCompleted={(e) => {
								if (e.actualizarVisadoServicio.status) {
									if (e.uploadFile.status) {
										refetch()
										handleCloseFormVisado()
									} else {
										alert('el archivo no se subio')
									}
								} else {
									alert('Error al cargar Formulario')
								}
							}}
						>
							{(actualizarVisadoServicio) => (
								<Formik
									initialValues={{
										id: '',
										visado: '',
									}}
									onSubmit={() => {
										const id = data.obtener105Orden.id
										const visado = adjunto.name
										return actualizarVisadoServicio({
											variables: {
												id,
												idServ,
												idOrden,
												visado,
												visadoNombreOriginal: nombreOriginal,
												file: adjunto,
												folder: 'visados',
											},
										})
									}}
								>
									{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
										<Form onSubmit={handleSubmit} action="/upload" encType="multipart/form-data" method="POST">
											<ModalBody>
												<FormVisadoCampos handleChange={handleChange} handleFileChange={handleFileChange} />
											</ModalBody>
											<ModalFooter>
												<Row>
													<FooterButton>
														<Button variant="secondary" onClick={handleCloseFormVisado}>
															Cancelar
														</Button>
														<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
															Guardar
														</Button>
													</FooterButton>
												</Row>
											</ModalFooter>
										</Form>
									)}
								</Formik>
							)}
						</Mutation>
					</Modal>
				)
			}}
		</Query>
	)
}

export default FormVisado
