import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { ACTUALIZAR_USUARIO } from '../../mutations'
import styled from 'styled-components'
import FormUsuario from './Form'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const validate = Yup.object({
	usuario: Yup.string().email('Correo invalido').max(320, 'Máximo 320 de caracteres').required('El campo usuario es obligatorio y debe ser un email'),
	nombre: Yup.string().required('El campo nombre Iva es requerido').typeError('Debe ser un texto'),
	area: Yup.string().required('El campo puesto es requerido').typeError('Debe ser un texto'),
	rol: Yup.string().required('El campo rol es requerido').typeError('Debe ser un texto'),
})

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 100px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormEditarUsuario = ({ cliente, handleClose, refetch, handleNoti, esAdmin }) => {
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')

	const handleFileChange = (valor) => {
		const extencion = valor.name.split('.').pop()
		const extraerNombreOriginal = valor.name
		var blob = valor.slice(0, valor.size, extencion)
		var newFile = new File([blob], uuidv4() + '.' + extencion, {
			type: valor.type,
		})
		setNombreOriginal(extraerNombreOriginal)
		setAdjunto(newFile)
	}

	const { id, usuario, nombre, area, rol } = cliente
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Editar auxiliar de servicio</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={ACTUALIZAR_USUARIO}
				onCompleted={(e) => {
					console.log(e)
					handleClose()
					refetch()
					handleNoti(2)
				}}
			>
				{(crearCliente) => (
					<Formik
						initialValues={{
							id,
							usuario,
							nombre,
							area,
							rol,
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							console.log(values)
							const { usuario, nombre, area, rol } = values
							const input = {
								id,
								usuario,
								nombre,
								area,
								rol,
								adjunto: adjunto.name ? adjunto.name : undefined,
								adjuntoNombreOriginal: nombreOriginal ? nombreOriginal : undefined,
							}
							return crearCliente({ variables: { input, file: adjunto, folder: 'firmas' } })
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormUsuario
										errors={errors}
										handleChange={handleChange}
										touched={touched}
										values={cliente}
										esAdmin={esAdmin}
										handleFileChange={handleFileChange}
									/>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit">
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormEditarUsuario
