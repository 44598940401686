const CheckBox = ({ estado, id, handleActualizarDebe }) => {
	return (
		<input
			style={{ cursor: 'pointer' }}
			type="checkbox"
			defaultChecked={estado}
			onClick={(e) => {
				handleActualizarDebe(id, e.target.checked)
			}}
		/>
	)
}

export default CheckBox
