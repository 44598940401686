import styled from 'styled-components'
import BodyMenu from './BodyMenu'
import lenge from './../../img/lengeIco.png'
import lengeText from './../../img/lenge-texto.png'
import { useState } from 'react'
import FooterMenu from './FooterMenu'
const bgMenu = '#28A745'
const HeaderNav = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #fff;
	border-bottom: 2px solid #28a745;
`

const TitleMenu = styled.div`
	text-align: center;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-left: 20px;
	h2 {
		font-family: 'Montserrat', sans-serif;
		font-size: 1.5rem;
		color: #000;
	}
	img {
		width: 250px;
	}
`
const BarsContainer = styled.div`
	text-align: center;
	width: 70px;
	height: 70px;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	div {
		cursor: pointer;
	}
	img {
		width: 40px;
	}
`
const DivNav = styled.div`
	background: ${bgMenu};
	position: fixed;
	top: 0;
	left: 0;
	height: calc(100%);
	width: ${({ ancho }) => (ancho ? '30%' : '70px')};
	z-index: 2;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-webkit-box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
	box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
`
const MenuLateral = ({ usuario, permiso, rolText }) => {
	const [menu, setMenu] = useState(false)
	const handleMenu = () => setMenu(!menu)
	const handleCloseMenu = () => setMenu(false)
	return (
		<DivNav ancho={menu} onMouseLeave={() => setMenu(false)}>
			<HeaderNav>
				{menu ? (
					<TitleMenu>
						<img src={lengeText} alt="" />
					</TitleMenu>
				) : (
					''
				)}
				<BarsContainer className="bar" onClick={handleMenu}>
					<div>
						<img src={lenge} alt="" />
					</div>
				</BarsContainer>
			</HeaderNav>
			<BodyMenu menu={menu} handleCloseMenu={handleCloseMenu} permiso={permiso} />
			<FooterMenu menu={menu} handleMenu={handleMenu} usuario={usuario} rolText={rolText} />
		</DivNav>
	)
}

export default MenuLateral
