import { Col, Form, Row } from 'react-bootstrap'
const FormCliente = ({ errors, handleChange, touched, values }) => {
	const { nombreEstablecimiento, codigoPostal, direccion, localidad, modulo } = values
	return (
		<div>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="nombreEstablecimiento">
					<Form.Label>Nombre del establecimiento</Form.Label>
					<Form.Control
						type="text"
						name="nombreEstablecimiento"
						onChange={handleChange}
						placeholder="Nombre del establecimiento"
						isInvalid={touched.nombreEstablecimiento && errors.nombreEstablecimiento}
						defaultValue={nombreEstablecimiento ? nombreEstablecimiento : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.nombreEstablecimiento && errors.nombreEstablecimiento}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="codigoPostal">
					<Form.Label>Código Postal</Form.Label>
					<Form.Control
						type="text"
						name="codigoPostal"
						onChange={handleChange}
						placeholder="Código Postal"
						isInvalid={touched.codigoPostal && errors.codigoPostal}
						defaultValue={codigoPostal ? codigoPostal : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.codigoPostal && errors.codigoPostal}</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="direccion">
					<Form.Label>Dirección</Form.Label>
					<Form.Control
						type="text"
						name="direccion"
						onChange={handleChange}
						placeholder="Dirección"
						isInvalid={touched.direccion && errors.direccion}
						defaultValue={direccion ? direccion : ''}
					/>

					<Form.Control.Feedback type="invalid">{touched.direccion && errors.direccion}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="localidad">
					<Form.Label>Localidad</Form.Label>
					<Form.Control
						type="text"
						name="localidad"
						onChange={handleChange}
						placeholder="Localidad"
						isInvalid={touched.localidad && errors.localidad}
						defaultValue={localidad ? localidad : ''}
					/>

					<Form.Control.Feedback type="invalid">{touched.localidad && errors.localidad}</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group className="mb-3 col-md-6" as={Col} controlId="modulo">
					<Form.Label>Módulo</Form.Label>
					<Form.Control
						type="text"
						name="modulo"
						onChange={handleChange}
						placeholder="Cantidad de módulos"
						isInvalid={touched.modulo && errors.modulo}
						defaultValue={modulo ? modulo.toString().replace('.', ',') : 0}
					/>

					<Form.Control.Feedback type="invalid">{touched.modulo && errors.modulo}</Form.Control.Feedback>
				</Form.Group>
			</Row>
		</div>
	)
}

export default FormCliente
