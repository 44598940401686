import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { CREAR_SUCURSAL } from '../../mutations'
import styled from 'styled-components'
import FormCliente from './FormCliente'

const validate = Yup.object({
	nombreEstablecimiento: Yup.string().required('El campo Razón Social es obligatorio'),
	codigoPostal: Yup.string().required('El campo Condición Iva es requerido'),
	direccion: Yup.string().required('El campo Teléfono es requerido'),
	localidad: Yup.string().required('El campo Email es requerido'),
})
const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const AltaCliente = ({ refetch, handleClose, handleNoti, idCliente }) => {
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Nueva Sucursal</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={CREAR_SUCURSAL}
				onCompleted={() => {
					refetch()
					handleClose()
					handleNoti(1)
				}}
			>
				{(crearCliente) => (
					<Formik
						initialValues={{
							id: '',
							idCliente: idCliente,
							nombreEstablecimiento: '',
							codigoPostal: '',
							direccion: '',
							localidad: '',
							modulo: '',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { nombreEstablecimiento, idCliente, codigoPostal, direccion, localidad, modulo } = values
							const input = {
								idCliente,
								nombreEstablecimiento,
								codigoPostal,
								direccion,
								localidad,
								modulo: modulo === '' ? null : parseFloat(modulo.toString().replace(',', '.')),
							}

							return crearCliente({ variables: { input } })
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormCliente errors={errors} handleChange={handleChange} touched={touched} values={''} />
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
												Agregar sucursal
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default AltaCliente
