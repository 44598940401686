import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Mutation } from 'react-apollo'
import { Button } from 'react-bootstrap'
import { permisosById } from './Permisos'
import { faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ELIMINAR_ADJUNTO_OT } from '../../mutations'

export const mostrarAdjuntos = (adjuntos, refetch, idOrden, permiso) => {
	return adjuntos.map((adjunto, index) => {
		return (
			<div key={index}>
				<a href={`https://lenge.s3.amazonaws.com/adjuntosot/${adjunto.adjunto}`} target="_blank" rel="noreferrer" style={{ marginTop: '10px' }}>
					<Button style={{ fontSize: '18px' }}>
						{adjunto.adjuntoNombreOriginal}
						<FontAwesomeIcon icon={faFilePdf} style={{ marginLeft: '10px' }} />
					</Button>
				</a>
				{permiso < 5 && (
					<Mutation
						mutation={ELIMINAR_ADJUNTO_OT}
						onCompleted={() => {
							refetch()
						}}
					>
						{(eliminar) => (
							<Button
								style={{ marginLeft: '20px' }}
								variant="danger"
								onClick={() => {
									if (window.confirm('¿Estas seguro de eliminar el archivo adjunto?')) {
										return eliminar({
											variables: {
												id: idOrden,
												adjunto: adjunto.adjunto,
											},
										})
											.then((e) => {})
											.catch((e) => {
												console.log(e, 'error')
											})
									}
								}}
							>
								<FontAwesomeIcon icon={faTrash} />
							</Button>
						)}
					</Mutation>
				)}
				<br />
				<br />
			</div>
		)
	})
}
