import { Mutation } from 'react-apollo'
import { UPLOAD_FILE } from '../mutations'
export const UploadForm = () => {
	return (
		<div>
			<Mutation
				mutation={UPLOAD_FILE}
				onCompleted={() => {
					alert('ok')
				}}
			>
				{(uploadFile) => (
					<>
						<h2>Upload</h2>
						<input
							type="file"
							name="subir"
							id="subir"
							onChange={(e) => {
								if (!e.target.files[0]) return
								const subido = uploadFile({
									variables: { file: e.target.files[0] },
								})
								subido.then((e) => {})
							}}
						/>
					</>
				)}
			</Mutation>
		</div>
	)
}
