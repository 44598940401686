import React, { useState } from 'react'
import { Query, useMutation } from 'react-apollo'
import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import { ADD_PERIODO } from '../../../../mutations'
import { SERVICIO111 } from '../../../../queries'
import FormGeneral from '../../../Formularios/FormGeneral/FormGeneral'

import Loading from '../../../Loading'
import Listar111Periodo from './Listar111Periodo'

const TitleStyle = styled.div`
	display: flex;
	.add_orden {
		background: #28a745;
		color: #fff;
		display: flex;
		justify-content: center;
		width: 24px;
		height: 24px;
		cursor: pointer;
		font-size: 15px;
		margin-left: 10px;
		margin-top: 3px;
		border-radius: 4px;
	}
`
const Listar111Interno = ({ id, periodicidad, datos, refetch111 }) => {
	const { id: id111, idCliente, idSucursal, idServicio, idOrden, cliente, sucursal } = datos

	const [showGeneral, setShowGeneral] = useState(false)
	const [periodo, setperiodo] = useState('')

	const handleCloseShowGeneral = () => {
		setShowGeneral(false)
	}

	const handleShowGeneral = (periodoNmero) => {
		setperiodo(periodoNmero)
		setShowGeneral(true)
	}
	const [view, setView] = useState(true)
	const [disabledAddPeriodo, setDisabledAddPeriodo] = useState(false)
	const [addPeriodo] = useMutation(ADD_PERIODO)
	return (
		<>
			<Query query={SERVICIO111} variables={{ id }}>
				{({ loading, error, data, refetch }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					let periodosTotales = []
					let texto = ''
					switch (data.getServicio111.item) {
						case 'D':
						case 'E':
						case 'F':
						case 'G':
						case 'I':
						case 'J':
						case 'K':
						case 'M':
						case 'O':
							texto = `${data.getServicio111.nombre} (${data.getServicio111.item}) - Servicio a demanda`
							break

						default:
							texto = `${data.getServicio111.nombre} (${data.getServicio111.item}) - Período ${periodo}`
							break
					}
					if (periodicidad !== 'demanda') {
						datos.serviciosPorPeriodo.map((e, index) => {
							return periodosTotales.push(
								<Listar111Periodo
									datos={e}
									key={index}
									handleShowGeneral={handleShowGeneral}
									item={data.getServicio111.item}
									cliente={cliente}
									sucursal={sucursal}
								/>
							)
						})
					}
					if (showGeneral) {
						// if (data.getServicio111.item === 'L') {
						// 	return (
						// 		<FormL
						// 			nombreServ={texto}
						// 			handleClose={handleCloseShowGeneral}
						// 			id={idCliente}
						// 			idSuc={idSucursal}
						// 			idServ={idServicio}
						// 			idOrden={idOrden}
						// 			idSubServicio={datos.id}
						// 			periodo={periodo}
						// 			refetch={refetch111}
						// 		/>
						// 	)
						// }

						return (
							<FormGeneral
								nombreServ={texto}
								handleClose={handleCloseShowGeneral}
								id={idCliente}
								idSuc={idSucursal}
								idServ={idServicio}
								idOrden={idOrden}
								idSubServicio={datos.id}
								periodo={periodo}
								refetch={refetch}
								es_111={true}
								item={data.getServicio111.item}
							/>
						)
					}
					return (
						<div key={data.getServicio111.id} style={{ marginBottom: '10px' }}>
							<Card>
								<Card.Header
									onClick={() => {
										setView(!view)
									}}
									style={{ cursor: 'pointer' }}
								>
									<TitleStyle>
										<h5>
											({data.getServicio111.item}) - {data.getServicio111.nombre}
										</h5>
										{disabledAddPeriodo === true && (
											<span style={{ fontSize: '13px', fontStyle: 'italic', marginLeft: '10px', marginTop: '4px', color: '#c2c2c2' }}>
												Agregando periódo ...
											</span>
										)}
										{disabledAddPeriodo === false && (
											<span
												className="add_orden"
												onClick={async () => {
													setView(false)
													setDisabledAddPeriodo(true)
													await addPeriodo({ variables: { id: id111 } })
														.then(async () => {
															await refetch111()
														})
														.then(() => {
															setDisabledAddPeriodo(false)
															setView(true)
														})
												}}
											>
												+
											</span>
										)}
									</TitleStyle>
								</Card.Header>
								{periodosTotales.length > 0 && (
									<Card.Body style={{ display: view ? '' : 'none' }} id={'body_' + data.getServicio111.id}>
										{periodosTotales}
									</Card.Body>
								)}
							</Card>
						</div>
					)
				}}
			</Query>
		</>
	)
}

export default Listar111Interno
