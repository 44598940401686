import { Query } from 'react-apollo'
import { CLIENTE_Y_SUCURSAL } from '../../../queries'
import { setFecha } from '../../../hooks/setFecha'

import Loading from '../../Loading'

export const DatosClientePdf = ({ id, idSuc }) => {
	return (
		<>
			<Query query={CLIENTE_Y_SUCURSAL} variables={{ id, idSuc }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					const { cuit, razonSocial } = data.getCliente
					const { direccion, localidad, provincia, codigoPostal } = data.getSucursal
					return (
						<tbody>
							<tr>
								<td>
									<b>Razón Social:</b> <b>{razonSocial}</b>
								</td>
								<td>
									<b>Dirección:</b> {direccion}
								</td>
							</tr>
							<tr>
								<td>
									<b>Localidad:</b> {localidad}
								</td>
								<td>
									<b>Provincia:</b> {provincia}
								</td>
							</tr>
							<tr>
								<td>
									<b>Código Postal:</b> {codigoPostal}
								</td>
								<td>
									<b>Cuit:</b> {cuit}
								</td>
							</tr>
						</tbody>
					)
				}}
			</Query>
		</>
	)
}

export const DatosClientePdf111 = ({ cliente, sucursal }) => {
	return (
		<tbody>
			<tr>
				<td>
					<b>Razón Social:</b> <b>{cliente.razonSocial}</b>
				</td>
				<td>
					<b>Dirección:</b> {sucursal.direccion}
				</td>
			</tr>
			<tr>
				<td>
					<b>Localidad:</b> {sucursal.localidad}
				</td>
				<td>
					<b>Provincia:</b> {sucursal.provincia}
				</td>
			</tr>
			<tr>
				<td>
					<b>Código Postal:</b> {sucursal.codigoPostal}
				</td>
				<td>
					<b>Cuit:</b> {cliente.cuit}
				</td>
			</tr>
		</tbody>
	)
}

export const DatosClientePdf111L = ({ cliente, sucursal, fecha, hora }) => {
	const nuevaFecha = fecha.replace('-', '/')
	return (
		<tbody>
			<tr>
				<td>
					<b>Empresa:</b> <b>{cliente.razonSocial}</b>
				</td>
			</tr>
			<tr>
				<td>
					<b>Dirección:</b> {sucursal.direccion}
				</td>
			</tr>
			<tr>
				<td>
					<b>Fecha:</b> {setFecha(nuevaFecha)}
				</td>
				<td>
					<b>Hora:</b> {hora}
				</td>
			</tr>
		</tbody>
	)
}

// export { DatosClientePdf, DatosClientePdf111 }
