import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import { useState } from 'react'
import Menu from '../Menus/Menu'
import DatosCliente from './DatosCliente'
import { Redirect, useParams } from 'react-router-dom'
import ListarParaLegajo from './ListarParaLegajo'
import TablaServicios from './TablaServicios'
const SucursalStyleDiv = styled.div`
	display: flex;
	height: calc(100vh - 80px);
	overflow: hidden;
	margin-top: 80px;
	flex-wrap: wrap;
	margin-left: 6em;
`

const OrdenesStyleDiv = styled.div`
	width: 100%;
	position: relative;
	height: 100%;
	table {
		width: 100%;
		padding: 0;
		margin: 0;
		tbody {
			tr {
				border-bottom: 1px solid #ccc;
				cursor: pointer;
				&:nth-last-child(1) {
					border-bottom: none;
				}
			}
			td {
				padding: 5px;
				img {
					-webkit-filter: invert(100%);
					filter: invert(100%);
					transform: rotate(90deg);
				}
			}
		}
		.centrado {
			button {
				border: none;
				background: #007bff;
				color: #fff;
				font-size: 0.8em;
			}
		}
	}
	.card {
		height: 100%;
		.card-body {
			height: 100%;
		}
	}
	.body_table {
		height: 90%;
		overflow: auto;
	}
	.title_orden {
		display: flex;
		justify-content: space-between;
		.add_orden {
			background: #28a745;
			color: #fff;
			display: block;
			text-align: center;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			padding-top: 1px;
			padding-right: 1px;
			cursor: pointer;
		}
	}
`
const CardStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: center;
	.header_card {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`
const IzquierdaDiv = styled.div`
	width: 30%;
	height: calc(100vh - 90px);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
	.listado {
		height: 65%;
	}
`
const DerechaDiv = styled.div`
	width: 70%;
	height: 100%;
	padding-left: 3em;
	padding-right: 3em;
`

const Legajo = ({ obtenerUsuario, permiso }) => {
	const { id: idCliente, sucursal: idSucursal, area } = useParams()
	let areaText = ''
	if (area === 'hyst') {
		areaText = 'Higiene y Seguridad del Trabajo'
	}
	if (area === 'ma') {
		areaText = 'Medio Ambiente'
	}
	if (area === 'gm') {
		areaText = 'Gestión Municipal'
	}
	const [show, setShow] = useState(false)
	const [datos, setDatos] = useState('')
	const [title, setTitle] = useState('')
	const handleShow = (title, data) => {
		setDatos('')
		setShow(true)
		setTitle(title)
		setDatos(data)
	}
	const handleClose = () => {
		setShow(false)
	}
	return (
		<>
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo={`Legajo ${areaText}`} />
			<SucursalStyleDiv>
				<IzquierdaDiv>
					<div className="listado">
						<OrdenesStyleDiv>
							<Card style={{ width: '100%' }}>
								<Card.Header className="title_orden">Listado de Servicios</Card.Header>
								<Card.Body>
									<div className="body_table">
										<TablaServicios handleShow={handleShow} idSucursal={idSucursal} area={area} />
									</div>
								</Card.Body>
							</Card>
						</OrdenesStyleDiv>
					</div>
					<CardStyleDiv>
						<Card style={{ width: '100%' }}>
							<Card.Header className="header_card">
								<span>Datos del Cliente</span>{' '}
							</Card.Header>
							<Card.Body>
								<DatosCliente id={idCliente} idSuc={idSucursal} />
							</Card.Body>
						</Card>
					</CardStyleDiv>
				</IzquierdaDiv>
				<DerechaDiv>{show && <ListarParaLegajo datos={datos} title={title} handleClose={handleClose} permiso={permiso} />}</DerechaDiv>
			</SucursalStyleDiv>
		</>
	)
}

export default Legajo
