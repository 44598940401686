import styled from 'styled-components'

const DivPaginator = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
	padding-bottom: 30px;
	button {
		margin-left: 20px;
	}
`
const Paginador = ({ actual, total, limite, paginaSiguiente, paginaAnterior }) => {
	const btnAnterior =
		actual > 1 ? (
			<button type="button" className="btn btn-success mr-2" onClick={paginaAnterior}>
				&laquo; Anterior
			</button>
		) : (
			''
		)
	const paginas = Math.ceil(Number(total) / limite)

	const btnSiguiente =
		actual !== paginas ? (
			<button type="button" className="btn btn-success" onClick={paginaSiguiente}>
				&raquo; Siguiente
			</button>
		) : (
			''
		)
	return (
		<DivPaginator>
			{btnAnterior}
			{btnSiguiente}
		</DivPaginator>
	)
}

export default Paginador
