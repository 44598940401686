import { USUARIO } from '../../queries'
import { Query } from 'react-apollo'
import FormEditarUsuario from './FormEditar'
import Loading from '../Loading'

const EditarUsuario = ({ id, handleClose, refetch, handleNoti, esAdmin }) => {
	return (
		<>
			<Query query={USUARIO} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return (
						<FormEditarUsuario cliente={data.obtenerUsuario} refetch={refetch} handleClose={handleClose} handleNoti={handleNoti} esAdmin={esAdmin} />
					)
				}}
			</Query>
		</>
	)
}

export default EditarUsuario
