import Form120 from '../Formularios/Form120/Form120'
import FormGeneral from '../Formularios/FormGeneral/FormGeneral'

const Formularios = ({ itemServicio, handleClose, idOrden, idSucursal, idCliente, idServ, nombre, refetch, es_120 = null }) => {
	if (itemServicio !== '111' && itemServicio !== '120') {
		return (
			<FormGeneral
				handleClose={handleClose}
				idOrden={idOrden}
				idSuc={idSucursal}
				id={idCliente}
				idServ={idServ}
				nombreServ={nombre}
				refetch={refetch}
				es_120={es_120}
			/>
		)
	}
	if (itemServicio === '111') {
		return (
			<Form120 handleClose={handleClose} idOrden={idOrden} idSuc={idSucursal} id={idCliente} idServ={idServ} nombreServ={nombre} refetch={refetch} />
		)
	}
	if (itemServicio === '120') {
		return (
			<Form120 handleClose={handleClose} idOrden={idOrden} idSuc={idSucursal} id={idCliente} idServ={idServ} nombreServ={nombre} refetch={refetch} />
		)
	}
}

export default Formularios
