import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { AUTENTICAR_USUARIO, RECUPERAR_PASSWORD } from '../../mutations'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'
import Loading from '../Loading'

const SetForm = styled.div`
	margin-top: 30px;
	form {
		width: 270px;
	}
	input {
		border-radius: 0;
	}
	button {
		margin-top: 5px;
		border-radius: 0;
		width: 100%;
	}
	p {
		margin-top: 5px;
		font-size: 12px;
		color: #868e96;
		cursor: pointer;
	}
	a {
		color: #fff;
		text-decoration: none;
	}
	.footer_password {
		display: flex;
		justify-content: space-between;
	}
`
const SetError = styled.div`
	text-align: center;
	p {
		color: red;
	}
`

export const FormLogin = ({ refetch }) => {
	const [errorLogin, setErrorLogin] = useState(false)
	const validate = Yup.object({
		email: Yup.string().email('Correo invalido').max(320, 'Maximo 320 de caracteres').required('El campo email es obligatorio para iniciar sesion'),
		password: Yup.string().required('La contraseña es requerida para iniciar sesion'),
	})

	return (
		<SetForm>
			<Mutation mutation={AUTENTICAR_USUARIO} onCompleted={() => {}}>
				{(autenticarUsuario, { loading, error, data }) => {
					loading && <Loading />
					return (
						<Formik
							initialValues={{ email: '', password: '' }}
							validationSchema={validate}
							onSubmit={(values) => {
								const { email, password } = values
								return autenticarUsuario({
									variables: { usuario: email, password },
								})
									.then(async ({ data }) => {
										localStorage.setItem('token', data.autenticarUsuario.token)
										await refetch()
									})
									.catch((e) => {
										if (e.message) {
											setErrorLogin(true)
										}
									})
							}}
						>
							{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
								<Form onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control onChange={handleChange} type="email" placeholder="Ingresar email" name="email" isInvalid={touched.email && errors.email} />
										<Form.Control.Feedback type="invalid">{touched.email && errors.email}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Contraseña</Form.Label>
										<Form.Control onChange={handleChange} type="password" placeholder="Ingresar contraseña" name="password" isInvalid={touched.password && errors.password} />
										<Form.Control.Feedback type="invalid">{touched.password && errors.password}</Form.Control.Feedback>
									</Form.Group>
									{errorLogin ? (
										<SetError>
											<p>Usuario y/o password incorrectos</p>
										</SetError>
									) : (
										''
									)}
									<Button variant="primary" type="submit" disabled={isSubmitting}>
										Iniciar Sesión
									</Button>
									<div className="footer_password">
										<Link to="/recuperar_password">
											<p>Recuperar contraseña &gt;</p>
										</Link>
										<Link to="/registro">
											<p>Registro &gt;</p>
										</Link>
										<p>v. 1.02.63</p>
									</div>
								</Form>
							)}
						</Formik>
					)
				}}
			</Mutation>
		</SetForm>
	)
}
export const FormRecupero = () => {
	const [errorLogin, setErrorLogin] = useState(false)
	let history = useHistory()
	const validate = Yup.object({
		email: Yup.string().email('Correo invalido').max(320, 'Maximo 320 de caracteres').required('El campo email es obligatorio para recuperar la contraseña'),
	})
	return (
		<SetForm>
			<Mutation mutation={RECUPERAR_PASSWORD} onCompleted={() => {}}>
				{(autenticarUsuario, { loading, error, data }) => {
					return (
						<Formik
							initialValues={{ email: '' }}
							validationSchema={validate}
							onSubmit={(values) => {
								setErrorLogin(false)
								const { email } = values
								return autenticarUsuario({
									variables: { usuario: email },
								})
									.then(async ({ data }) => {
										// localStorage.setItem('token', data.autenticarUsuario.token)
										// await refetch()
										// console.log(data)
										alert('Por favor revise su correo electrónico, para continuar con el recupero de la contraseña')
										history.push('/login')
									})
									.catch((e) => {
										if (e.message) {
											setErrorLogin(true)
										}
									})
							}}
						>
							{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
								<Form onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control onChange={handleChange} type="email" placeholder="Ingresar email" name="email" isInvalid={touched.email && errors.email} />
										<Form.Control.Feedback type="invalid">{touched.email && errors.email}</Form.Control.Feedback>
									</Form.Group>
									<Button variant="primary" type="submit" disabled={isSubmitting}>
										Recuperar Contraseña
									</Button>
									<Link to="/login">
										<p>&lt; Volver a Login</p>
									</Link>
									{errorLogin ? (
										<SetError>
											<p>Usuario incorrecto</p>
										</SetError>
									) : (
										''
									)}
								</Form>
							)}
						</Formik>
					)
				}}
			</Mutation>
		</SetForm>
	)
}
