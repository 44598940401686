import { useQuery } from 'react-apollo'
import { USUARIOS } from '../../queries'
import { useState } from 'react'

import AltaUsuario from './Alta'
import EditarUsuario from './Editar'
import EliminarUsuario from './Eliminar'
import Paginador from '../Paginador'

import Lateral from '../Lateral/Lateral'
import { ToastContainer, toast } from 'react-toastify'
import { Redirect } from 'react-router'
import Menu from '../Menus/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import TableList from './TableList'
import { ClientesStyleDiv } from '../Style/Style'
import Loading from '../Loading'
import ReasignarOT from './ReasignarOT'

const Usuarios = ({ obtenerUsuario, esAdmin = false, permiso }) => {
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [info, setInfo] = useState({})
	const [offset, setOffset] = useState(0)
	const [actual, setActual] = useState(1)
	const [showAdd, setShowAdd] = useState(false)
	const [showReasignar, setShowReasignar] = useState(false)
	const [noti, setNoti] = useState(false)
	const [notiEdit, setNotiEdit] = useState(false)
	const [notiDelete, setNotiDelete] = useState(false)
	const [search, setSearch] = useState(null)
	const [orderBy, setOrderBy] = useState('nombre')
	const [asc, setAsc] = useState(1)

	const limite = 10
	const paginaAnterior = () => {
		setOffset(offset - limite)
		setActual(actual - 1)
	}
	const paginaSiguiente = () => {
		setOffset(offset + limite)
		setActual(actual + 1)
	}
	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}
	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}
	const handleCloseAdd = () => {
		setShowAdd(false)
	}
	const handleCloseReasignar = () => {
		setShowReasignar(false)
	}
	const handleConfirm = (eliminarCliente) => {
		eliminarCliente({ variables: { id } })
	}

	const handleNoti = (valor = false) => {
		if (valor === 1) {
			setNoti(true)
		}
		if (valor === 2) {
			setNotiEdit(true)
		}
		if (valor === 3) {
			setNotiDelete(true)
		}
	}

	const exito = (valor) => {
		let text = ''
		if (valor === 1) {
			text = 'Auxiliar dado de alta con éxito'
			setNoti(false)
		}
		if (valor === 2) {
			text = 'Auxiliar editado con éxito'
			setNotiEdit(false)
		}
		if (valor === 3) {
			text = 'Auxiliar eliminado con éxito'
			setNotiDelete(false)
		}
		toast.success(text, {
			position: 'bottom-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}
	const handleSearch = (e) => {
		setOffset(0)
		setActual(1)
		setSearch(e.target.value)
	}

	const handleOrderBy = (order, asc) => {
		if (orderBy === order && asc === 1) {
			setAsc(-1)
		} else {
			setAsc(1)
		}
		setOrderBy(order)
		console.log(orderBy)
	}

	const { loading, error, data, refetch } = useQuery(USUARIOS, {
		variables: { search, limite: limite, offset: offset, es_admin: esAdmin, orderBy, asc },
		fetchPolicy: 'network-only',
	})
	if (error) {
		return <Redirect to="login" />
	}
	return (
		<>
			{permiso !== 1 && permiso !== 6 && <Redirect to="login" />}
			{!obtenerUsuario && <Redirect to="login" />}
			<Menu titulo={esAdmin ? 'Administradores' : 'Auxiliares'} handleSearch={handleSearch} />
			{noti && exito(1)}
			{notiEdit && exito(2)}
			{notiDelete && exito(3)}
			{loading && <Loading />}
			<>
				<ClientesStyleDiv>
					<div className="antes_tabla"></div>

					{showEdit && <EditarUsuario handleClose={handleCloseEdit} id={id} refetch={refetch} handleNoti={handleNoti} esAdmin={esAdmin} />}
					{showAdd && <AltaUsuario handleClose={handleCloseAdd} refetch={refetch} handleNoti={handleNoti} esAdmin={esAdmin} />}
					{showReasignar && <ReasignarOT handleClose={handleCloseReasignar} data={data} />}
					<div className="tabla">
						{showDelete && <EliminarUsuario info={info} handleCloseDelete={handleCloseDelete} handleConfirm={handleConfirm} />}
						<table>
							<thead>
								<tr>
									<th onClick={() => handleOrderBy('nombre', asc, refetch)} className="orderBy">
										{orderBy === 'nombre' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'nombre' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Nombre
									</th>
									<th onClick={() => handleOrderBy('usuario', asc)} className="orderBy">
										{orderBy === 'usuario' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'usuario' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Email
									</th>
									<th onClick={() => handleOrderBy('area', asc)} className="orderBy">
										{orderBy === 'area' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'area' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Área
									</th>
									<th onClick={() => handleOrderBy('rol', asc)} className="orderBy">
										{orderBy === 'rol' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'rol' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Puesto
									</th>
									<th>Editar</th>
									<th>Eliminar</th>
								</tr>
							</thead>
							<tbody>
								{!loading && (
									<TableList
										data={data}
										handleCloseDelete={handleCloseDelete}
										setId={setId}
										setShowEdit={setShowEdit}
										refetch={refetch}
										handleNoti={handleNoti}
										setShowDelete={setShowDelete}
										setInfo={setInfo}
									/>
								)}
							</tbody>
						</table>
						{!loading && data.obtenerUsuarios.total > 0 && (
							<Paginador actual={actual} total={data.obtenerUsuarios.total} limite={limite} paginaAnterior={paginaAnterior} paginaSiguiente={paginaSiguiente} />
						)}
					</div>
				</ClientesStyleDiv>
			</>

			<Lateral setShowAdd={setShowAdd} setShowReasignar={setShowReasignar} />
			<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	)
}

export default Usuarios
