import styled from 'styled-components'

const DivNotification = styled.div`
	display: flex;
	height: 100%;
	margin-right: 20px;
	margin-bottom: 15px;
	position: relative;
	.animacion {
		animation-name: parpadeo;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		-webkit-animation-name: parpadeo;
		-webkit-animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
	}

	@-moz-keyframes parpadeo {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-webkit-keyframes parpadeo {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes parpadeo {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	#formulario {
		width: 100px;
		height: 100px;
		background: pink;
	}
	.ico {
		color: #ffffff;
		cursor: pointer;
		font-size: 20px;
	}
	.point {
		width: 8px;
		cursor: pointer;
		height: 8px;
		border-radius: 50%;
		background-color: red;
		position: absolute;
		top: 5px;
		left: 5px;
	}
`
const UlNotification = styled.ul`
	list-style: none;
	font-size: 14px;
	padding: 0px;
	li {
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		span {
			margin-left: 10px;
		}
	}
`

export { DivNotification, UlNotification }
