import { Row, Form, Tab, Tabs } from 'react-bootstrap'
import styled from 'styled-components'

const FormDivStyle = styled.div`
	.no_modif {
		input {
			border: none;
			background: none;
			padding: 0;
		}
	}
	.row {
		display: flex;
		align-items: baseline;
	}
	.div_acordeon {
		margin-bottom: 20px;
	}
`

const FormVisadoCampos = ({ handleFileChange }) => {
	return (
		<FormDivStyle>
			<Tabs defaultActiveKey="visado" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="visado" title="Adjuntar archivo visado">
					<Row>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Archivo de visado</Form.Label>
							<Form.Control type="file" name="visado" onChange={(e) => handleFileChange(e.target.files[0])} />
						</Form.Group>
					</Row>
				</Tab>
			</Tabs>
		</FormDivStyle>
	)
}

export default FormVisadoCampos
