import { Button } from 'react-bootstrap'
import styled from 'styled-components'
const TrStyle = styled.tr`
	td {
		padding: 5px;
		:last-child {
			width: 250px;
		}
	}
`
const TablaLegajo = ({ datos, handleShowArchivos }) => {
	return (
		<TrStyle>
			<td>{datos.nOrden}</td>
			<td>{datos.servicios.servicio.nombre}</td>
			<td>{datos.fecha}</td>
			<td>{datos.servicios.estado}</td>
			<td>
				{datos.servicios?.cargado !== null && (
					<Button
						onClick={() => {
							handleShowArchivos(datos.servicios.cargado, datos.servicios.servicio.item, datos.idCargado)
						}}
					>
						Ver archivos
					</Button>
				)}
				{datos.servicios?.cargado === null && <Button variant="secondary">Archivo no disponible</Button>}
			</td>
		</TrStyle>
	)
}

export default TablaLegajo
