import styled from 'styled-components'

const ClientesStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 80px;
	flex-direction: row;
	margin-bottom: 30px;
	.tabla {
		width: 80%;
		margin-right: 10%;
		table {
			width: 100%;
			tr {
				height: 60px;
				border-bottom: 1px solid #ccc;
				.orderBy {
					cursor: pointer;
				}
				td,
				th {
					text-align: center;
					padding: 2px;
					button {
						border: none;
						padding: 0;
						margin: 0;
						background: none;
						color: #fff;
					}
					&.centrado {
						&.ver {
							button {
								width: 60px;
								background: #ffc107;
							}
						}
						&.edit {
							button {
								width: 24px;
								background: #28a745;
								img {
								}
							}
						}
						&.delete {
							button {
								width: 24px;
								background: #dc3545;
								img {
								}
							}
						}
					}
				}
			}
		}
		.instrumental {
			.btn {
				line-height: 5px !important;
			}
		}
	}
	.antes_tabla {
		width: 10%;
	}
`

const DetalleStyleDiv = styled.div`
	.header {
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-weight: bold;
		button {
			width: 24px;
			height: 24px;
			background: #28a745;
			border: none;
			position: relative;
			img {
				position: absolute;
				top: 0;
				left: 0;
				margin: auto;
				right: 0;
				bottom: 0;
			}
		}
		.estado {
			border: none;
			background: #007bff;
			color: #fff;
			font-size: 0.8em;
			padding: 5px;
			font-weight: normal;
		}
	}
	table {
		width: 100%;
		.body_detalle {
			display: flex;
			flex-wrap: wrap;
			tr:nth-last-child(1) {
				border: none;
			}
			tr {
				width: 100%;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #ccc;
				td {
					button {
						border: none;
						background: #007bff;
						color: #fff;
						padding: 4px;
						border-radius: 0;
						margin-left: 10px;
					}
				}
			}
		}
	}
	button {
		border-radius: 4px !important;
	}
`
const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const OrdenesStyleDiv = styled.div`
	.title_orden {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.ambos {
			width: 75%;
			display: flex;
			justify-content: start;
			flex-direction: row;
			align-items: center;
			.titulo {
				width: 50% !important;
			}
			.export {
				padding-left: 10px;
				width: 50% !important;
				display: flex;
				align-items: center;
			}
		}
		.add_orden {
			background: #28a745;
			color: #fff;
			display: block;
			text-align: center;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			padding-top: 1px;
			padding-right: 1px;
			cursor: pointer;
			font-size: 16px;
		}
		.mas_detalle {
			width: auto;
			border: none;
			background: #007bff;
			color: #fff;
			font-size: 0.8em;
			padding: 4px;
			padding-left: 8px;
			padding-right: 8px;
			border-radius: 4px;
		}
	}
	.card-body {
		height: ${(props) => (props.general ? '78vh' : '40vh')};
		overflow: hidden;
	}

	@media (min-width: 1281px) and (max-width: 1366px) {
		.card-body {
			height: ${(props) => (props.general ? '79vh' : '43vh')};
		}
	}
	@media (min-width: 1367px) {
		.card-body {
			height: ${(props) => (props.general ? '82vh' : '56vh')};
		}
	}
	.body_table {
		height: 100%;
	}
	.contenedor_table_body {
		height: 100%;
		overflow: auto;
		padding-bottom: 20px;
		tr {
			cursor: ${(props) => (props.home ? 'default' : 'pointer')};
		}
	}
	table {
		width: 100%;
		tbody {
			td {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
		.centrado {
			button {
				width: 110px;
				border: none;
				background: #007bff;
				color: #fff;
				font-size: 0.8em;
				padding: 4px;
			}
		}
	}
	table {
		width: 100%;
		thead,
		tbody {
			tr {
				width: 100%;
				th:nth-child(1),
				td:nth-child(1) {
					width: 15%;
				}
				th:nth-last-child(2),
				td:nth-last-child(2) {
					width: 25%;
				}
				th:nth-last-child(1),
				td:nth-last-child(1) {
					width: 8%;
					button {
						border: none;
						padding: 2px;
						margin: 0;
						background: none;
						width: 24px;
						background: #dc3545;
						img {
						}
					}
					.ocultar {
						display: none;
					}
				}
			}
		}
	}
	button {
		border-radius: 4px !important;
	}
`

export { ClientesStyleDiv, DetalleStyleDiv, FooterButton, OrdenesStyleDiv }
