import { useQuery } from 'react-apollo'
import { SUCURSAL_CLIENTE_AUX } from '../../queries'
import { useState } from 'react'
import editBtn from './../../img/edit.svg'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import { Redirect } from 'react-router'
import Menu from '../Menus/Menu'
import EditarAuxiliarSucursal from './EditarAuxiliarSucursal'
import ExcelExport from './ExcelExport'
import sortBy from 'lodash/sortBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import Loading from '../Loading'
const ClientesStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 80px;
	flex-direction: row;
	margin-bottom: 30px;
	.tabla {
		width: 60%;
		margin-right: 10%;
		table {
			width: 100%;
			tr {
				height: 60px;
				border-bottom: 1px solid #ccc;
				.orderBy {
					cursor: pointer;
				}
				td,
				th {
					text-align: center;
					button {
						border: none;
						padding: 0;
						margin: 0;
						background: none;
						color: #fff;
					}
					&.centrado {
						&.ver {
							button {
								width: 60px;
								background: #ffc107;
							}
						}
						&.edit {
							button {
								width: 24px;
								background: #28a745;
								img {
								}
							}
						}
						&.delete {
							button {
								width: 24px;
								background: #dc3545;
								img {
								}
							}
						}
					}
					a {
						color: #fff;
						text-decoration: none;
					}
				}
			}
		}
	}
	.antes_tabla {
		width: 10%;
	}
`

const AsignarAuxiliar = ({ obtenerUsuario, permiso }) => {
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [offset, setOffset] = useState(0)
	const [noti, setNoti] = useState(false)
	const [notiEdit, setNotiEdit] = useState(false)
	const [notiDelete, setNotiDelete] = useState(false)
	const [search, setSearch] = useState(null)
	const download = true
	const [orderBy, setOrderBy] = useState('razonSocial')
	const [asc, setAsc] = useState(1)

	const limite = 10

	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}

	const handleNoti = (valor = false) => {
		if (valor === 1) {
			setNoti(true)
		}
		if (valor === 2) {
			setNotiEdit(true)
		}
		if (valor === 3) {
			setNotiDelete(true)
		}
	}
	const handleSearch = (e) => {
		setOffset(0)
		setSearch(e.target.value)
	}

	const exito = (valor) => {
		let text = ''
		if (valor === 1) {
			text = 'Cliente dado de alta con éxito'
			setNoti(false)
		}
		if (valor === 2) {
			text = 'Asignación realizada con éxito'
			setNotiEdit(false)
		}
		if (valor === 3) {
			text = 'Cliente eliminado con éxito'
			setNotiDelete(false)
		}
		toast.success(text, {
			position: 'bottom-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}
	const handleOrderBy = (order, asc) => {
		if (orderBy === order && asc === 1) {
			setAsc(-1)
		} else {
			setAsc(1)
		}
		setOrderBy(order)
	}

	const { loading, data, refetch } = useQuery(SUCURSAL_CLIENTE_AUX, { variables: { search, limite: limite, offset: offset, orderBy, asc } })

	return (
		<>
			{permiso !== 1 && permiso !== 3 && <Redirect to="login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo="Servicios de Higiene y Seguridad" handleSearch={handleSearch} />
			{noti && exito(1)}
			{notiEdit && exito(2)}
			{notiDelete && exito(3)}
			{loading && <Loading />}
			<>
				{showEdit && <EditarAuxiliarSucursal id={id} handleClose={handleCloseEdit} refetch={refetch} handleNoti={handleNoti} />}
				<ClientesStyleDiv>
					<div className="antes_tabla"></div>

					<div className="tabla">
						{!loading && download && <ExcelExport data={data.getSucursalesClientesTodos.sucursales} />}
						<table>
							<thead>
								<tr>
									<th onClick={() => handleOrderBy('idCliente.razonSocial', asc)} className="orderBy" style={{ width: '350px' }}>
										{orderBy === 'idCliente.razonSocial' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'idCliente.razonSocial' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Establecimiento
									</th>
									<th onClick={() => handleOrderBy('modulo', asc)} className="orderBy" style={{ width: '350px' }}>
										{orderBy === 'modulo' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'modulo' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Módulo
									</th>
									<th onClick={() => handleOrderBy('auxiliarAsignado.nombre', asc)} className="orderBy" style={{ width: '350px' }}>
										{orderBy === 'auxiliarAsignado.nombre' && asc === 1 && (
											<FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />
										)}
										{orderBy === 'auxiliarAsignado.nombre' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Auxiliar asignado
									</th>
									<th>Reasignar</th>
								</tr>
							</thead>
							<tbody>
								{!loading && data.getSucursalesClientesTodos.total === 0 && (
									<tr>
										<td colSpan={5}>No se encontraron resultados</td>
									</tr>
								)}
								{!loading &&
									asc === 1 &&
									sortBy(data.getSucursalesClientesTodos.sucursales, orderBy).map((item, index) => {
										const { id, idCliente, nombreEstablecimiento, auxiliarAsignado, modulo } = item
										if (idCliente === null) return false
										return (
											<tr key={index}>
												<td>
													{idCliente.razonSocial} ({nombreEstablecimiento})
												</td>
												<td>{modulo !== null ? modulo.toString().replace('.', ',') : 'Sin Asignar'}</td>
												<td>{auxiliarAsignado?.nombre ? auxiliarAsignado.nombre : 'Sin Asignar'}</td>
												<td className="centrado edit">
													<button>
														<img
															src={editBtn}
															alt=""
															onClick={() => {
																setId(id)
																setShowEdit(true)
															}}
														/>
													</button>
												</td>
											</tr>
										)
									})}
								{!loading &&
									asc === -1 &&
									sortBy(data.getSucursalesClientesTodos.sucursales, orderBy)
										.map((item, index) => {
											const { id, idCliente, nombreEstablecimiento, auxiliarAsignado, modulo } = item
											if (idCliente === null) return false
											return (
												<tr key={index}>
													<td>
														{idCliente.razonSocial} ({nombreEstablecimiento})
													</td>
													<td>{modulo !== null ? modulo.toString().replace('.', ',') : 'Sin Asignar'}</td>
													<td>{auxiliarAsignado?.nombre ? auxiliarAsignado.nombre : 'Sin Asignar'}</td>
													<td className="centrado edit">
														<button>
															<img
																src={editBtn}
																alt=""
																onClick={() => {
																	setId(id)
																	setShowEdit(true)
																}}
															/>
														</button>
													</td>
												</tr>
											)
										})
										.reverse()}
							</tbody>
						</table>
					</div>
				</ClientesStyleDiv>
			</>

			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{/* <LateralExcel setDownload={setDownload} /> */}
		</>
	)
}

export default AsignarAuxiliar
