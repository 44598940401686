import styled from 'styled-components'
import deleteBtn from './../../img/delete.svg'

import chevron from './../../img/chevron.svg'
import { useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import { Redirect, useHistory } from 'react-router'

const FooterNav = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 28px;
	flex-direction: column;
	div {
		display: flex;
		justify-content: center;
		.avatar {
			border-radius: 50%;
			width: 42px;
			height: 42px;
			overflow: hidden;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			padding-top: 14px;
			cursor: pointer;
			p {
				color: #000;
				font-weight: bold;
			}
		}
		.name_usuario {
			width: 55%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 10px;
			margin-left: 15px;
			h4 {
				font-size: 16px;
				font-weight: normal;
				margin-right: 0;
				line-height: 0.2em;
			}
			small {
				color: #f2f2f2;
			}
		}
		.up {
			display: flex;
			justify-content: center;
			img {
				cursor: pointer;
				transform: ${({ closeSession }) => (closeSession ? 'rotate(0.50turn)' : '')};
			}
		}
		&.close_session {
			margin-top: 10px;
			.btn_close {
				width: 30px;
				height: 30px;
				margin-right: 20px;
				img {
					width: 50%;
					cursor: pointer;
				}
			}
			p {
				width: 60%;
				cursor: pointer;
				margin: 0;
				padding: 0;
				padding-top: 2px;
			}
		}
	}
`
const FooterMenu = ({ menu, handleMenu, usuario, rolText }) => {
	const [showCloseSession, setshowCloseSession] = useState(false)
	const handleSession = () => setshowCloseSession(!showCloseSession)
	const history = useHistory()
	let rolTextFinal = ''
	let textAvatar = ''
	switch (rolText) {
		case 'ADMINISTRADOR':
			textAvatar = 'Ad'
			rolTextFinal = 'Administrador'
			break
		case 'RESPONSABLE':
			textAvatar = 'Rp'
			rolTextFinal = 'Responsable de Servicios'
			break
		case 'SUPERVISOR':
			textAvatar = 'Sp'
			rolTextFinal = 'Supervisor de Servicios'
			break
		case 'AUXILIAR':
			textAvatar = 'Ax'
			rolTextFinal = 'Auxiliar de Servicios'
			break
		case 'CLIENTE':
			textAvatar = 'Cl'
			rolTextFinal = 'Cliente'
			break

		default:
			break
	}

	const cerrarSesionUsuario = async (cliente, history) => {
		localStorage.removeItem('token')
		cliente
			.resetStore()
			.then(() => {
				return <Redirect to="/login" />
			})
			.catch((e) => {
				console.log(e)
			})
	}
	return (
		<FooterNav closeSession={showCloseSession}>
			<div>
				<div className="avatar" onClick={() => handleMenu()}>
					<p>{textAvatar}</p>
				</div>
				{menu ? (
					<div className="name_usuario">
						<h4>{usuario}</h4>
						<small>{rolTextFinal}</small>
					</div>
				) : (
					''
				)}
				{menu ? (
					<div className="up" onClick={handleSession}>
						<img src={chevron} alt="" />
					</div>
				) : (
					''
				)}
			</div>
			{menu ? (
				showCloseSession ? (
					<div className="close_session">
						<ApolloConsumer>
							{(cliente) => {
								return (
									<>
										<div className="btn_close">
											<img src={deleteBtn} alt="" onClick={() => cerrarSesionUsuario(cliente, history)} />
										</div>
										<p onClick={() => cerrarSesionUsuario(cliente, history)}>Cerrar sesión</p>
									</>
								)
							}}
						</ApolloConsumer>
					</div>
				) : (
					''
				)
			) : (
				''
			)}
		</FooterNav>
	)
}

export default FooterMenu
