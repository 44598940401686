import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const SinSucursalDiv = styled.div`
	width: 100%;
	height: 100%;
	text-align: center;
`
const SinSucursal = ({ setShowAdd }) => {
	return (
		<SinSucursalDiv>
			<p>No hay Sucursales, por favor agregue una para continuar</p>
			<Button onClick={() => setShowAdd(true)}>Agregar Sucursal</Button>
		</SinSucursalDiv>
	)
}

export default SinSucursal
