import React from 'react'
import styled from 'styled-components'

const Error404Style = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
`
const Error404 = () => {
	return (
		<Error404Style>
			<h2>Error 404</h2>
		</Error404Style>
	)
}

export default Error404
