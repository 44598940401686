import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { ACTUALIZAR_SUCURSAL } from '../../mutations'
import styled from 'styled-components'
import FormCliente from './FormCliente'

const validate = Yup.object({
	codigoPostal: Yup.string().required('El campo Código Postal es obligatorio'),
	direccion: Yup.string().required('El campo Drección es requerido'),
	localidad: Yup.string().required('El campo Localidad es requerido'),
})

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 100px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormEditar = ({ sucursal, handleClose, refetch, handleNoti }) => {
	const { id, nombreEstablecimiento, codigoPostal, direccion, localidad, modulo } = sucursal
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Sucursal</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={ACTUALIZAR_SUCURSAL}
				onCompleted={() => {
					handleClose()
					refetch()
					handleNoti(2)
				}}
			>
				{(crearCliente) => (
					<Formik
						initialValues={{
							id,
							nombreEstablecimiento,
							codigoPostal,
							direccion,
							localidad,
							modulo,
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { nombreEstablecimiento, codigoPostal, direccion, localidad, modulo } = values
							if (modulo === null) {
								values.modulo = 0
							}
							const input = {
								id,
								nombreEstablecimiento,
								codigoPostal,
								direccion,
								localidad,
								modulo: values.modulo === null ? 0 : parseFloat(values.modulo.toString().replace(',', '.')),
							}

							return crearCliente({ variables: { input } })
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormCliente errors={errors} handleChange={handleChange} touched={touched} values={sucursal} />
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit">
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormEditar
