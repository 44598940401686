import { Query } from 'react-apollo'
import { OBTENER_GENERAL111 } from '../../../../queries'
import ListarGeneral from '../ListarGeneral'

const DetalleOrden111 = ({ id, item, handleCloseListar, nombre, cliente, sucursal }) => {
	return (
		<Query query={OBTENER_GENERAL111} variables={{ id }} fetchPolicy={'network-only'}>
			{({ loading, error, data }) => {
				if (loading) return <p>Cargando</p>
				if (error) return console.log(error.message)

				if (data.obtenerGeneral111 === null) {
					alert('Error en la carga')
					return false
				}
				return <ListarGeneral datos={data.obtenerGeneral111} handleCloseListar={handleCloseListar} nombre={nombre} item={item} />
			}}
		</Query>
	)
}

export default DetalleOrden111
