import { Col, Form, Row } from 'react-bootstrap'
const FormCliente = ({ errors, handleChange, touched, values }) => {
	const { razonSocial, cuit, condicionIva, telefono, email } = values
	return (
		<div>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Cuit</Form.Label>
					<Form.Control
						type="text"
						name="cuit"
						onChange={handleChange}
						placeholder="Cuit"
						isInvalid={touched.cuit && errors.cuit}
						defaultValue={cuit ? cuit : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.cuit && errors.cuit}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="razonSocial">
					<Form.Label>Nombre o Razón Social</Form.Label>
					<Form.Control
						type="text"
						name="razonSocial"
						onChange={handleChange}
						placeholder="Razón Social"
						isInvalid={touched.razonSocial && errors.razonSocial}
						defaultValue={razonSocial ? razonSocial : ''}
					/>

					<Form.Control.Feedback type="invalid">
						{touched.razonSocial && errors.razonSocial}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row></Row>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="condicionIva">
					<Form.Label>Condición Iva</Form.Label>
					<Form.Select
						aria-label="Default select example"
						type="text"
						name="condicionIva"
						onChange={handleChange}
						isInvalid={touched.condicionIva && errors.condicionIva}
						defaultValue={condicionIva ? condicionIva : ''}
					>
						<option value="">Seleccione una opción</option>
						<option value="RI">Responsable Inscripto</option>
						<option value="RM">Responsable Monotributo</option>
						<option value="EX">Exento de Iva</option>
						<option value="CF">Consumidor Final</option>
						<option value="NC">No categoriza</option>
					</Form.Select>
					<Form.Control.Feedback type="invalid">
						{touched.condicionIva && errors.condicionIva}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="telefono">
					<Form.Label>Teléfono</Form.Label>
					<Form.Control
						type="text"
						name="telefono"
						onChange={handleChange}
						placeholder="Teléfono"
						isInvalid={touched.telefono && errors.telefono}
						defaultValue={telefono ? telefono : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.telefono && errors.telefono}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row></Row>
			<Row>
				<Form.Group className="mb-3 col-md-6" as={Col} controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						type="text"
						name="email"
						onChange={handleChange}
						placeholder="Email"
						isInvalid={touched.email && errors.email}
						defaultValue={email ? email : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.email && errors.email}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
		</div>
	)
}

export default FormCliente
