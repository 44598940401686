import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
const AdjuntoLabel = styled.div`
	display: flex;
	justify-content: space-between;
	span {
		margin-top: 0px;
		a {
			color: #fff;
			text-decoration: none;
			background-color: #007bff;
			padding: 3px 15px;
			border-radius: 3px;
		}
	}
`
const FormUsuario = ({ errors, handleChange, touched, values, esAdmin, handleFileChange }) => {
	const { usuario, nombre, area, rol, adjunto } = values
	return (
		<div>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Nombre y apellido</Form.Label>
					<Form.Control type="text" name="nombre" onChange={handleChange} placeholder="Nombre y apellido" isInvalid={touched.nombre && errors.nombre} defaultValue={nombre ? nombre : ''} />
					<Form.Control.Feedback type="invalid">{touched.nombre && errors.nombre}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Email</Form.Label>
					<Form.Control type="text" name="usuario" onChange={handleChange} placeholder="Email" isInvalid={touched.usuario && errors.usuario} defaultValue={usuario ? usuario : ''} />
					<Form.Control.Feedback type="invalid">{touched.usuario && errors.usuario}</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Área</Form.Label>
					<Form.Select type="text" name="area" onChange={handleChange} placeholder="Área" isInvalid={touched.area && errors.area} defaultValue={area ? area : ''}>
						<option value="">Seleccione una opción</option>
						<option value="HYST">Higiene y Seguridad en el Trabajo</option>
						<option value="GM">Gestoría Municipal</option>
						<option value="MA">Medio Ambiente</option>
						<option value="CLD">Calidad</option>
					</Form.Select>
					<Form.Control.Feedback type="invalid">{touched.puesto && errors.puesto}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Puesto</Form.Label>
					<Form.Select name="rol" onChange={handleChange} isInvalid={touched.rol && errors.rol} defaultValue={rol ? rol : ''}>
						<option value="">Seleccione una opción</option>
						{esAdmin && <option value="GERENTE">Gerente</option>}
						{esAdmin && <option value="ADMINISTRADOR">Administrador</option>}
						{!esAdmin && (
							<>
								<option value="RESPONSABLE">Responsable de Área</option>
								<option value="SUPERVISOR">Supervisor</option>
								<option value="AUXILIAR">Auxiliar</option>
								<option value="CALIDAD">Control de Calidad</option>
							</>
						)}
					</Form.Select>
					<Form.Control.Feedback type="invalid">{touched.rol && errors.rol}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3 col-md-12" as={Col} controlId="adjunto">
					<AdjuntoLabel>
						<Form.Label>Adjunto</Form.Label>
						{adjunto && (
							<span>
								<a href={`https://lenge.s3.amazonaws.com/firmas/${adjunto}`}>Adjunto actual</a>
							</span>
						)}
					</AdjuntoLabel>
					<Form.Control
						type="file"
						name="adjunto"
						onChange={(e) => {
							handleFileChange(e.target.files[0])
						}}
					/>
				</Form.Group>
			</Row>
		</div>
	)
}

export default FormUsuario
