import styled from 'styled-components'
import clientesImg from './../../img/clientes.svg'
import serviciosImg from './../../img/servicios.svg'
import operariosImg from './../../img/operarios.svg'
import asignarImg from './../../img/asignar.svg'
import ordenesDeTrabajoImg from './../../img/ordenes_de_tranbajo.svg'
import instrumentosDeMedicionImg from './../../img/instrumentos_de_medicion.svg'
import administradoresImg from './../../img/administradores.svg'
import homeImg from './../../img/home.svg'
import ListMenu from './ListMenu'

const Menu = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	ul {
		list-style: none;
		width: 100%;
		padding: 0;
		.version {
			margin-top: 5px;
			font-size: 12px;
			color: #fff;
			text-align: center;
		}
		.powered {
			font-size: 10px;
			font-weight: 400;
			color: #ededed;
		}
		a {
			text-decoration: none;
			color: #fff;
			display: block;

			&.active {
				background: #fff;
				color: #007bff;
				span {
					img {
						filter: invert(35%) sepia(35%) saturate(6253%) hue-rotate(199deg) brightness(101%) contrast(102%);
					}
				}
			}
		}
		li {
			width: 100%;
			font-size: 17px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			height: 55px;
			border-bottom: 1px solid #fff;
			cursor: pointer;
			p {
				padding: 0;
				margin: 0;
				text-align: left;
				width: 75%;
			}
			span {
				width: 70px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-content: center;
			}
			.ico_logo {
				width: 24px;
			}
			.chevron {
				width: 24px;
				transform: rotate(0.25turn);
			}
		}
	}
`

const BodyMenu = ({ menu, handleCloseMenu, permiso }) => {
	return (
		<Menu menu={menu}>
			<ul>
				{permiso !== 6 && <ListMenu label="Inicio" image={homeImg} menu={menu} ruta="/home" handleCloseMenu={handleCloseMenu} />}
				{permiso !== 5 && permiso !== 6 && <ListMenu label="Clientes" image={clientesImg} menu={menu} ruta="/clientes" handleCloseMenu={handleCloseMenu} />}
				{permiso === 5 && <ListMenu label="Locaciones" image={clientesImg} menu={menu} ruta="/locaciones" handleCloseMenu={handleCloseMenu} />}
				{permiso !== 5 && permiso !== 6 && <ListMenu label="Servicios" image={serviciosImg} menu={menu} ruta="/servicios" handleCloseMenu={handleCloseMenu} />}
				{permiso !== 6 && <ListMenu label="Ordenes de trabajo" image={ordenesDeTrabajoImg} menu={menu} ruta="/ordenes_de_trabajo" handleCloseMenu={handleCloseMenu} />}
				{permiso !== 5 && permiso !== 6 && (
					<ListMenu label="Instrumenal de Medición" image={instrumentosDeMedicionImg} menu={menu} ruta="/instrumental_de_medicion" handleCloseMenu={handleCloseMenu} />
				)}
				{(permiso === 1 || permiso === 6) && <ListMenu label="Usuarios" image={operariosImg} menu={menu} ruta="/usuarios" handleCloseMenu={handleCloseMenu} />}

				{permiso === 1 && localStorage.getItem('idAux') === '62852e9e65d1630016b6f2fe' && (
					<ListMenu label="Administradores" image={administradoresImg} menu={menu} ruta="/administradores" handleCloseMenu={handleCloseMenu} />
				)}
				{(permiso === 1 || permiso === 3) && <ListMenu label="Servicios de Higiene y Seguridad" image={asignarImg} menu={menu} ruta="/servicios_hyst" handleCloseMenu={handleCloseMenu} />}
				<p className="version">
					v. 1.02.63
					{menu && <a href="https://venturiadigital.com/">Powered by Venturia Digital</a>}
				</p>
				{!menu && (
					<a href="https://venturiadigital.com/" className="version powered" target="_blank" rel="noreferrer">
						Powered by Venturia Digital
					</a>
				)}
			</ul>
		</Menu>
	)
}

export default BodyMenu
