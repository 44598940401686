import React from 'react'
import { Button } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const ExcelExport = ({ data }) => {
	let dataSeteada = []
	data.map(async (e) => {
		return await dataSeteada.push([
			`${e.idCliente?.razonSocial ? e.idCliente.razonSocial : 'Vacio'} (${e?.nombreEstablecimiento ? e.nombreEstablecimiento : 'Vacio'})`,
			`${e.auxiliarAsignado?.nombre !== undefined ? e.auxiliarAsignado?.nombre : 'Sin asignar'}`,
			`${e.modulo !== null ? e.modulo : 'Sin asignar'}`,
		])
	})
	var hash = {}
	let auxFiltrado = data.filter(function (current) {
		let exists = ''
		if (current.auxiliarAsignado?.nombre) {
			exists = !hash[current.auxiliarAsignado.nombre]

			hash[current.auxiliarAsignado.nombre] = true
		}
		return exists
	})
	let auxSolo = []
	auxFiltrado.map((e) => {
		return auxSolo.push([e.auxiliarAsignado.nombre, 0])
	})

	auxSolo.map((e, index) => {
		return data.map((e2) => {
			if (e2.modulo !== null) {
				if (e2.auxiliarAsignado?.nombre === e[0]) {
					return (auxSolo[index][1] = auxSolo[index][1] + e2.modulo)
				}
			}
			return null
		})
	})
	const multiDataSet = [
		{
			columns: [
				{
					value: 'TOTAL DE SERVICIOS DE HYST',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
			],
			data: [''],
			xSteps: 0,
			ySteps: 0,
		},
		{
			columns: [
				{
					value: 'TOTAL ' + data.length,
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
			],
			data: [''],
			xSteps: 0,
			ySteps: 0,
		},
		{
			columns: [
				{
					value: 'Establecimiento',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Auxiliar',
					widthPx: 180,
					fill: { bgColor: '#000000' },
					style: { font: { sz: '24', bold: true } },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Módulo',
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
			],
			data: dataSeteada,
			xSteps: 0,
			ySteps: 0,
		},
	]
	const multiDataSetTwo = [
		{
			columns: [
				{
					value: 'Asignación de módulos',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
			],
			data: [''],
			xSteps: 0,
			ySteps: 0,
		},
		{
			columns: [
				{
					value: 'Responsable',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Servicios',
					widthPx: 180,
					fill: { bgColor: '#000000' },
					style: { font: { sz: '24', bold: true } },
					border: { top: { style: 'medium' } },
				},
			],
			data: auxSolo,
			xSteps: 0,
			ySteps: 0,
		},
	]

	return (
		<div style={{ textAlign: 'center', position: 'fixed', right: '20px' }}>
			<ExcelFile filename="Exportar" element={<Button variant="success">Exportar planilla</Button>}>
				<ExcelSheet dataSet={multiDataSet} name="Servicios HYST" />
				<ExcelSheet dataSet={multiDataSetTwo} name="Asignación" />
			</ExcelFile>
		</div>
	)
}

export default ExcelExport
