import { useState } from 'react'
import { Card } from 'react-bootstrap'
import styled from 'styled-components'
import MostrarAdjuntos from './MostrarAdjuntos'
import TablaLegajo from './TablaLegajo'
const CardStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: center;
	flex-direction: column;
	.header_card {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	table {
		width: 100%;
	}
`
const ListarParaLegajo = ({ datos, title, handleClose, permiso }) => {
	const [showArchivos, setShowArchivos] = useState(false)
	const [arrayArchivos, setArrayArchivos] = useState([])
	const [item, setItem] = useState('')
	const [idCargado, setIdCargado] = useState('')
	const handleShowArchivos = (archivos, item, idCargado) => {
		setItem(item)
		setIdCargado(idCargado)
		setArrayArchivos(archivos)
		setShowArchivos(true)
	}
	const handleCloseArchivos = () => {
		setShowArchivos(false)
	}
	if (datos?.getLegajo) {
		return (
			<CardStyleDiv>
				<Card style={{ width: '100%' }}>
					<Card.Header className="header_card">
						<span>{title}</span>{' '}
					</Card.Header>
					<Card.Body>
						{datos.getLegajo.length === 0 && (
							<li style={{ listStyle: 'none' }}>
								<p style={{ textAlign: 'center' }}>No hay resultados</p>
							</li>
						)}
						{datos.getLegajo.length > 0 && (
							<>
								<table style={{ width: '100%' }}>
									<thead>
										<tr>
											<th>Orden</th>
											<th>Servicio</th>
											<th>Fecha</th>
											<th>Estado</th>
											<th>Archivos adjuntos</th>
										</tr>
									</thead>
									<tbody>
										{datos.getLegajo.map((e, index) => {
											let datos = {
												servicios: {
													cargado: e.cargado,
													servicio: {
														item: e?.servicio?.item,
														nombre: e?.servicio?.nombre,
													},
													estado: e?.estado,
												},
												nOrden: e?.idOrden?.nOrden,
												idCargado: e?.idCargado,
												idOrden: e?.idOrden?.idOrden,
												fecha: e?.fecha,
											}
											return <TablaLegajo datos={datos} key={index} handleShowArchivos={handleShowArchivos} />
										})}
									</tbody>
								</table>
							</>
						)}
					</Card.Body>
				</Card>
				{showArchivos && (
					<MostrarAdjuntos
						handleClose={handleCloseArchivos}
						datos={arrayArchivos}
						item={item}
						idCargado={idCargado}
						handleCloseEntire={handleClose}
						permiso={permiso}
					/>
				)}
			</CardStyleDiv>
		)
	}
}

export default ListarParaLegajo
