import { SUCURSAL_CLIENTE } from '../../queries'
import { Query } from 'react-apollo'
import FormEditarAuxSucursal from './FormEditarAuxSucursal'
import Loading from '../Loading'

const EditarAuxiliarSucursal = ({ id, handleClose, refetch, handleNoti }) => {
	return (
		<>
			<Query query={SUCURSAL_CLIENTE} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return <FormEditarAuxSucursal sucursal={data.getSucursal} refetch={refetch} handleClose={handleClose} handleNoti={handleNoti} />
				}}
			</Query>
		</>
	)
}

export default EditarAuxiliarSucursal
