import styled from 'styled-components'
import estudioLenge from './../../img/log_lenge_new.png'
import { FormRegistro } from './FormRegistro'
import { Link } from 'react-router-dom'

const LoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	.estudio {
		width: 40%;
	}
`

export const Registro = () => {
	return (
		<LoginPage>
			<img src={estudioLenge} alt="" className="estudio" />
			<FormRegistro />
			<Link to="https://venturiadigital.com/">¿Ya tienes cuenta? Inicia sesión</Link>
		</LoginPage>
	)
}
