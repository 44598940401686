import { Mutation, useQuery } from 'react-apollo'
import { SERVICIOS } from '../../queries'
import { ELIMINAR_SERVICIO } from '../../mutations'
import { useState } from 'react'

import editBtn from './../../img/edit.svg'
import deleteBtn from './../../img/delete.svg'

import AltaServicio from './Alta'
import EditarServicio from './Editar'
import EliminarServicio from './Eliminar'
import Paginador from '../Paginador'

import styled from 'styled-components'
import Lateral from '../Lateral/Lateral'
import { Redirect } from 'react-router'
import Menu from '../Menus/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import Loading from '../Loading'

const ClientesStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 80px;
	flex-direction: row;
	margin-bottom: 30px;
	.tabla {
		width: 60%;
		margin-right: 10%;
		table {
			width: 100%;
			tr {
				height: 60px;
				border-bottom: 1px solid #ccc;
				.orderBy {
					cursor: pointer;
				}
				td,
				th {
					text-align: center;
					button {
						border: none;
						padding: 0;
						margin: 0;
						background: none;
						color: #fff;
					}

					&.centrado {
						&.ver {
							button {
								width: 60px;
								background: #ffc107;
							}
						}
						&.edit {
							button {
								width: 24px;
								background: #28a745;
								img {
								}
							}
						}
						&.delete {
							button {
								width: 24px;
								background: #dc3545;
								img {
								}
							}
						}
					}
				}
				td:nth-child(2),
				th:nth-child(2) {
					width: 600px;
					text-align: left;
					padding-left: 50px;
				}
			}
		}
	}
	.antes_tabla {
		width: 10%;
	}
`

const Servicios = ({ permiso, obtenerUsuario }) => {
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [info, setInfo] = useState({})
	const [offset, setOffset] = useState(0)
	const [actual, setActual] = useState(1)
	const [showAdd, setShowAdd] = useState(false)
	const [search, setSearch] = useState(null)
	const [orderBy, setOrderBy] = useState('nombre')
	const [asc, setAsc] = useState(1)

	const limite = 10
	const paginaAnterior = () => {
		setOffset(offset - limite)
		setActual(actual - 1)
	}
	const paginaSiguiente = () => {
		setOffset(offset + limite)
		setActual(actual + 1)
	}
	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}
	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}
	const handleCloseAdd = () => {
		setShowAdd(false)
	}
	const handleConfirm = (eliminarServicio) => {
		eliminarServicio({ variables: { id } })
	}
	const handleSearch = (e) => {
		setOffset(0)
		setActual(1)
		setSearch(e.target.value)
	}

	const handleOrderBy = (order, asc) => {
		if (orderBy === order && asc === 1) {
			setAsc(-1)
		} else {
			setAsc(1)
		}
		setOrderBy(order)
	}
	const { loading, data, refetch } = useQuery(SERVICIOS, {
		variables: { limite: limite, offset: offset, search: search, orderBy, asc },
		fetchPolicy: 'network-only',
	})

	return (
		<>
			{(permiso === 5 || permiso === 6) && <Redirect to="/login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo="Servicios" handleSearch={handleSearch} />
			{loading && <Loading />}
			<>
				<ClientesStyleDiv>
					<div className="antes_tabla"></div>
					{showEdit && <EditarServicio handleClose={handleCloseEdit} id={id} refetch={refetch} />}
					{showAdd && <AltaServicio handleClose={handleCloseAdd} refetch={refetch} />}
					<div className="tabla">
						{showDelete && <EliminarServicio info={info} handleCloseDelete={handleCloseDelete} handleConfirm={handleConfirm} />}
						<table>
							<thead>
								<tr>
									<th onClick={() => handleOrderBy('item', asc)} className="orderBy">
										{orderBy === 'item' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'item' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Item
									</th>
									<th onClick={() => handleOrderBy('nombre', asc)} className="orderBy">
										{orderBy === 'nombre' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'nombre' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Nombre del servicio
									</th>

									{permiso === 1 && <th>Editar</th>}
									{permiso === 1 && <th>Eliminar</th>}
								</tr>
							</thead>
							<tbody>
								{!loading && data.getServicios.total === 0 && (
									<tr>
										<td colSpan={4}>No se encontraron resultados</td>
									</tr>
								)}
								{!loading &&
									data.getServicios.servicios.map((element) => {
										const { id, item, nombre } = element
										return (
											<tr key={id}>
												<td>{item}</td>
												<td>
													<p>{nombre}</p>
												</td>
												{permiso === 1 && (
													<td className="centrado edit">
														<button>
															<img
																src={editBtn}
																alt=""
																onClick={() => {
																	setId(id)
																	setShowEdit(true)
																}}
															/>
														</button>
													</td>
												)}
												{permiso === 1 && (
													<td className="centrado delete">
														<Mutation
															mutation={ELIMINAR_SERVICIO}
															onCompleted={() => {
																handleCloseDelete()
																refetch()
															}}
														>
															{(eliminarServicio) => (
																<button
																	onClick={() => {
																		setShowDelete(true)
																		setId(id)
																		setInfo({ nombre, eliminarServicio })
																	}}
																>
																	<img src={deleteBtn} alt="" />
																</button>
															)}
														</Mutation>
													</td>
												)}
											</tr>
										)
									})}
							</tbody>
						</table>
						{!loading && data.getServicios.total > 0 && (
							<Paginador
								actual={actual}
								total={data.getServicios.total}
								limite={limite}
								paginaAnterior={paginaAnterior}
								paginaSiguiente={paginaSiguiente}
							/>
						)}
					</div>
				</ClientesStyleDiv>
			</>

			{permiso === 1 && <Lateral setShowAdd={setShowAdd} />}
		</>
	)
}

export default Servicios
