import { Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
const AdjuntoLabel = styled.div`
	display: flex;
	justify-content: space-between;
	span {
		margin-top: 0px;
		a {
			color: #fff;
			text-decoration: none;
			background-color: #007bff;
			padding: 3px 15px;
			border-radius: 3px;
		}
	}
`
const FormInstrumental = ({ errors, handleChange, touched, values, handleFileChange }) => {
	const { nombre, marca, modelo, numeroDeSerie, vence, adjunto } = values
	console.log(touched)
	console.log(values)
	console.log(errors)
	return (
		<div>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="marca">
					<Form.Label>Marca</Form.Label>
					<Form.Control
						type="text"
						name="marca"
						onChange={handleChange}
						placeholder="Marca"
						isInvalid={touched.marca && errors.marca}
						defaultValue={marca ? marca : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.marca && errors.marca}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="nombre">
					<Form.Label>Nombre</Form.Label>
					<Form.Control
						type="text"
						name="nombre"
						onChange={handleChange}
						placeholder="Nombre del instrumento"
						isInvalid={touched.nombre && errors.nombre}
						defaultValue={nombre ? nombre : ''}
					/>

					<Form.Control.Feedback type="invalid">{touched.nombre && errors.nombre}</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="modelo">
					<Form.Label>Modelo</Form.Label>
					<Form.Control
						type="text"
						name="modelo"
						onChange={handleChange}
						placeholder="Modelo"
						isInvalid={touched.modelo && errors.modelo}
						defaultValue={modelo ? modelo : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.modelo && errors.modelo}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="numeroDeSerie">
					<Form.Label>Número de seriess</Form.Label>
					<Form.Control
						type="text"
						name="numeroDeSerie"
						onChange={handleChange}
						placeholder="Número de serie"
						isInvalid={touched.numeroDeSerie && errors.numeroDeSerie}
						defaultValue={numeroDeSerie ? numeroDeSerie : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.numeroDeSerie && errors.numeroDeSerie}</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row></Row>
			<Row>
				<Form.Group className="mb-3 col-md-6" as={Col} controlId="vence">
					<Form.Label>Vence</Form.Label>
					<Form.Control
						type="month"
						name="vence"
						onChange={handleChange}
						placeholder="vence"
						isInvalid={touched.vence && errors.vence}
						defaultValue={vence ? vence : ''}
					/>
					<Form.Control.Feedback type="invalid">{touched.vence && errors.vence}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3 col-md-6" as={Col} controlId="adjunto">
					<AdjuntoLabel>
						<Form.Label>Adjunto</Form.Label>
						{adjunto && (
							<span>
								<a href={`https://lenge.s3.amazonaws.com/instrumentales/${adjunto}`}>Adjunto actual</a>
							</span>
						)}
					</AdjuntoLabel>
					<Form.Control
						type="file"
						name="adjunto"
						onChange={(e) => {
							console.log(e)
							handleFileChange(e.target.files[0])
						}}
					/>
				</Form.Group>
			</Row>
		</div>
	)
}

export default FormInstrumental
