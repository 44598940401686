import { Mutation, useMutation } from 'react-apollo'
import { Modal, Button } from 'react-bootstrap'
import { ANULAR_OT } from '../../mutations'
import { ELIMINAR_OT } from '../../mutations'

const AnularOT = ({ info, handleCloseDelete, id, refetch }) => {
	const [eliminar] = useMutation(ELIMINAR_OT, { variables: { id, idAux: localStorage.getItem('idAux') } })

	return (
		<Modal show="true" onHide={handleCloseDelete} centered>
			<Modal.Header closeButton>
				<Modal.Title>¿Qué desea hacer con la orden de trabajo N° {info.nOrden}?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Recuerde que esta acción es irreversible</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCloseDelete}>
					Cancelar
				</Button>
				<Mutation
					mutation={ANULAR_OT}
					onCompleted={() => {
						handleCloseDelete()
						refetch()
					}}
				>
					{(anularServicioPrestado) => (
						<Button
							variant="warning"
							onClick={() => {
								anularServicioPrestado({ variables: { id, idAux: localStorage.getItem('idAux') } })
							}}
						>
							Anular
						</Button>
					)}
				</Mutation>
				<Button
					variant="danger"
					onClick={() => {
						eliminar()
						handleCloseDelete()
						refetch()
					}}
				>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AnularOT
