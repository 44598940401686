import { Query } from 'react-apollo'
import { OBTENER_111_BY_ORDEN, OBTENER_120_BY_ORDEN, OBTENER_GENERAL } from '../../queries'
import ListarGeneral from '../Servicios/Listar/ListarGeneral'
import Listar111 from '../Servicios/Listar/111/Listar111'
import Listar120 from '../Servicios/Listar/120/Listar120'

const DetalleOrden = ({ id, item, handleCloseListar, nombre, permiso = null }) => {
	if (item === '111') {
		return (
			<Query query={OBTENER_111_BY_ORDEN} variables={{ idOrden: id }} fetchPolicy={'network-only'}>
				{({ loading, error, data, refetch }) => {
					if (loading) return <p>Cargando</p>
					if (error) return console.log(error.message)
					if (data.obtener111Orden.id === null) {
						alert('Error en la carga')
						handleCloseListar()
						return false
					}
					return (
						<Listar111
							datos={data.obtener120Orden}
							datos111={data.obtener111Orden}
							handleCloseListar={handleCloseListar}
							nombre={nombre}
							refetch111={refetch}
						/>
					)
				}}
			</Query>
		)
	}
	if (item === '120') {
		return (
			<Query query={OBTENER_120_BY_ORDEN} variables={{ idOrden: id }} fetchPolicy={'network-only'}>
				{({ loading, error, data, refetch }) => {
					if (loading) return <p>Cargando</p>
					if (error) return console.log(error.message)
					if (data.obtener120Orden.id === null) {
						alert('Error en la carga')
						handleCloseListar()
						return false
					}
					return (
						<Listar120
							datos={data.obtener120Orden}
							datos111={data.obtener111Orden}
							handleCloseListar={handleCloseListar}
							nombre={nombre}
							refetch={refetch}
						/>
					)
				}}
			</Query>
		)
	}
	// if (item !== '105' && item !== '111' && item !== '120') {
	if (item !== '111' && item !== '120') {
		return (
			<Query query={OBTENER_GENERAL} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <p>Cargando</p>
					if (error) return console.log(error.message)
					if (data.obtenerGeneral === null) {
						alert('Error en la carga')
						return false
					}
					return <ListarGeneral datos={data.obtenerGeneral} handleCloseListar={handleCloseListar} nombre={nombre} permiso={permiso} />
				}}
			</Query>
		)
	}
}

export default DetalleOrden
