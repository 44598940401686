import { Modal, Button } from 'react-bootstrap'

const EliminarUsuario = ({ info, handleCloseDelete, handleConfirm }) => {
	return (
		<Modal show="true" onHide={handleCloseDelete} centered>
			<Modal.Header closeButton>
				<Modal.Title>¿Desea eliminar a {info.usuario}?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Recuerde que esta acción es irreversible</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCloseDelete}>
					Cancelar
				</Button>
				<Button
					variant="danger"
					onClick={() => {
						handleConfirm(info.eliminarUsuario)
					}}
				>
					Eliminar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default EliminarUsuario
