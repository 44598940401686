import { Form, Row, Button, Modal, ModalBody, ModalFooter, Col, FormLabel, FormControl } from 'react-bootstrap'
import { FieldArray, Formik } from 'formik'
import { Mutation, Query } from 'react-apollo'
import { FORM120 } from '../../../mutations'

import styled from 'styled-components'
import { SERVICIOS_120 } from '../../../queries'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import deleteBtn from './../../../img/delete.svg'

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const BotonDelete = styled.button`
	font-size: 0.8em;
	background-color: #dc3545;
	width: 24px;
	height: 24px;
	border: none;
	color: #fff;
	padding: 0;
	margin: 0;
	margin-left: 20px;
	img {
		margin-top: -2px;
	}
`

const Form120 = ({ handleClose, id, idSuc, idServ, idOrden, nombreServ, refetch }) => {
	const idCliente = id
	const idSucursal = idSuc
	const idServicio = idServ

	return (
		<Modal show="true" centered size="xl" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{nombreServ}</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={FORM120}
				onCompleted={(e) => {
					if (e.crearForm120.status) {
						refetch()
						handleClose()
					} else {
						alert('Error al cargar Formulario')
					}
				}}
			>
				{(crearForm120) => (
					<Formik
						initialValues={{
							idCliente,
							idSucursal,
							idServicio,
							idOrden,
							servicios: [
								{
									fechaInicio: '',
									fechaFin: '',
									servicio: '',
								},
							],
						}}
						onSubmit={(values) => {
							const { idCliente, idSucursal, idServicio, idOrden, servicios } = values

							servicios.map((e) => {
								const input = {
									idCliente,
									idSucursal,
									idServicio,
									idOrden,
									servicio: e.servicio,
									fechaInicio: e.fechaInicio,
									fechaFin: e.fechaFin,
									cargado: '',
								}
								return crearForm120({
									variables: { input },
								})
							})
							return false
						}}
					>
						{({ errors, values, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, touched }) => (
							<Form onSubmit={handleSubmit} action="/upload" encType="multipart/form-data" method="POST">
								<ModalBody>
									<FieldArray name="servicios">
										{({ insert, remove, push }) => (
											<div>
												{values.servicios.length > 0 &&
													values.servicios.map((friend, index) => (
														<div className="row" key={index}>
															<Query query={SERVICIOS_120} fetchPolicy={'network-only'}>
																{({ loading, error, data, refetch }) => {
																	if (loading) return <p>Cargando</p>
																	if (error) return console.log(error.message)
																	return (
																		<>
																			<Row>
																				<Form.Group className="mb-3 col-12" as={Col} controlId={`servicios.${index}.name`}>
																					<FormLabel>Seleccionar Servicio</FormLabel>
																					<BotonDelete onClick={() => remove(index)}>
																						<img src={deleteBtn} alt="" />
																					</BotonDelete>
																					<Select
																						onChange={(e) => {
																							friend.servicio = e.id
																							setFieldValue()
																						}}
																						onBlur={setFieldTouched}
																						options={data.getServicios111.servicios.concat(data.getServicios120.servicios)}
																						isClearable
																						isSearchable
																						components={makeAnimated()}
																						placeholder="Seleccionar Servicios"
																						getOptionValue={(options) => options.id}
																						getOptionLabel={(options) => '(' + options.item + ') ' + options.nombre}
																						noOptionsMessage={() => 'No se encontraron servicios'}
																						isInvalid={touched.servicio && errors.servicio}
																						required
																					></Select>
																				</Form.Group>
																				<Form.Group className="mb-3 col-6" as={Col} controlId={`servicios.${index}.name`}>
																					<FormLabel>Fecha de inicio</FormLabel>
																					<FormControl
																						type="date"
																						onChange={(e) => {
																							friend.fechaInicio = e.target.value
																							setFieldValue()
																						}}
																						onBlur={setFieldTouched}
																					/>
																				</Form.Group>
																				<Form.Group className="mb-3 col-6" as={Col} controlId={`servicios.${index}.name`}>
																					<FormLabel>Fecha Estimada de finalización</FormLabel>
																					<FormControl
																						type="date"
																						onChange={(e) => {
																							friend.fechaFin = e.target.value
																							setFieldValue()
																						}}
																						onBlur={setFieldTouched}
																					/>
																				</Form.Group>
																			</Row>
																		</>
																	)
																}}
															</Query>
														</div>
													))}
												<Button variant="primary" onClick={() => push({ servicio: '' })}>
													Agregar Servicio
												</Button>
											</div>
										)}
									</FieldArray>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
												Guardar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default Form120
