import gql from 'graphql-tag'

export const NUEVO_CLIENTE = gql`
	mutation crearCliente($input: ClienteInput) {
		crearCliente(input: $input) {
			id
			razonSocial
			cuit
			condicionIva
			telefono
			email
		}
	}
`
export const ELIMINAR_CLIENTE = gql`
	mutation eliminarCliente($id: ID!) {
		eliminarCliente(id: $id)
	}
`

export const ACTUALIZAR_CLIENTE = gql`
	mutation actualizarCliente($input: ClienteInput) {
		actualizarCliente(input: $input) {
			id
			razonSocial
			cuit
			condicionIva
			telefono
			email
		}
	}
`

export const NUEVO_SERVICIO = gql`
	mutation crearServicio($input: ServicioInput) {
		crearServicio(input: $input) {
			id
			item
			nombre
			area
			sistema
			vigencia
		}
	}
`

export const ACTUALIZAR_SERVICIO = gql`
	mutation actualizarServicio($input: ServicioInput) {
		actualizarServicio(input: $input) {
			id
			item
			nombre
			area
			sistema
			vigencia
		}
	}
`

export const ELIMINAR_SERVICIO = gql`
	mutation eliminarServicio($id: ID!) {
		eliminarServicio(id: $id)
	}
`
export const ELIMINAR_OT = gql`
	mutation eliminarServicioPrestado($id: ID!, $idAux: ID!) {
		eliminarServicioPrestado(id: $id, idAux: $idAux)
	}
`
export const VISTO_OT = gql`
	mutation vistoServicioPrestado($id: ID!) {
		vistoServicioPrestado(id: $id)
	}
`

export const NUEVO_USUARIO = gql`
	mutation crearUsuario($cuit: String!, $usuario: String!, $password: String!) {
		crearUsuario(cuit: $cuit, usuario: $usuario, password: $password)
	}
`

export const NUEVO_USUARIO_INTERNO = gql`
	mutation crearUsuarioInterno($input: UsuarioInput, $file: Upload!, $folder: String) {
		crearUsuarioInterno(input: $input) {
			id
			usuario
			password
			nombre
			token
			area
			rol
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`
export const ELIMINAR_USUARIO = gql`
	mutation eliminarUsuario($id: ID!) {
		eliminarUsuario(id: $id)
	}
`
export const ELIMINAR_ARCHIVO = gql`
	mutation eliminarArchivo($id: ID!, $elemento: Int) {
		eliminarArchivo(id: $id, elemento: $elemento)
	}
`

// export const ACTUALIZAR_USUARIO_EXTERNO = gql`
// 	mutation actualizarUsuarioExterno($input: UsuarioInput) {
// 		actualizarUsuario(input: $input) {
// 			id
// 			usuario
// 		}
// 	}
// `
export const ACTUALIZAR_USUARIO = gql`
	mutation actualizarUsuario($input: UsuarioInputEdit, $file: Upload!, $folder: String) {
		actualizarUsuario(input: $input) {
			id
			usuario
			area
			rol
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`
export const CONFIRMAR_USUARIO = gql`
	mutation confirmarUsuario($input: UsuarioInputConfirm) {
		confirmarUsuario(input: $input) {
			id
			usuario
			token
			password
		}
	}
`

export const AUTENTICAR_USUARIO = gql`
	mutation autenticarUsuario($usuario: String!, $password: String!) {
		autenticarUsuario(usuario: $usuario, password: $password) {
			token
		}
	}
`
export const RECUPERAR_PASSWORD = gql`
	mutation recuperarPassword($usuario: String!) {
		recuperarPassword(usuario: $usuario)
	}
`
export const ELIMINAR_SUCURSAL = gql`
	mutation eliminarSucursal($id: ID!) {
		eliminarSucursal(id: $id)
	}
`

export const ACTUALIZAR_SUCURSAL = gql`
	mutation actualizarSucursal($input: SucursalInput) {
		actualizarSucursal(input: $input) {
			id
			codigoPostal
			direccion
			localidad
			modulo
		}
	}
`

export const CREAR_SUCURSAL = gql`
	mutation crearSucursal($input: SucursalInput) {
		crearSucursal(input: $input) {
			id
			idCliente
			direccion
			localidad
			provincia
			codigoPostal
			modulo
		}
	}
`

export const CREAR_ORDEN = gql`
	mutation crearServiciosPrestados($input: ServicioPrestadoInput) {
		crearServiciosPrestados(input: $input) {
			status
		}
	}
`
export const EDITAR_ORDEN = gql`
	mutation editarServiciosPrestados($input: ServicioPrestadoEditInput) {
		editarServiciosPrestados(input: $input) {
			status
		}
	}
`

export const FORM105 = gql`
	mutation crearForm105($input: Form105Input, $file: Upload!, $folder: String) {
		crearForm105(input: $input) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`
export const FORM111 = gql`
	mutation crearForm111($input: Form111Input) {
		crearForm111(input: $input) {
			status
		}
	}
`
export const FORM120 = gql`
	mutation crearForm120($input: Form120Input) {
		crearForm120(input: $input) {
			status
		}
	}
`
export const FORMGENERAL = gql`
	mutation crearFormGeneral($input: FormGeneralInput) {
		crearFormGeneral(input: $input) {
			status
		}
	}
`
export const FORMGENERAL120 = gql`
	mutation crearFormGeneral120($input: FormGeneralInput) {
		crearFormGeneral120(input: $input) {
			status
		}
	}
`

export const FORMGENERAL111 = gql`
	mutation crearFormGeneral111($input: FormGeneralInput111) {
		crearFormGeneral111(input: $input) {
			status
		}
	}
`
export const FORML = gql`
	mutation crearFormL($input: FormLInput) {
		crearFormL(input: $input) {
			status
		}
	}
`

export const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folder: String) {
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`

export const CREAR_INSTRUMENTAL = gql`
	mutation crearInstrumental($input: InstrumentalInput, $file: Upload!, $folder: String) {
		crearInstrumental(input: $input) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`

export const ELIMINAR_INSTRUMENTAL = gql`
	mutation eliminarInstrumental($id: ID!) {
		eliminarInstrumental(id: $id)
	}
`
export const ACTIVAR_INSTRUMENTAL = gql`
	mutation activarInstrumental($id: ID!, $activo: Boolean) {
		activarInstrumental(id: $id, activo: $activo)
	}
`
export const ACTUALIZAR_INSTRUMENTAL = gql`
	mutation actualizarInstrumental($input: InstrumentalInput, $file: Upload!, $folder: String) {
		actualizarInstrumental(input: $input) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`
export const ACTUALIZAR_ESTADO_SERVICIO = gql`
	mutation actualizarEstadoServicio($id: ID, $idServicio: ID) {
		actualizarEstadoServicio(id: $id, idServicio: $idServicio)
	}
`
export const CANCELAR_SERVICIO = gql`
	mutation cancelarServicio($id: ID, $idServicio: ID) {
		cancelarServicio(id: $id, idServicio: $idServicio)
	}
`
export const ACTUALIZAR_VISADO_SERVICIO = gql`
	mutation actualizarVisadoServicio($id: ID, $idServ: ID, $idOrden: ID, $visado: String, $visadoNombreOriginal: String, $file: Upload!, $folder: String) {
		actualizarVisadoServicio(id: $id, idServ: $idServ, idOrden: $idOrden, visadoNombreOriginal: $visadoNombreOriginal, visado: $visado) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`

export const RESULTADO_FINAL = gql`
	mutation subirAchivoFinal($id: ID, $final: String, $file: Upload!, $folder: String) {
		subirAchivoFinal(id: $id, final: $final) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`
export const RESULTADO_FINAL_L = gql`
	mutation subirAchivoFinalL($id: ID, $final: String, $file: Upload!, $folder: String) {
		subirAchivoFinalL(id: $id, final: $final) {
			status
		}
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`

export const ACTUALIZAR_DEBE = gql`
	mutation actualizarDebeCliente($id: ID!, $debe: Boolean) {
		actualizarDebeCliente(id: $id, debe: $debe)
	}
`

export const ANULAR_OT = gql`
	mutation anularServicioPrestado($id: ID!, $idAux: ID!) {
		anularServicioPrestado(id: $id, idAux: $idAux)
	}
`
export const ACTUALIZAR_OBSERVACION_OT = gql`
	mutation actualizarObservacionServicioPrestado($id: ID!, $observacion: String) {
		actualizarObservacionServicioPrestado(id: $id, observacion: $observacion)
	}
`

export const ADD_PERIODO = gql`
	mutation addPeriodo($id: ID) {
		addPeriodo(id: $id)
	}
`

export const NUEVO_ARCHIVO = gql`
	mutation actualizarArchivos($input: FormGeneralInput) {
		actualizarArchivos(input: $input) {
			status
		}
	}
`
export const VISTO_NOTIFICACION = gql`
	mutation vistoNotificacion($id: ID!) {
		vistoNotificacion(id: $id)
	}
`
export const ELIMINAR_NOTIFICACION = gql`
	mutation eliminarNotificacion($id: ID!) {
		eliminarNotificacion(id: $id)
	}
`
export const ELIMINAR_ADJUNTO_OT = gql`
	mutation eliminarAdjuntoServicioPrestado($id: ID!, $adjunto: String!) {
		eliminarAdjuntoServicioPrestado(id: $id, adjunto: $adjunto)
	}
`

export const ACTUALIZAR_ADJUNTO_OT = gql`
	mutation actualizarAdjuntoServicioPrestado($id: ID!, $adjunto: String, $adjuntoNombreOriginal: String, $file: Upload!, $folder: String) {
		actualizarAdjuntoServicioPrestado(id: $id, adjunto: $adjunto, adjuntoNombreOriginal: $adjuntoNombreOriginal)
		uploadFile(file: $file, folder: $folder) {
			status
		}
	}
`

export const REASIGNAR_OT = gql`
	mutation reasignarServiciosPrestados($input: ReasignarServiciosInput) {
		reasignarServiciosPrestados(input: $input)
	}
`
