import { CLIENTE } from '../../queries'
import { Query } from 'react-apollo'
import FormEditarCliente from './FormEditarCliente'
import Loading from '../Loading'

const EditarCliente = ({ id, handleClose, refetch, handleNoti }) => {
	return (
		<>
			<Query query={CLIENTE} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return (
						<FormEditarCliente
							cliente={data.getCliente}
							refetch={refetch}
							handleClose={handleClose}
							handleNoti={handleNoti}
						/>
					)
				}}
			</Query>
		</>
	)
}

export default EditarCliente
