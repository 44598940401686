import chevron from './../../img/chevron.svg'
import { NavLink } from 'react-router-dom'

const ListMenu = ({ label, image, menu, ruta, handleCloseMenu }) => {
	return (
		<NavLink
			to={ruta ? ruta : ''}
			activeClassName="active"
			onClick={() => handleCloseMenu()}
		>
			<li>
				<span>
					<img src={image} alt="" className="ico_logo" />{' '}
				</span>
				{menu ? (
					<>
						<p>{label}</p>
						<span>
							<img src={chevron} alt="" className="chevron" />
						</span>
					</>
				) : (
					''
				)}
			</li>
		</NavLink>
	)
}

export default ListMenu
