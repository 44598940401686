import styled from 'styled-components'
import logo from './../../img/logo_el.svg'
import estudioLenge from './../../img/log_lenge_new.png'
import { FormRecupero } from './FormLogin'
import { Redirect } from 'react-router-dom'

const LoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	.estudio {
		width: 40%;
		/* border-bottom: 2px solid #000; */
	}
`

export const Recuperar = ({ obtenerUsuario }) => {
	return (
		<>
			{!obtenerUsuario ? '' : <Redirect to="/clientes" />}
			<LoginPage>
				<img src={estudioLenge} alt="" className="estudio" />
				<FormRecupero />
			</LoginPage>
		</>
	)
}
