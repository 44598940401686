import { Form, Row, Button, Modal, ModalBody, ModalFooter, FormLabel, Col, FormControl } from 'react-bootstrap'
import { Mutation, Query, useLazyQuery, useMutation, useQuery } from 'react-apollo'
import { CREAR_ORDEN, UPLOAD_FILE } from '../../../mutations'
import Loading from '../../Loading'
import { GET_AUX_SUC, SERVICIOS_Y_USUARIOS_AREA_ROL, SUCURSALES_CLIENTE, USUARIOS } from '../../../queries'
import styled from 'styled-components'

import Select from 'react-select'

import makeAnimated from 'react-select/animated'
import { FieldArray, Formik } from 'formik'
import deleteBtn from './../../../img/delete.svg'
import { useEffect, useState } from 'react'
import { handleFileChange } from '../../Helpers/HandleFile'
import { FooterButton } from '../../Style/Style'

const BotonDelete = styled.button`
	font-size: 0.8em;
	background-color: #dc3545;
	width: 24px;
	height: 24px;
	border: none;
	color: #fff;
	padding: 0;
	margin: 0;
	margin-left: 20px;
	img {
		margin-top: -2px;
	}
`

const Alta = ({ refetch, handleCloseAdd, idSucursal, idCliente, idAuxiliar }) => {
	const [cliente, setCliente] = useState('')
	const [showSucursales, setShowSucursales] = useState(false)
	const [sucursal, setSucursal] = useState(idSucursal)
	const [selectShow, setSelectShow] = useState(false)
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState([])
	const { loading, data } = useQuery(GET_AUX_SUC, { variables: { id: sucursal }, fetchPolicy: 'network-only' })
	const { data: dataUsuarios } = useQuery(USUARIOS)
	const [aux, setAux] = useState({})
	const [getAux, { data: dataAux }] = useLazyQuery(GET_AUX_SUC)
	const [uploadFile] = useMutation(UPLOAD_FILE)

	const [sucursalConsulta, { data: dataSucursales }] = useLazyQuery(SUCURSALES_CLIENTE, {
		onCompleted: (e) => {
			setShowSucursales(true)
		},
	})
	useEffect(() => {
		if (sucursal) {
			getAux({ variables: { id: sucursal }, fetchPolicy: 'network-only' })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sucursal])
	useEffect(() => {
		if (dataAux) {
			dataUsuarios?.obtenerUsuarios?.usuarios.map((e) => {
				if (e?.id === dataAux?.getAuxSucursal?.auxiliarAsignado?.id) {
					return setAux({ id: e.id, nombre: e.nombre })
				}
				return false
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataAux])
	useEffect(() => {
		if (data) {
			dataUsuarios?.obtenerUsuarios?.usuarios.map((e) => {
				if (e?.id === data?.getAuxSucursal?.auxiliarAsignado?.id) {
					return setAux({ id: e.id, nombre: e.nombre })
				}
				return false
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])
	if (loading) {
		return <p style={{ display: 'none' }}>Cargando0000</p>
	}

	return (
		<Modal show="true" centered size="lg" onHide={handleCloseAdd}>
			<Modal.Header closeButton>
				<Modal.Title>Nueva Orden de trabajo</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={CREAR_ORDEN}
				onCompleted={(e) => {
					if (e.crearServiciosPrestados.status) {
						let actual = 0
						if (adjunto.length === 0) {
							handleCloseAdd()
							refetch()
							return
						}
						adjunto.map(async (e, index) => {
							try {
								await uploadFile({
									variables: { file: e.adjunto, folder: 'adjuntosot' },
								}).then(() => {})
								actual = actual + 1
								if (actual === adjunto.length) {
									refetch()
									handleCloseAdd()
								}
								return 'ok'
							} catch (error) {
								if (error) {
									return 'no'
								}
							}
						})
					} else {
						alert('ocurrio un error')
					}
				}}
			>
				{(crearServiciosPrestados) => (
					<div>
						<Formik
							initialValues={{
								idCliente,
								idSucursal,
								cliente: '',
								servicios: [
									{
										servicio: '',
										servicio120: [],
										auxiliar: data?.getAuxSucursal?.auxiliarAsignado?.id ? [{ id: data?.getAuxSucursal?.auxiliarAsignado?.id }] : [],
										fechaInicio: '',
										fechaFin: '',
									},
								],
								estado: 'PENDIENTE',
								observacion: '',
								adjuntoNombreOriginal: '',
								adjunto: [],
							}}
							onSubmit={(values) => {
								const { idCliente, idSucursal, servicios, estado, observacion } = values

								const input = {
									idCliente: idCliente ? idCliente : cliente,
									idSucursal: idSucursal ? idSucursal : sucursal,
									servicios,
									estado,
									observacion,

									adjunto: nombreOriginal,
									createdBy: localStorage.getItem('idAux'),
								}

								let allComplete = true
								servicios.forEach((element) => {
									if (element.auxiliar.length === 0 || element.fechaFin === '' || element.fechaInicio === '' || element.servicio === '') {
										allComplete = false
									}
								})
								if (allComplete) {
									return crearServiciosPrestados({ variables: { input } })
								} else {
									alert('Complete los campos')
									return false
								}
							}}
						>
							{({ errors, values, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, touched, handleChange }) => (
								<Form onSubmit={handleSubmit}>
									<ModalBody>
										<Query
											query={SERVICIOS_Y_USUARIOS_AREA_ROL}
											fetchPolicy={'network-only'}
											variables={{
												rol: 'AUXILIAR',
											}}
										>
											{({ loading, error, data }) => {
												if (loading) return <Loading style={{ display: 'none' }} />
												if (error) return console.log(error.message)

												return (
													<>
														{!idCliente && !idSucursal && (
															<>
																<Row>
																	<Form.Group className="mb-3" as={Col} controlId={`cliente`}>
																		<FormLabel>Seleccionar Cliente</FormLabel>
																		<Select
																			onChange={async (e) => {
																				sucursalConsulta({
																					variables: {
																						id: e.id,
																					},
																				})
																				await setCliente(e.id)
																				setFieldValue()
																			}}
																			onBlur={setFieldTouched}
																			options={data.getClientes.clientes}
																			isClearable
																			isSearchable
																			components={makeAnimated()}
																			placeholder="Seleccionar Cliente"
																			getOptionValue={(options) => options.id}
																			getOptionLabel={(options) => options.razonSocial}
																			noOptionsMessage={() => 'No se encontraron clientes'}
																			isInvalid={touched.cliente && errors.cliente}
																			defaultValue={data.getClientes.clientes.find((op) => {
																				return op.id === cliente
																			})}
																			// setValue={}
																		></Select>
																	</Form.Group>
																</Row>
																{showSucursales && (
																	<Row>
																		<Form.Group className="mb-3" as={Col} controlId={`sucursal`}>
																			<FormLabel>Seleccionar Sucursal</FormLabel>
																			<Select
																				onChange={(e) => {
																					setSucursal(e.id)
																					setFieldValue()
																				}}
																				onBlur={setFieldTouched}
																				options={dataSucursales?.getSucursalesClientes}
																				isClearable
																				isSearchable
																				components={makeAnimated()}
																				placeholder="Seleccionar Sucursal"
																				getOptionValue={(options) => options.id}
																				getOptionLabel={(options) => options.nombreEstablecimiento}
																				noOptionsMessage={() => 'No se encontraron clientes'}
																				isInvalid={touched.sucursal && errors.sucursal}
																				defaultValue={dataSucursales?.getSucursalesClientes.find((op) => {
																					return op.id === sucursal
																				})}
																			></Select>
																		</Form.Group>
																	</Row>
																)}
																{showSucursales && <hr />}
															</>
														)}
														{(sucursal || idCliente) && (
															<FieldArray name="servicios">
																{({ insert, remove, push }) => (
																	<div>
																		{values.servicios.length > 0 &&
																			values.servicios.map((friend, index) => {
																				return (
																					<Row key={index}>
																						<Form.Group className="mb-3" as={Col} controlId={`servicios.${index}.name`}>
																							<FormLabel>Seleccionar Servicios</FormLabel>
																							<BotonDelete onClick={() => remove(index)}>
																								<img src={deleteBtn} alt="" />
																							</BotonDelete>
																							<Select
																								onChange={(e) => {
																									friend.servicio = e.id
																									friend.auxiliar = [{ id: aux.id }]

																									if (e.item === '120' || e.item === '111') {
																										setSelectShow(true)
																									} else {
																										setSelectShow(false)
																										friend.servicio120 = []
																									}

																									setFieldValue()
																								}}
																								onBlur={setFieldTouched}
																								options={data.getServicios.servicios}
																								isClearable
																								isSearchable
																								components={makeAnimated()}
																								placeholder="Seleccionar Servicios"
																								getOptionValue={(options) => options.id}
																								getOptionLabel={(options) => '(' + options.item + ') ' + options.nombre}
																								noOptionsMessage={() => 'No se encontraron servicios'}
																								isInvalid={touched.servicio && errors.servicio}
																								required
																							></Select>
																							<br />
																							{selectShow && (
																								<>
																									<FormLabel>Seleccionar Servicios</FormLabel>
																									<Select
																										onChange={(e) => {
																											let array120 = []
																											e.map((subServicio, index) => {
																												return array120.push({ id: subServicio.id })
																											})

																											friend.servicio120 = array120
																											return setFieldValue()
																										}}
																										onBlur={setFieldTouched}
																										options={data.getServicios120.servicios}
																										isClearable
																										isSearchable
																										isMulti
																										closeMenuOnSelect={false}
																										components={makeAnimated()}
																										placeholder="Seleccionar Servicios"
																										getOptionValue={(options) => options.id}
																										getOptionLabel={(options) => '(' + options.item + ') ' + options.nombre}
																										noOptionsMessage={() => 'No se encontraron servicios'}
																										isInvalid={touched.servicio120 && errors.servicio120}
																										required
																									></Select>
																									<br />
																								</>
																							)}
																							<FormLabel>
																								Seleccionar Auxiliar{' '}
																								{aux.nombre && (
																									<small style={{ color: '#FF6623' }}>
																										(Asignado: <b>{aux.nombre}</b>)
																									</small>
																								)}
																							</FormLabel>
																							<Select
																								onChange={(e) => {
																									let arrayAux = []
																									e.map((aux, index) => {
																										return arrayAux.push({ id: aux.id })
																									})
																									friend.auxiliar = arrayAux
																									setFieldValue()
																								}}
																								onBlur={setFieldTouched}
																								options={data.obtenerUsuariosAreaRol.usuarios}
																								isClearable
																								isSearchable
																								components={makeAnimated()}
																								placeholder="Reasignar auxiliar"
																								getOptionValue={(options) => options.id}
																								getOptionLabel={(options) => {
																									return options.nombre
																								}}
																								noOptionsMessage={() => 'No se encontraron Usuarios'}
																								isInvalid={touched.auxiliar && errors.auxiliar}
																								isMulti
																							></Select>
																							<br />
																							<Row>
																								<Form.Group as={Col} md="6">
																									<FormLabel>Fecha de inicio</FormLabel>
																									<FormControl
																										type="date"
																										onChange={(e) => {
																											friend.fechaInicio = e.target.value
																											setFieldValue()
																										}}
																										onBlur={setFieldTouched}
																									/>
																								</Form.Group>
																								<Form.Group as={Col} md="6">
																									<FormLabel>Fecha Estimada de finalización</FormLabel>
																									<FormControl
																										type="date"
																										onChange={(e) => {
																											friend.fechaFin = e.target.value
																											setFieldValue()
																										}}
																										onBlur={setFieldTouched}
																									/>
																								</Form.Group>
																								<br />
																							</Row>
																						</Form.Group>
																					</Row>
																				)
																			})}
																		<Button variant="primary" onClick={() => push({ servicio: '', auxiliar: '' })}>
																			Agregar Servicio
																		</Button>
																	</div>
																)}
															</FieldArray>
														)}
														{((idCliente && idSucursal) || (cliente && sucursal)) && (
															<>
																<hr style={{ marginTop: '30px' }} />
																<Row>
																	<Form.Group className="mb-3" as={Col} controlId={`observacion`}>
																		<FormLabel>Observaciones de la orden de trabajo</FormLabel>
																		<Form.Control as="textarea" rows={3} isInvalid={touched.observacion && errors.observacion} onChange={handleChange} />
																	</Form.Group>
																</Row>
																<Row>
																	<Form.Group controlId="formFile" className="mb-3">
																		<Form.Label>Adjuntar Archivo</Form.Label>
																		<Form.Control type="file" name="adjunto" multiple onChange={(e) => handleFileChange(e.target.files, setNombreOriginal, setAdjunto)} />
																	</Form.Group>
																</Row>
															</>
														)}
													</>
												)
											}}
										</Query>
									</ModalBody>
									<ModalFooter>
										<Row>
											<FooterButton>
												<Button variant="secondary" onClick={handleCloseAdd}>
													Cancelar
												</Button>
												<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
													Crear Orden
												</Button>
											</FooterButton>
										</Row>
									</ModalFooter>
								</Form>
							)}
						</Formik>
					</div>
				)}
			</Mutation>
		</Modal>
	)
}

export default Alta
