import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
import { Button, Spinner } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix'
import styled from 'styled-components'
import { SERVICIO_PRESTADOS_GENERAL_FOR_EXPORT } from '../../queries'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const ExcelExport = ({ variables, minDate, maxDate }) => {
	if (minDate && maxDate) {
		variables.minDate = minDate
		variables.maxDate = maxDate
	}
	const [isButtonClicked, setIsButtonClicked] = useState(false)
	const { data: dataForExport, loading } = useQuery(SERVICIO_PRESTADOS_GENERAL_FOR_EXPORT, {
		variables,
		fetchPolicy: 'network-only',
		skip: !isButtonClicked,
	})

	const handleExportClick = () => {
		setIsButtonClicked(true)
	}
	useEffect(() => {
		if (!loading && isButtonClicked) {
			document.getElementById('export_final').click()
			setIsButtonClicked(false)
		}
	}, [loading, isButtonClicked])
	let dataSeteada = []
	if (dataForExport && dataForExport.getServiciosPrestadosForExport) {
		dataSeteada = dataForExport.getServiciosPrestadosForExport.map((e) => [
			`N° ${e.nOrden}`,
			`${e.fecha}`,
			`${e.idCliente?.razonSocial}`,
			`${e.estado}`,
			`${e.item}`,
			`${e.auxiliarNew}`,
			`${e.area}`,
			`${e.fecha}`,
		])
	}
	const multiDataSet = [
		{
			columns: [
				{
					value: 'Órdenes de trabajo',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
			],
			data: [''],
			xSteps: 0,
			ySteps: 0,
		},
		{
			columns: [
				{
					value: 'N° orden',
					widthPx: 160,
					style: { font: { bold: true } },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Fecha',
					widthPx: 160,
					fill: { bgColor: '#000000' },
					style: { font: { sz: '24', bold: true } },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Cliente',
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Estado',
					widthPx: 160,
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Item de Servicio',
					widthPx: 160,
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Auxiliar',
					widthPx: 160,
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Área de Negocio',
					widthPx: 160,
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
				{
					value: 'Fecha de Asignación',
					widthPx: 160,
					style: { bold: true },
					border: { top: { style: 'medium' } },
				},
			],
			data: dataSeteada,
			xSteps: 0,
			ySteps: 0,
		},
	]
	const DivButtonExcel = styled.div`
		button {
			font-size: 0.8em;
		}
	`

	return (
		<DivButtonExcel>
			{!loading && !isButtonClicked && (
				<Button variant="success" onClick={handleExportClick}>
					Exportar
				</Button>
			)}
			{loading && isButtonClicked && (
				<Button variant="success" style={{ display: 'flex', alignItems: 'center' }}>
					<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" style={{ width: '.5rem', height: '.5rem', marginRight: '5px' }} />
					Preparando archivo
				</Button>
			)}
			{/* {loading && isButtonClicked && (
				
			)} */}
			{isButtonClicked && !loading && dataSeteada.length > 0 && (
				<ExcelFile
					filename="Exportar"
					element={
						<Button variant="success" id="export_final">
							Exportar
						</Button>
					}
				>
					<ExcelSheet dataSet={multiDataSet} name="Listado de órdenes de trabajo" />
				</ExcelFile>
			)}
		</DivButtonExcel>
	)
}

export default ExcelExport
