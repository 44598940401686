import { useQuery } from 'react-apollo'
import { INSTRUMENTALES } from '../../queries'

import { useState } from 'react'

import Alta from './Alta'
import Editar from './Editar'
import Eliminar from './Eliminar'
import Paginador from '../Paginador'

import Lateral from '../Lateral/Lateral'
import { ToastContainer, toast } from 'react-toastify'
import { Redirect } from 'react-router'
import Menu from '../Menus/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import TableListInstrumental from './TableListInstrumental'
import { ClientesStyleDiv } from '../Style/Style'
import Loading from '../Loading'

const Instrumental = ({ obtenerUsuario, permiso }) => {
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [info, setInfo] = useState({})
	const [offset, setOffset] = useState(0)
	const [actual, setActual] = useState(1)
	const [showAdd, setShowAdd] = useState(false)
	const [noti, setNoti] = useState(false)
	const [notiEdit, setNotiEdit] = useState(false)
	const [notiDelete, setNotiDelete] = useState(false)
	const [search, setSearch] = useState(null)
	const [orderBy, setOrderBy] = useState('nombre')
	const [asc, setAsc] = useState(1)
	const limite = 10
	const paginaAnterior = () => {
		setOffset(offset - limite)
		setActual(actual - 1)
	}
	const paginaSiguiente = () => {
		setOffset(offset + limite)
		setActual(actual + 1)
	}
	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}
	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}
	const handleCloseAdd = () => {
		setShowAdd(false)
	}
	const handleConfirm = (eliminarCliente) => {
		eliminarCliente({ variables: { id } })
	}

	const handleNoti = (valor = false) => {
		if (valor === 1) {
			setNoti(true)
		}
		if (valor === 2) {
			setNotiEdit(true)
		}
		if (valor === 3) {
			setNotiDelete(true)
		}
	}

	const exito = (valor) => {
		let text = ''
		if (valor === 1) {
			text = 'Instrumento de medición dado de alta con éxito'
			setNoti(false)
		}
		if (valor === 2) {
			text = 'Instrumento de medición editado con éxito'
			setNotiEdit(false)
		}
		if (valor === 3) {
			text = 'Instrumento de medición eliminado con éxito'
			setNotiDelete(false)
		}
		toast.success(text, {
			position: 'bottom-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}

	const handleSearch = (e) => {
		setOffset(0)
		setActual(1)
		setSearch(e.target.value)
	}

	const handleOrderBy = (order, asc) => {
		if (orderBy === order && asc === 1) {
			setAsc(-1)
		} else {
			setAsc(1)
		}
		setOrderBy(order)
	}

	const { loading, data, refetch } = useQuery(INSTRUMENTALES, { variables: { limite: limite, offset: offset, search: search, orderBy, asc } })
	return (
		<>
			{(permiso === 5 || permiso === 6) && <Redirect to="/login" />}

			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo="Instrumental" handleSearch={handleSearch} />
			{noti && exito(1)}
			{notiEdit && exito(2)}
			{notiDelete && exito(3)}
			{loading && <Loading />}
			<>
				<ClientesStyleDiv>
					<div className="antes_tabla"></div>

					{showEdit && <Editar handleClose={handleCloseEdit} id={id} refetch={refetch} handleNoti={handleNoti} />}
					{showAdd && <Alta handleClose={handleCloseAdd} refetch={refetch} handleNoti={handleNoti} />}
					<div className="tabla">
						{showDelete && <Eliminar info={info} handleCloseDelete={handleCloseDelete} handleConfirm={handleConfirm} />}
						<table className="instrumental">
							<thead>
								<tr>
									<th onClick={() => handleOrderBy('nombre', asc)} className="orderBy">
										{orderBy === 'nombre' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'nombre' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Nombre del equipo
									</th>
									<th onClick={() => handleOrderBy('marca', asc)} className="orderBy">
										{orderBy === 'marca' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'marca' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Marca
									</th>
									<th onClick={() => handleOrderBy('modelo', asc)} className="orderBy">
										{orderBy === 'modelo' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'modelo' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Modelo
									</th>
									<th onClick={() => handleOrderBy('numeroDeSerie', asc)} className="orderBy">
										{orderBy === 'numeroDeSerie' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'numeroDeSerie' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										N° De Serie
									</th>
									<th>Vence</th>
									<th>Activo/Inactivo</th>
									{permiso === 1 && <th>Editar</th>}
									{permiso === 1 && <th>Eliminar</th>}
								</tr>
							</thead>
							<tbody>
								{!loading && (
									<TableListInstrumental
										data={data}
										refetch={refetch}
										setId={setId}
										setShowEdit={setShowEdit}
										handleCloseDelete={handleCloseDelete}
										handleNoti={handleNoti}
										setShowDelete={setShowDelete}
										setInfo={setInfo}
										permiso={permiso}
									/>
								)}
							</tbody>
						</table>
						{!loading && data.getInstrumentales.total > 0 && (
							<Paginador actual={actual} total={data.getInstrumentales.total} limite={limite} paginaAnterior={paginaAnterior} paginaSiguiente={paginaSiguiente} />
						)}
					</div>
				</ClientesStyleDiv>
			</>
			{permiso === 1 && <Lateral setShowAdd={setShowAdd} />}
			<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	)
}

export default Instrumental
