import React from 'react'
import { Modal, Row, Form, FormLabel, FormControl, Col, Button } from 'react-bootstrap'

const Filter = ({ showFilter, handleCloseFilter, minDate, setMinDate, maxDate, setMaxDate, refetch, setLimit }) => {
	return (
		<Modal show={showFilter} onHide={handleCloseFilter}>
			<Modal.Header closeButton>
				<Modal.Title>Filtros</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Form.Group as={Col} md="6">
						<FormLabel>Fecha desde</FormLabel>
						<FormControl
							type="date"
							defaultValue={minDate}
							onChange={(e) => {
								setMinDate(e.target.value)
							}}
						/>
					</Form.Group>
					<Form.Group as={Col} md="6">
						<FormLabel>Fecha hasta</FormLabel>
						<FormControl
							type="date"
							defaultValue={maxDate}
							onChange={(e) => {
								setMaxDate(e.target.value)
							}}
							min={minDate !== '' ? minDate : null}
						/>
					</Form.Group>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={() => {
						setMinDate('')
						setMaxDate('')
						handleCloseFilter()
						refetch({
							minDate: '',
							maxDate: '',
						})
					}}
				>
					Limpiar
				</Button>
				<Button
					variant="success"
					onClick={() => {
						refetch({
							minDate: minDate,
							maxDate: maxDate,
						})
						handleCloseFilter()
					}}
				>
					Filtrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default Filter
