import { Col, Form, Row } from 'react-bootstrap'
const FormServicios = ({ errors, handleChange, touched, values }) => {
	const { item, nombre, area, sistema, vigencia } = values
	return (
		<div>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="cuit">
					<Form.Label>Item</Form.Label>
					<Form.Control
						type="text"
						name="item"
						onChange={handleChange}
						placeholder="Item"
						isInvalid={touched.item && errors.item}
						defaultValue={item ? item : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.item && errors.item}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="razonSocial">
					<Form.Label>Nombre</Form.Label>
					<Form.Control
						type="text"
						name="nombre"
						onChange={handleChange}
						placeholder="Nombre"
						isInvalid={touched.nombre && errors.nombre}
						defaultValue={nombre ? nombre : ''}
					/>

					<Form.Control.Feedback type="invalid">
						{touched.nombre && errors.nombre}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row></Row>
			<Row>
				<Form.Group className="mb-3" as={Col} controlId="condicionIva">
					<Form.Label>Área</Form.Label>
					<Form.Select
						aria-label="Default select example"
						type="text"
						name="area"
						onChange={handleChange}
						isInvalid={touched.area && errors.area}
						defaultValue={area ? area : ''}
					>
						<option value="">Seleccione una opción</option>
						<option value="HYST">Higiene y Seguridad en el Trabajo</option>
						<option value="GM">Gestoría Municipal</option>
						<option value="MA">Medio Ambiente</option>
					</Form.Select>
					<Form.Control.Feedback type="invalid">
						{touched.area && errors.area}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3" as={Col} controlId="telefono">
					<Form.Label>Sistema</Form.Label>
					<Form.Control
						type="text"
						name="sistema"
						onChange={handleChange}
						placeholder="Sistema"
						isInvalid={touched.sistema && errors.sistema}
						defaultValue={sistema ? sistema : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.sistema && errors.sistema}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
			<Row></Row>
			<Row>
				<Form.Group className="mb-3 col-md-6" as={Col} controlId="email">
					<Form.Label>Vigencia</Form.Label>
					<Form.Control
						type="text"
						name="vigencia"
						onChange={handleChange}
						placeholder="Vigencia"
						isInvalid={touched.vigencia && errors.vigencia}
						defaultValue={vigencia ? vigencia : ''}
					/>
					<Form.Control.Feedback type="invalid">
						{touched.vigencia && errors.vigencia}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
		</div>
	)
}

export default FormServicios
