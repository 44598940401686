import { INSTRUMENTAL } from '../../queries'
import { Query } from 'react-apollo'
import FormEditarCliente from './FormEditar'
import Loading from '../Loading'

const Editar = ({ id, handleClose, refetch, handleNoti }) => {
	return (
		<>
			<Query
				query={INSTRUMENTAL}
				variables={{ id }}
				fetchPolicy={'network-only'}
			>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return (
						<FormEditarCliente
							cliente={data.getInstrumental}
							refetch={refetch}
							handleClose={handleClose}
							handleNoti={handleNoti}
						/>
					)
				}}
			</Query>
		</>
	)
}

export default Editar
