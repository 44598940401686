import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import List from './List'
import { DivNotification } from './Styles'
import { GET_AVISO } from '../../queries'
import { Query } from 'react-apollo'

const Notification = () => {
	const [show, setShow] = useState(false)
	const [showPoint, setShowPoint] = useState(true)

	const handleClose = () => setShow(false)
	const handleShow = () => {
		setShowPoint(false)
		setShow(true)
	}
	return (
		<>
			<DivNotification>
				<div onClick={handleShow}>
					<FontAwesomeIcon icon={faBell} className="ico" />
					<Query
						query={GET_AVISO}
						variables={{
							usuario: localStorage.getItem('idAux'),
						}}
						// pollInterval={1500}
					>
						{({ loading, error, data, refetch }) => {
							if (loading) return 'Cargando...'
							if (error) return ''
							if (data.obtenerNotificacionesAviso.total > 0 && showPoint) {
								return (
									<>
										<div className="point animacion"></div>
									</>
								)
							} else {
								return <></>
							}
						}}
					</Query>
				</div>
				<Offcanvas show={show} onHide={handleClose} placement={'end'}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>Notificaciones</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<List />
					</Offcanvas.Body>
				</Offcanvas>
			</DivNotification>
		</>
	)
}

export default Notification
