import styled from 'styled-components'
import estudioLenge from './../../img/log_lenge_new.png'
import { FormLogin } from './FormLogin'
import { Redirect } from 'react-router'

const LoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	.estudio {
		width: 40%;
		/* border-bottom: 2px solid #000; */
	}
`
const DivPower = styled.div`
	width: 100%;
	text-align: center;
	a {
		text-decoration: none;
		color: #515151;
		font-size: 1em;
		span {
			font-weight: 600;
		}
	}
`

export const Login = ({ refetch, obtenerUsuario }) => {
	return (
		<>
			{!obtenerUsuario ? '' : obtenerUsuario.rol === 'TEMPORAL' ? <Redirect to="/usuarios" /> : <Redirect to="/home" />}
			<LoginPage>
				<img src={estudioLenge} alt="" className="estudio" />
				<FormLogin refetch={refetch} />
				<DivPower>
					<a href="https://venturiadigital.com/" target="_blank" rel="noreferrer">
						Powered by <span>Venturia Digital</span>
					</a>
				</DivPower>
			</LoginPage>
		</>
	)
}
