import DetalleOrdenDeTrabajo120 from './DetalleOrdenDeTrabajo120'

const Listar120 = ({ datos, datos111, handleCloseListar, nombre, refetch }) => {
	return (
		<div>
			<DetalleOrdenDeTrabajo120 datos={datos} datos111={datos111} nombre={nombre} handleClose120={handleCloseListar} refetch={refetch} />
		</div>
	)
}

export default Listar120
