import { Form, Row, Button, Modal, ModalBody, ModalFooter, FormLabel, Col, FormControl } from 'react-bootstrap'
import { Mutation, Query } from 'react-apollo'
import { EDITAR_ORDEN } from '../../mutations'
import Loading from '../Loading'
import { SERVICIOS_Y_USUARIOS_AREA_ROL } from '../../queries'
import styled from 'styled-components'

import Select from 'react-select'

import makeAnimated from 'react-select/animated'
import { FieldArray, Formik } from 'formik'
import deleteBtn from './../../img/delete.svg'
import { useState } from 'react'

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const BotonDelete = styled.button`
	font-size: 0.8em;
	background-color: #dc3545;
	width: 24px;
	height: 24px;
	border: none;
	color: #fff;
	padding: 0;
	margin: 0;
	margin-left: 20px;
	img {
		margin-top: -2px;
	}
`

const EditarOT = ({ data, handleCloseEdit, refetch }) => {
	const { id: idOrden, idCliente, idSucursal, nOrden, servicios: serviciosEdit, observacion } = data
	const [selectShow, setSelectShow] = useState(false)
	return (
		<Modal show="true" centered size="lg" onHide={handleCloseEdit}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Orden de trabajo N° {nOrden}</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={EDITAR_ORDEN}
				onCompleted={(e) => {
					if (e.editarServiciosPrestados.status) {
						refetch()
						handleCloseEdit()
					} else {
						alert('ocurrio un error')
					}
				}}
			>
				{(editarServiciosPrestados) => (
					<div>
						<Formik
							initialValues={{
								idCliente,
								idSucursal,
								cliente: '',
								servicios: serviciosEdit,
								estado: 'PENDIENTE',
								observacion,
								adjuntoNombreOriginal: '',
								adjunto: '',
							}}
							onSubmit={(values) => {
								const { idCliente, idSucursal, servicios } = values

								const input = {
									nOrden: nOrden,
									id: idOrden,
									idCliente,
									idSucursal,
									servicios,
								}
								return editarServiciosPrestados({ variables: { input } })
							}}
						>
							{({ errors, values, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, touched, handleChange }) => (
								<Form onSubmit={handleSubmit}>
									<ModalBody>
										<Query
											query={SERVICIOS_Y_USUARIOS_AREA_ROL}
											fetchPolicy={'network-only'}
											variables={{
												rol: 'AUXILIAR',
											}}
										>
											{({ loading, error, data }) => {
												if (loading) return <Loading />
												if (error) return console.log(error.message)
												return (
													<>
														{idCliente && (
															<FieldArray name="servicios">
																{({ insert, remove, push }) => (
																	<div>
																		{values.servicios.length > 0 &&
																			values.servicios.map((friend, index) => {
																				return (
																					<Row key={index}>
																						<Form.Group className="mb-3" as={Col} controlId={`servicios.${index}.name`}>
																							<FormLabel>Seleccionar Servicios</FormLabel>
																							<BotonDelete onClick={() => remove(index)}>
																								<img src={deleteBtn} alt="" />
																							</BotonDelete>
																							<Select
																								onChange={(e) => {
																									friend.servicio = e.id
																									if (e.item === '120') {
																										setSelectShow(true)
																									} else {
																										setSelectShow(false)
																										friend.servicio120 = []
																									}
																									setFieldValue()
																								}}
																								onBlur={setFieldTouched}
																								options={data.getServicios.servicios}
																								isClearable
																								isSearchable
																								components={makeAnimated()}
																								placeholder="Seleccionar Servicios"
																								getOptionValue={(options) => options.id}
																								getOptionLabel={(options) => '(' + options.item + ') ' + options.nombre}
																								noOptionsMessage={() => 'No se encontraron servicios'}
																								isInvalid={touched.servicio && errors.servicio}
																								defaultValue={data.getServicios.servicios.find((op) => {
																									return op.id === friend.servicio
																								})}
																								required
																								isDisabled={friend.new === true ? false : true}
																							></Select>
																							<br />
																							{selectShow && (
																								<>
																									<FormLabel>Seleccionar Servicios</FormLabel>
																									<Select
																										onChange={(e) => {
																											let array120 = []
																											e.map((subServicio) => {
																												return array120.push({ id: subServicio.id })
																											})

																											friend.servicio120 = array120
																											return setFieldValue()
																										}}
																										onBlur={setFieldTouched}
																										options={data.getServicios120.servicios}
																										isClearable
																										isSearchable
																										isMulti
																										closeMenuOnSelect={false}
																										components={makeAnimated()}
																										placeholder="Seleccionar Servicios"
																										getOptionValue={(options) => options.id}
																										getOptionLabel={(options) => '(' + options.item + ') ' + options.nombre}
																										noOptionsMessage={() => 'No se encontraron servicios'}
																										isInvalid={touched.servicio120 && errors.servicio120}
																										required
																									></Select>
																									<br />
																								</>
																							)}
																							<FormLabel>Seleccionar Auxiliar</FormLabel>
																							<Select
																								onChange={(e) => {
																									let arrayAux = []
																									e.map((aux, index) => {
																										return arrayAux.push({ id: aux.id })
																									})
																									friend.auxiliar = arrayAux
																									setFieldValue()
																								}}
																								onBlur={setFieldTouched}
																								options={data.obtenerUsuariosAreaRol.usuarios}
																								isClearable
																								isSearchable
																								components={makeAnimated()}
																								placeholder="Seleccionar Auxiliar"
																								getOptionValue={(options) => options.id}
																								getOptionLabel={(options) => {
																									return options.nombre
																								}}
																								noOptionsMessage={() => 'No se encontraron Usuarios'}
																								isInvalid={touched.auxiliar && errors.auxiliar}
																								defaultValue={data.obtenerUsuariosAreaRol.usuarios.find((op) => {
																									return op.id === friend.auxiliar
																								})}
																								isMulti
																							></Select>
																							<br />
																							<FormLabel>Fecha de inicio</FormLabel>
																							<FormControl
																								type="date"
																								onChange={(e) => {
																									friend.fechaInicio = e.target.value
																									setFieldValue()
																								}}
																								defaultValue={friend.fechaInicio ? friend.fechaInicio.split('/').reverse().join('-') : ''}
																								onBlur={setFieldTouched}
																							/>
																							<br />
																							<FormLabel>Fecha Estimada de finalización</FormLabel>
																							<FormControl
																								type="date"
																								onChange={(e) => {
																									friend.fechaFin = e.target.value
																									setFieldValue()
																								}}
																								defaultValue={friend.fechaFin ? friend.fechaFin.split('/').reverse().join('-') : ''}
																								onBlur={setFieldTouched}
																							/>
																						</Form.Group>
																					</Row>
																				)
																			})}
																		<Button variant="primary" onClick={() => push({ servicio: '', auxiliar: '', new: true })}>
																			Agregar Servicio
																		</Button>
																	</div>
																)}
															</FieldArray>
														)}
													</>
												)
											}}
										</Query>
									</ModalBody>
									<ModalFooter>
										<Row>
											<FooterButton>
												<Button variant="secondary" onClick={handleCloseEdit}>
													Cancelar
												</Button>
												<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
													Guardar cambios
												</Button>
											</FooterButton>
										</Row>
									</ModalFooter>
								</Form>
							)}
						</Formik>
					</div>
				)}
			</Mutation>
		</Modal>
	)
}

export default EditarOT
