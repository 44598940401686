import { Row, Form } from 'react-bootstrap'
import styled from 'styled-components'

const FormDivStyle = styled.div`
	.no_modif {
		input {
			border: none;
			background: none;
			padding: 0;
		}
	}
	.row {
		display: flex;
		align-items: baseline;
	}
	.div_acordeon {
		margin-bottom: 20px;
	}
`

const FormGeneralCampos = ({ handleFileChange }) => {
	return (
		<FormDivStyle>
			<Row>
				<Form.Group controlId="formFile" className="mb-3">
					<Form.Label>
						<b>Adjuntar Archivo</b>
					</Form.Label>
					<Form.Control
						type="file"
						name="adjunto"
						multiple
						onChange={(e) => {
							handleFileChange(e.target.files)
						}}
					/>
				</Form.Group>
			</Row>
		</FormDivStyle>
	)
}

export default FormGeneralCampos
