import {
	Button,
	Form,
	Modal,
	ModalBody,
	ModalFooter,
	ProgressBar,
	Row,
} from 'react-bootstrap'
import styled from 'styled-components'
import { DatosClientePdf } from './DatosClientePdf'
import { EstilizarTabla, MostrarLista } from './StylesListar'
import {
	ELIMINAR_ARCHIVO,
	NUEVO_ARCHIVO,
	UPLOAD_FILE,
} from '../../../mutations'
import { Mutation, useMutation } from 'react-apollo'
import FormGeneralCampos from '../../Formularios/FormGeneral/FormGeneralCampos'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Formik } from 'formik'
import { setFecha } from '../../../hooks/setFecha'
import { permisosById } from '../../Helpers/Permisos'

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const ContenedorArchivos = styled.div`
	background: ${(props) => (props.bg % 2 === 0 ? '' : '#cccccc')};
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	width: 100%;
	label {
		margin-right: 100px;
		width: 60%;
	}
	button {
		margin-left: 10px;
	}
`

const ListarGeneral = ({
	datos,
	handleCloseListar,
	nombre,
	permiso,
	item = null,
}) => {
	const [deleteArchivo] = useMutation(ELIMINAR_ARCHIVO)
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')
	const [conteo, setConteo] = useState(0)
	const [progreso, setProgreso] = useState(false)
	const [uploadFile] = useMutation(UPLOAD_FILE)
	const [showAdd, setShowAdd] = useState(false)
	let textoFechaMedicion = 'Fecha de la medición:'
	if (item === 'A') {
		textoFechaMedicion = 'Fecha de cronograma:'
	}
	if (item === 'H') {
		textoFechaMedicion = 'Fecha de capacitación:'
	}
	if (item === 'K') {
		textoFechaMedicion = 'Fecha de visita:'
	}
	if (item === 'O') {
		textoFechaMedicion = 'Fecha de procedimientos de seguridad:'
	}
	const handleFileChange = (valor) => {
		let archivosNombre = []
		let archivos = []
		for (let i = 0; i < valor.length; i++) {
			const extencion = valor[i].name.split('.').pop()
			const extraerNombreOriginal = valor[i].name
			var blob = valor[i].slice(0, valor[i].size, extencion)
			var newFile = new File([blob], uuidv4() + '.' + extencion, {
				type: valor[i].type,
			})
			archivosNombre.push({
				adjuntoNombreOriginal: extraerNombreOriginal,
				adjunto: newFile.name,
			})
			archivos.push({ adjunto: newFile })
		}
		setNombreOriginal(archivosNombre)
		setAdjunto(archivos)
	}
	const mostrarArchivos = () => {
		return datos.archivos.map((archivo, index) => {
			let urlImage = `https://lenge.s3.amazonaws.com/documents/${archivo.adjunto}`
			return (
				<ContenedorArchivos bg={index} key={index}>
					<label htmlFor="">{archivo.adjuntoNombreOriginal}</label>
					<Button href={urlImage} target="_blank" rel="noreferrer" key={index}>
						Descargar
					</Button>
					{permisosById(localStorage.getItem('idAux')) && (
						<Button
							variant="danger"
							onClick={() => {
								deleteArchivo({
									variables: {
										id: datos.id,
										elemento: index,
									},
								})
								handleCloseListar()
							}}
						>
							Eliminar
						</Button>
					)}
				</ContenedorArchivos>
			)
		})
	}
	const nuevaFecha = datos.fechaMedicion.replace('-', '/')
	return (
		<MostrarLista>
			<Modal show={true} centered onHide={handleCloseListar} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>
						{nombre}{' '}
						{!showAdd && permiso < 5 && (
							<Button
								style={{ marginLeft: '20px' }}
								onClick={() => {
									setShowAdd(true)
								}}
							>
								Agregar archivos
							</Button>
						)}
					</Modal.Title>
				</Modal.Header>
				<ModalBody>
					<EstilizarTabla>
						<DatosClientePdf id={datos.idCliente} idSuc={datos.idSucursal} />
					</EstilizarTabla>
					<EstilizarTabla>
						<thead>
							<tr>
								<th>Datos de la Medición</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<div>
										<b>{textoFechaMedicion} </b>
										<span>{setFecha(nuevaFecha)}</span>
									</div>
									<div>
										<b>Hora de inicio: </b>
										<span>{datos.horaInicio} </span>
									</div>
									<div>
										<b>Hora de finalización: </b>
										<span>{datos.horaFin}</span>
									</div>
								</td>
							</tr>
						</tbody>
					</EstilizarTabla>

					{permiso < 5 && showAdd && (
						<Mutation
							mutation={NUEVO_ARCHIVO}
							onCompleted={async (e) => {
								let estado = e.actualizarArchivos.status
								if (estado === true) {
									setProgreso(true)
									let actual = 0
									adjunto.map(async (e, index) => {
										try {
											await uploadFile({
												variables: { file: e.adjunto, folder: 'documents' },
											}).then(() => {})
											actual = actual + 1
											setConteo(actual)
											if (actual === adjunto.length) {
												setConteo(0)
												setProgreso(false)
												// refetch()
												// handleClose()
												handleCloseListar()
											}
											return 'ok'
										} catch (error) {
											if (error) {
												return 'no'
											}
										}
									})
								}
							}}
						>
							{(crearFormGeneral) => (
								<Formik
									initialValues={{
										fechaMedicion: '',
										horaInicio: '',
										horaFin: '',
									}}
									onSubmit={(values) => {
										let input

										input = {
											id: datos.id,
											archivos: nombreOriginal,
										}
										return crearFormGeneral({
											variables: { input },
										})
									}}
								>
									{({
										errors,
										handleSubmit,
										handleChange,
										isSubmitting,
										touched,
									}) => (
										<Form
											onSubmit={handleSubmit}
											action="/upload"
											encType="multipart/form-data"
											method="POST"
										>
											<ModalBody>
												<FormGeneralCampos
													handleChange={handleChange}
													handleFileChange={handleFileChange}
												/>
											</ModalBody>
											<ModalFooter>
												<Row>
													<FooterButton>
														<Button
															variant="secondary"
															onClick={() => {
																setShowAdd(false)
															}}
														>
															Cancelar
														</Button>
														{adjunto.length > 0 && (
															<Button
																variant="success"
																disabled={isSubmitting}
																type="submit"
																className="enviar"
															>
																Guardar
															</Button>
														)}
													</FooterButton>
												</Row>
												{progreso && (
													<Row style={{ width: '100%', marginTop: '20px' }}>
														<ProgressBar
															variant="success"
															animated
															now={(conteo / adjunto.length) * 100}
															style={{ padding: '0' }}
														/>
													</Row>
												)}
											</ModalFooter>
										</Form>
									)}
								</Formik>
							)}
						</Mutation>
					)}
				</ModalBody>

				<ModalFooter>
					<>{mostrarArchivos()}</>
				</ModalFooter>
			</Modal>
		</MostrarLista>
	)
}

export default ListarGeneral
