import { Mutation } from 'react-apollo'
import { ACTIVAR_INSTRUMENTAL, ELIMINAR_INSTRUMENTAL } from '../../mutations'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import editBtn from './../../img/edit.svg'
import deleteBtn from './../../img/delete.svg'

const TableListInstrumental = ({ data, refetch, setId, setShowEdit, handleCloseDelete, handleNoti, setShowDelete, setInfo, permiso }) => {
	if (data.getInstrumentales.total === 0) {
		return (
			<tr>
				<td colSpan={7}>No se encontraron resultados</td>
			</tr>
		)
	}
	return data.getInstrumentales.instrumentales.map((item) => {
		const { id, nombre, marca, modelo, numeroDeSerie, vence, activo } = item
		return (
			<tr key={id}>
				<td>{nombre}</td>
				<td>{marca}</td>
				<td>{modelo}</td>
				<td>{numeroDeSerie}</td>
				<td>{vence ? vence : '-'}</td>
				<td>
					<Mutation
						mutation={ACTIVAR_INSTRUMENTAL}
						onCompleted={() => {
							refetch()
						}}
					>
						{(actualizar) => (
							<BootstrapSwitchButton
								checked={activo === null ? true : activo}
								size="xs"
								onChange={(e) => {
									if (permiso !== 1) return false
									actualizar({ variables: { id, activo: e } })
								}}
							/>
						)}
					</Mutation>
				</td>
				{permiso === 1 && (
					<td className="centrado edit">
						<button>
							<img
								src={editBtn}
								alt=""
								onClick={() => {
									setId(id)
									setShowEdit(true)
								}}
							/>
						</button>
					</td>
				)}
				{permiso === 1 && (
					<td className="centrado delete">
						<Mutation
							mutation={ELIMINAR_INSTRUMENTAL}
							onCompleted={() => {
								handleCloseDelete()
								refetch()
								handleNoti(3)
							}}
						>
							{(eliminarCliente) => (
								<button
									onClick={() => {
										setShowDelete(true)
										setId(id)
										setInfo({ nombre, eliminarCliente })
									}}
								>
									<img src={deleteBtn} alt="" />
								</button>
							)}
						</Mutation>
					</td>
				)}
			</tr>
		)
	})
}

export default TableListInstrumental
