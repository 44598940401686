export const permisos = ({ rol }) => {
	let rolUsuario = ''
	switch (rol) {
		case 'ADMINISTRADOR':
			rolUsuario = 1
			break
		case 'RESPONSABLE':
			rolUsuario = 2
			break
		case 'SUPERVISOR':
			rolUsuario = 3
			break
		case 'AUXILIAR':
			rolUsuario = 4
			break
		case 'CLIENTE':
			rolUsuario = 5
			break
		case 'TEMPORAL':
			rolUsuario = 6
			break
		case 'CALIDAD':
			rolUsuario = 7
			break

		default:
			break
	}
	return rolUsuario
}
export const permisosOrdenesDeTrabajo = ({ id, rol }) => {
	let idPermiso
	if (rol === 'ADMINISTRADOR' || rol === 'RESPONSABLE' || rol === 'SUPERVISOR' || rol === 'CLIENTE') {
		idPermiso = ''
	} else {
		idPermiso = id
	}
	return idPermiso
}
export const permisosNoti = ({ id }) => {
	return id
}

export const permisosById = (id) => {
	const arraysID = [
		'624441ca728ee90016dde938', //Daniela
		'628797fa88f309001607d4b3', //Javier
		'624ad49e2f23020016c7dcd0', //Lucas Caseres
		'624ad5a52f23020016c7dcd7', //Emanuel
		'627e48364d24ba0016da8b0a', //Melisa
		'62377fc70c15d60016781351', //Matias
		'624ad55c2f23020016c7dcd6', //Mauro
		'62852e9e65d1630016b6f2fe', //Soporte
		'627d6c3bf90fc70016211374', // Andrea
		'65f9d605a3a84300144d1656', //Claudio
		'661eab89e0d6a400146247e1', // Emilse
	]
	if (arraysID.includes(id)) {
		return true
	}
	return false
}
