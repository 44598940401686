import styled from 'styled-components'

const MostrarLista = styled.div`
	margin-top: 20px;
	padding: 20px;
	/* border: 1px solid #cccccc; */
	border-radius: 3px;
	p {
		color: blue;
	}
	.header {
		display: flex;
		justify-content: space-between;
	}
	.tabla_list .table {
		font-size: 13px;
	}
`
const EstilizarTabla = styled.table`
	width: 100%;
	thead {
		border-bottom: 1px solid #000;
		tr {
			th {
				padding: 10px;
				text-align: center;
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 5px;
				img {
					width: 35%;
				}
			}
		}
	}
	tr {
	}
`
export { MostrarLista, EstilizarTabla }
