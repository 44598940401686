import { Button, Card, Modal, ModalBody, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Listar111Interno from './Listar111Interno'
import { MostrarLista } from '../StylesListar'
import sortBy from 'lodash/sortBy'
import { DetalleStyleDiv } from '../../../Style/Style'
import { useState } from 'react'
import { Mutation, Query } from 'react-apollo'
import { SERVICIO } from '../../../../queries'
import { ACTUALIZAR_ESTADO_SERVICIO } from '../../../../mutations'
import DetalleOrden from '../../../Sucursal/DetalleOrden'
import Formularios from '../../../Sucursal/Formularios'

const Listar111 = ({ datos, datos111, handleCloseListar, nombre, refetch111 }) => {
	const [showForm, setShowForm] = useState(false)
	const [showListar, setShowListar] = useState(false)
	const [orden, setOrden] = useState('')
	const [sucursal, setSucursal] = useState('')
	const [cliente, setCliente] = useState('')
	const [idServ, setIdServ] = useState('')
	const [itemServicio, setItemServicio] = useState('')
	const [nombreServicio, setNombreServicio] = useState('')
	const [idForm, setIdForm] = useState('')
	const [view, setView] = useState(true)

	const handleShowForm = () => {
		setShowForm(true)
	}
	const handleShowListar = () => {
		setShowListar(true)
	}

	const handleClose = () => {
		setIdServ('')
		setShowForm(false)
	}
	const mostrarTabla = (datos) => {
		const valores = datos.map((e, index) => {
			const { id: idServicio, servicio, idCliente, idSucursal, idOrden, fechaInicio, estado, cargado } = e

			const color = estado === 'COMPLETO' ? '#28A745' : ''
			return (
				<>
					<Query query={SERVICIO} variables={{ id: servicio }} fetchPolicy={'network-only'} key={index}>
						{({ loading, error, data }) => {
							if (loading) return <p>Cargando...</p>
							if (error) return `Error: ${error.message}`
							const { item, nombre, sistema } = data.getServicio
							const ver_111 = cargado && (item === '111' || item === '120') ? true : false
							return (
								<tr>
									<td style={{ width: '40px' }}>{item}</td>
									<td style={{ width: '35%' }}>{nombre}</td>
									<td style={{ width: '10%' }}>
										<b>{fechaInicio.split('-').reverse().join('/')}</b>
									</td>
									<td className="centrado ver">
										<OverlayTrigger placement={'top'} overlay={<Tooltip>Cargar formulario</Tooltip>}>
											<Button
												onClick={() => {
													if (cargado !== '' && item !== '111') {
														alert('Este formulario ya fue completado')
													} else {
														setOrden(idOrden)
														setSucursal(idSucursal)
														setCliente(idCliente)
														setIdServ(idServicio)
														handleShowForm()
														setItemServicio(item)
														setNombreServicio(nombre)
													}
												}}
											>
												{sistema}
											</Button>
										</OverlayTrigger>
									</td>
									<td>
										<Mutation
											mutation={ACTUALIZAR_ESTADO_SERVICIO}
											onCompleted={() => {
												// refetch()
											}}
										>
											{(actualizarEstadoServicio) => (
												<OverlayTrigger placement={'top'} overlay={<Tooltip>{estado === 'COMPLETO' ? 'Completado' : cargado ? 'Listo para visado' : 'Pendiente de realización'}</Tooltip>}>
													<button
														style={{ backgroundColor: color }}
														onClick={() => {
															if (cargado === '') {
																alert('El formuario todavia no está cargado')
																return false
															}

															if (estado === 'PENDIENTE' && item === '105') {
																return actualizarEstadoServicio({
																	variables: {
																		id: idOrden,
																		idServicio,
																	},
																})
															}
														}}
													>
														{estado}
													</button>
												</OverlayTrigger>
											)}
										</Mutation>
									</td>
									<td style={{ width: '5%' }}>
										{(estado !== 'PENDIENTE' || ver_111) && (
											<Button
												variant="success"
												onClick={() => {
													handleShowListar()
													setIdForm(cargado)
													setItemServicio(item)
													setNombreServicio(nombre)
												}}
											>
												Ver
											</Button>
										)}
									</td>
								</tr>
							)
						}}
					</Query>

					{showListar && <DetalleOrden id={idForm} item={itemServicio} handleCloseListar={handleCloseListar} nombre={nombreServicio} />}
				</>
			)
		})

		return valores
	}

	return (
		<>
			<MostrarLista>
				<Modal show={true} centered onHide={handleCloseListar} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>{nombre}</Modal.Title>
					</Modal.Header>
					<ModalBody>
						<div>
							{sortBy(datos111, 'nombre').map((e, index) => {
								return <Listar111Interno key={e.id} id={e.servicio} periodicidad={e.periodicidad} datos={e} refetch111={refetch111} />
							})}
						</div>
						<DetalleStyleDiv>
							<Card
								onClick={() => {
									setView(!view)
								}}
								style={{ cursor: 'pointer' }}
							>
								<Card.Header>
									<h5>Servicios Adicionales</h5>
								</Card.Header>
								<Card.Body style={{ display: view ? '' : 'none' }}>
									<table>
										<tbody className="body_detalle">{mostrarTabla(datos)}</tbody>
									</table>
								</Card.Body>
							</Card>
						</DetalleStyleDiv>
					</ModalBody>
				</Modal>
			</MostrarLista>
			{showForm && (
				<Formularios itemServicio={itemServicio} handleClose={handleClose} idOrden={orden} idSucursal={sucursal} idCliente={cliente} idServ={idServ} nombre={nombreServicio} es_120={true} />
			)}
		</>
	)
}

export default Listar111
