import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { CREAR_INSTRUMENTAL } from '../../mutations'
import styled from 'styled-components'
import FormCliente from './Form'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

const validate = Yup.object({
	nombre: Yup.string().required('El campo nombre es obligatorio'),
	modelo: Yup.string().required('El campo modelos es requerido'),
	marca: Yup.string().required('El campo marca es requerido'),
	numeroDeSerie: Yup.string().required('El campo Número de serie es requeridoww'),
})
const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const Alta = ({ refetch, handleClose, handleNoti }) => {
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')

	const handleFileChange = (valor) => {
		const extencion = valor.name.split('.').pop()
		const extraerNombreOriginal = valor.name
		var blob = valor.slice(0, valor.size, extencion)
		var newFile = new File([blob], uuidv4() + '.' + extencion, {
			type: valor.type,
		})
		setNombreOriginal(extraerNombreOriginal)
		setAdjunto(newFile)
	}

	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Nuevo Instrumento de medición</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={CREAR_INSTRUMENTAL}
				onCompleted={(e) => {
					if (e.crearInstrumental.status) {
						if (e.uploadFile.status) {
						} else {
							alert('El archivo no fue cargado')
						}
						refetch()
						handleClose()
						handleNoti(1)
					} else {
						alert('Error al cargar Formulario')
					}
				}}
			>
				{(crearInstrumental) => (
					<Formik
						initialValues={{
							id: '',
							nombre: '',
							modelo: '',
							marca: '',
							numeroDeSerie: '',
							vence: '',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { nombre, modelo, marca, numeroDeSerie, vence } = values
							const input = {
								nombre,
								modelo,
								marca,
								numeroDeSerie,
								vence,
								adjunto: adjunto.name,
								adjuntoNombreOriginal: nombreOriginal,
							}
							return crearInstrumental({
								variables: { input, file: adjunto, folder: 'instrumentales' },
							})
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormCliente errors={errors} handleChange={handleChange} touched={touched} values={''} handleFileChange={handleFileChange} />
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
												Agregar instrumento
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default Alta
