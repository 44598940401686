import {
	Form,
	Row,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { NUEVO_CLIENTE } from '../../mutations'
import styled from 'styled-components'
import FormCliente from './FormCliente'

const validate = Yup.object({
	razonSocial: Yup.string().required('El campo Razón Social es obligatorio'),
	cuit: Yup.string()
		.max(11, 'Máximo 11 caracteres, no incluir guiones ni puntos')
		.required('El campo cuit es requerido'),
	condicionIva: Yup.string().required('El campo Condición Iva es requerido'),
	telefono: Yup.string().required('El campo Teléfono es requerido'),
	email: Yup.string().required('El campo Email es requerido'),
})
const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const AltaCliente = ({ refetch, handleClose, handleNoti }) => {
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Nuevo Cliente</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={NUEVO_CLIENTE}
				onCompleted={() => {
					refetch()
					handleClose()
					handleNoti(1)
				}}
			>
				{(crearCliente) => (
					<Formik
						initialValues={{
							id: '',
							razonSocial: '',
							cuit: '',
							condicionIva: '',
							telefono: '',
							email: '',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { razonSocial, cuit, condicionIva, telefono, email } =
								values

							const input = {
								razonSocial,
								cuit,
								condicionIva,
								telefono,
								email,
							}
							return crearCliente({ variables: { input } })
						}}
					>
						{({
							errors,
							handleSubmit,
							handleChange,
							isSubmitting,
							touched,
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormCliente
										errors={errors}
										handleChange={handleChange}
										touched={touched}
										values={''}
									/>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button
												variant="success"
												disabled={isSubmitting}
												type="submit"
												className="enviar"
											>
												Agregar cliente
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default AltaCliente
