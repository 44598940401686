import styled from 'styled-components'

const SucursalStyleDiv = styled.div`
	display: flex;
	overflow: hidden;
	margin-top: 80px;
	flex-wrap: wrap;
	margin-left: 6em;
	/* height: calc(100vh - 80px); */
	padding-bottom: 30px;
`

const IzquierdaDiv = styled.div`
	width: ${(props) => (props.home ? '30%' : props.general ? '40%' : '30%')};
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
	@media (max-width: 1280px) {
		margin-bottom: 15px;
	}
	@media (min-width: 1281px) and (max-width: 1366px) {
		margin-bottom: 10px;
	}
	.superior,
	.listado,
	.datos {
		width: 100%;
	}
	.superior {
		margin-bottom: 20px;
	}
	.superior .legajo {
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;
	}

	.listado {
		height: 50%;
		overflow-y: none;
	}
`
const DerechaDiv = styled.div`
	width: ${(props) => (props.general ? '60%' : '70%')};
	height: 100%;
	padding-left: 3em;
	padding-right: 3em;
`

export { SucursalStyleDiv, IzquierdaDiv, DerechaDiv }
