import { Query } from 'react-apollo'
import { CLIENTE_Y_SUCURSAL } from '../../queries'
import { Card } from 'react-bootstrap'

import Loading from '../Loading'
import styled from 'styled-components'
const DatosClientesDiv = styled.div`
	p {
		margin-bottom: 2px;
		word-break: break-all;
	}
`
const DatosCliente = ({ id, idSuc }) => {
	return (
		<DatosClientesDiv>
			<Query query={CLIENTE_Y_SUCURSAL} variables={{ id, idSuc }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					const { cuit, razonSocial, email } = data.getCliente
					const { nombreEstablecimiento, direccion, auxiliarAsignado } = data.getSucursal
					return (
						<>
							<Card.Text>
								<b>Razon Social:</b> {razonSocial}
							</Card.Text>
							<Card.Text>
								<b>Cuit:</b> {cuit}
							</Card.Text>
							<Card.Text>
								<b>Dirección:</b> {direccion} ({nombreEstablecimiento} )
							</Card.Text>
							<Card.Text>
								<b>Correo:</b> {email}
							</Card.Text>
							<Card.Text>
								<b>Auxiliar asignado:</b> {auxiliarAsignado?.nombre ? auxiliarAsignado.nombre : 'Sin Asignar'}
								{auxiliarAsignado?.usuario ? `(${auxiliarAsignado.usuario})` : ''}
							</Card.Text>
						</>
					)
				}}
			</Query>
		</DatosClientesDiv>
	)
}

export default DatosCliente
