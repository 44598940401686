import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Query } from 'react-apollo'
import { USUARIOS } from '../../queries'
import makeAnimated from 'react-select/animated'
import Loading from '../Loading'
const Formulario = ({ errors, handleChange, touched, values, setFieldTouched, setFieldValue }) => {
	let { auxiliarAsignado } = values
	return (
		<Query query={USUARIOS} fetchPolicy={'network-only'} variables={{ asgin: true }}>
			{({ loading, error, data, refetch }) => {
				if (loading) return <Loading />
				if (error) return console.log(error.message)
				return (
					<div>
						<Row>
							<Form.Group className="mb-3" as={Col} controlId="modulo">
								<Form.Label>Módulo</Form.Label>
								<Form.Control
									type="text"
									name="modulo"
									onChange={handleChange}
									placeholder="Módulo"
									isInvalid={touched.modulo && errors.modulo}
									defaultValue={values.modulo ? values.modulo.toString().replace('.', ',') : ''}
								/>
								<Form.Control.Feedback type="invalid">{values.modulo && errors.modulo}</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className="mb-3" as={Col} controlId="auxiliarAsignado">
								<Form.Label>Seleccionar Auxiliar</Form.Label>
								<Select
									onChange={(e) => {
										setFieldValue('auxiliarAsignado', e?.id)
									}}
									onBlur={setFieldTouched}
									options={data.obtenerUsuarios.usuarios}
									isClearable
									isSearchable
									name="auxiliarAsignado"
									components={makeAnimated()}
									placeholder="Seleccionar Auxiliar a asignar"
									getOptionValue={(options) => options.id}
									getOptionLabel={(options) => options.nombre}
									noOptionsMessage={() => 'No se encontraron auxiliares'}
									isInvalid={touched.auxiliarAsignado && errors.auxiliarAsignado}
									defaultValue={auxiliarAsignado ? auxiliarAsignado : ''}
								></Select>{' '}
								<Form.Control.Feedback type="invalid">{touched.auxiliarAsignado && errors.auxiliarAsignado}</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</div>
				)
			}}
		</Query>
	)
}

export default Formulario
