import { Mutation, Query } from 'react-apollo'
import { SERVICIO } from '../../queries'
import UsuarioNombre from './UsuarioNombre'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CANCELAR_SERVICIO } from '../../mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateBack } from '@fortawesome/free-solid-svg-icons'
import { permisosById } from './Permisos'

export const mostrarTabla = (
	servicio,
	idOrden,
	refetch,
	permiso,
	handleShowForm,
	setItemServicio,
	setNombreServicio,
	setShowFormVisado,
	handleShowListar,
	setIdForm,
	setIdServ
) => {
	return servicio.map((e, index) => {
		const { id: idServicio, servicio, fechaInicio, estado, cargado, auxiliar } = e
		const color = estado === 'COMPLETO' ? '#28A745' : ''
		return (
			<Query query={SERVICIO} variables={{ id: servicio }} fetchPolicy={'network-only'} key={index}>
				{({ loading, error, data }) => {
					if (loading) return false
					if (error) return `Error: ${error.message}`
					const { item, nombre, sistema } = data.getServicio
					const ver_111 = cargado && (item === '111' || item === '120') ? true : false
					const textOverlay = permiso !== 5 ? 'Cargar formulario' : 'Pendiente de carga'
					return (
						<>
							<tr style={{ padding: '10px' }} key={data.getServicio.id}>
								<td style={{ width: '30%' }}>
									<b>({item})</b> <br />
									{nombre}
								</td>
								<td style={{ width: '30%' }}>
									<b>{fechaInicio}</b> <br />
									<UsuarioNombre id={auxiliar} />
								</td>
								<td style={{ width: '40%' }}>
									{/* {permiso !== 5 && ( */}
									<OverlayTrigger placement={'top'} overlay={<Tooltip>{textOverlay}</Tooltip>}>
										<Button
											onClick={() => {
												if (permiso === 5) return false
												if (permiso !== 1 && item === 111) return alert('Este formulario ya fue completado')
												if (item !== '111' && item !== '120' && cargado) return alert('Este formulario ya fue completado')
												if (permiso !== 1 && item === '120') return alert('No tiene permisos')

												setIdServ(idServicio)
												handleShowForm()
												setItemServicio(item)
												setNombreServicio(nombre)
											}}
										>
											{sistema}
										</Button>
									</OverlayTrigger>
									{/* )} */}

									<OverlayTrigger placement={'top'} overlay={<Tooltip>{estado === 'COMPLETO' ? 'Completado' : 'Pendiente de realización'}</Tooltip>}>
										<button style={{ backgroundColor: color, padding: '6px', paddingTop: '5px' }}>{estado}</button>
									</OverlayTrigger>
									{(estado !== 'PENDIENTE' || ver_111) && (
										<Button
											variant="success"
											onClick={() => {
												handleShowListar()
												setIdForm(cargado)
												setItemServicio(item)
												setNombreServicio(nombre)
											}}
										>
											Ver
										</Button>
									)}
									{estado !== 'PENDIENTE' && permisosById(localStorage.getItem('idAux')) && (
										<Mutation
											mutation={CANCELAR_SERVICIO}
											onCompleted={() => {
												refetch()
											}}
										>
											{(cancelarServicio) => (
												<OverlayTrigger placement={'top'} overlay={<Tooltip>Cancelar servicio</Tooltip>}>
													<Button
														style={{ backgroundColor: '#ffc107', width: '30px' }}
														onClick={() => {
															if (window.confirm('¿Estas seguro de cancelar el servicio?')) {
																return cancelarServicio({
																	variables: {
																		id: idOrden,
																		idServicio,
																	},
																})
																	.then((e) => {})
																	.catch((e) => {
																		console.log(e, 'error')
																	})
															}
														}}
													>
														<FontAwesomeIcon icon={faRotateBack} />
													</Button>
												</OverlayTrigger>
											)}
										</Mutation>
									)}
								</td>
								<td></td>
							</tr>
						</>
					)
				}}
			</Query>
		)
	})
}
