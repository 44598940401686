import { Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { CONFIRMAR_USUARIO } from '../../mutations'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'

const SetForm = styled.div`
	margin-top: 30px;
	form {
		width: 270px;
	}
	input {
		border-radius: 0;
	}
	button {
		margin-top: 5px;
		border-radius: 0;
		width: 100%;
	}
	p {
		margin-top: 5px;
		font-size: 12px;
		color: #868e96;
		cursor: pointer;
	}
	a {
		color: #fff;
		text-decoration: none;
	}
`
const validate = Yup.object({
	password: Yup.string()
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d]|[^ ]){6,50}$/, 'La contraseña debe tener al menos 6 caracteres, mayúsculas, minúsculas, números y simbolos')
		.required('La constraseña es requerida'),
	repeatPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
		.required('Repetir contraseña es requerido'),
})

const exito = () => {
	toast.success('Registro correcto', {
		position: 'bottom-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}

const FormConfirmar = ({ cliente, token, usuario }) => {
	const { id } = cliente
	const history = useHistory()
	return (
		<>
			<SetForm>
				<Mutation
					mutation={CONFIRMAR_USUARIO}
					onCompleted={() => {
						exito()
						setTimeout(() => {
							history.push('/login')
						}, 3000)
					}}
				>
					{(confirmarUsuario, { loading, error, data }) => (
						<Formik
							initialValues={{
								id,
								token,
								usuario,

								password: '',
								repeatPassword: '',
							}}
							validationSchema={validate}
							onSubmit={(values) => {
								const { password } = values
								const input = {
									id,
									token,
									usuario,
									password,
								}
								return confirmarUsuario({ variables: { input } })
							}}
						>
							{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
								<Form onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Crear contraseña</Form.Label>
										<Form.Control onChange={handleChange} type="password" placeholder="Ingresar contraseña" name="password" isInvalid={touched.password && errors.password} />
										<Form.Control.Feedback type="invalid">{touched.password && errors.password}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicRepeatPassword">
										<Form.Label>Repetir Contraseña</Form.Label>
										<Form.Control onChange={handleChange} type="password" placeholder="Ingresar contraseña" name="repeatPassword" isInvalid={touched.repeatPassword && errors.repeatPassword} />
										<Form.Control.Feedback type="invalid">{touched.repeatPassword && errors.repeatPassword}</Form.Control.Feedback>
									</Form.Group>
									<Button variant="primary" type="submit" disabled={isSubmitting}>
										Registrar
									</Button>
								</Form>
							)}
						</Formik>
					)}
				</Mutation>
			</SetForm>
			<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	)
}

export default FormConfirmar
