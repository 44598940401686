import { SERVICIO } from '../../queries'
import { Query } from 'react-apollo'
import FormEditarServicio from './FormEditar'
import Loading from '../Loading'

const EditarServicio = ({ id, handleClose, refetch }) => {
	return (
		<>
			<Query query={SERVICIO} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return (
						<FormEditarServicio
							cliente={data.getServicio}
							refetch={refetch}
							handleClose={handleClose}
						/>
					)
				}}
			</Query>
		</>
	)
}

export default EditarServicio
