import { Formik } from 'formik'
import { useState } from 'react'
import { Mutation, useMutation } from 'react-apollo'
import { Button, Form, Modal, ModalBody, ModalFooter, ProgressBar, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { ELIMINAR_ARCHIVO, NUEVO_ARCHIVO, UPLOAD_FILE } from '../../mutations'
import FormGeneralCampos from '../Formularios/FormGeneral/FormGeneralCampos'

const ContenedorArchivos = styled.div`
	background: ${(props) => (props.bg % 2 === 0 ? '' : '#cccccc')};
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	width: 100%;
	label {
		margin-right: 100px;
		width: 60%;
	}
	button {
		margin-left: 10px;
	}
`

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`
const MostrarAdjuntos = ({ datos, handleClose, item, permiso = null, idCargado, handleCloseEntire }) => {
	const [deleteArchivo] = useMutation(ELIMINAR_ARCHIVO)
	const [progreso, setProgreso] = useState(false)
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')
	const [conteo, setConteo] = useState(0)

	const [uploadFile] = useMutation(UPLOAD_FILE)
	const [showAdd, setShowAdd] = useState(false)

	const handleFileChange = (valor) => {
		let archivosNombre = []
		let archivos = []
		for (let i = 0; i < valor.length; i++) {
			const extencion = valor[i].name.split('.').pop()
			const extraerNombreOriginal = valor[i].name
			var blob = valor[i].slice(0, valor[i].size, extencion)
			var newFile = new File([blob], uuidv4() + '.' + extencion, {
				type: valor[i].type,
			})
			archivosNombre.push({ adjuntoNombreOriginal: extraerNombreOriginal, adjunto: newFile.name })
			archivos.push({ adjunto: newFile })
		}
		setNombreOriginal(archivosNombre)
		setAdjunto(archivos)
	}
	const mostrarArchivos = (datos) => {
		return datos.map((archivo, index) => {
			const pathUrl = 'documents'
			let urlImage = `https://lenge.s3.amazonaws.com/${pathUrl}/${archivo.adjunto}`
			return (
				<ContenedorArchivos bg={index}>
					<label htmlFor="">{archivo.adjuntoNombreOriginal}</label>
					<Button href={urlImage} target="_blank" rel="noreferrer" key={index}>
						Descargar
					</Button>
					{permiso === 1 && (
						<Button
							variant="danger"
							onClick={() => {
								deleteArchivo({
									variables: {
										id: idCargado,
										elemento: index,
									},
								})
								handleCloseEntire()
							}}
						>
							Eliminar
						</Button>
					)}
				</ContenedorArchivos>
			)
		})
	}
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					Archivos adjuntos
					{!showAdd && permiso === 1 && (
						<Button
							style={{ marginLeft: '20px' }}
							onClick={() => {
								setShowAdd(true)
							}}
						>
							Agregar archivos
						</Button>
					)}
				</Modal.Title>
			</Modal.Header>
			<ModalBody>
				{permiso === 1 && showAdd && (
					<Mutation
						mutation={NUEVO_ARCHIVO}
						onCompleted={async (e) => {
							let estado = e.actualizarArchivos.status
							if (estado === true) {
								setProgreso(true)
								let actual = 0
								adjunto.map(async (e, index) => {
									try {
										await uploadFile({
											variables: { file: e.adjunto, folder: 'documents' },
										}).then(() => {})
										actual = actual + 1
										setConteo(actual)
										if (actual === adjunto.length) {
											setConteo(0)
											setProgreso(false)
											handleCloseEntire()
										}
										return 'ok'
									} catch (error) {
										if (error) {
											return 'no'
										}
									}
								})
							}
						}}
					>
						{(crearFormGeneral) => (
							<Formik
								initialValues={{
									fechaMedicion: '',
									horaInicio: '',
									horaFin: '',
								}}
								onSubmit={(values) => {
									let input

									input = {
										id: idCargado,
										archivos: nombreOriginal,
									}
									return crearFormGeneral({
										variables: { input },
									})
								}}
							>
								{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
									<Form onSubmit={handleSubmit} action="/upload" encType="multipart/form-data" method="POST">
										<ModalBody>
											<FormGeneralCampos handleChange={handleChange} handleFileChange={handleFileChange} />
										</ModalBody>
										<ModalFooter>
											<Row>
												<FooterButton>
													<Button
														variant="secondary"
														onClick={() => {
															setShowAdd(false)
														}}
													>
														Cancelar
													</Button>
													{adjunto.length > 0 && (
														<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
															Guardar
														</Button>
													)}
												</FooterButton>
											</Row>
											{progreso && (
												<Row style={{ width: '100%', marginTop: '20px' }}>
													<ProgressBar variant="success" animated now={(conteo / adjunto.length) * 100} style={{ padding: '0' }} />
												</Row>
											)}
										</ModalFooter>
									</Form>
								)}
							</Formik>
						)}
					</Mutation>
				)}
				{mostrarArchivos(datos)}
			</ModalBody>
			<ModalFooter>
				<Row>
					<Button variant="secondary" onClick={handleClose}>
						Cerrar
					</Button>
				</Row>
			</ModalFooter>
		</Modal>
	)
}

export default MostrarAdjuntos
