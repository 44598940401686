import gql from 'graphql-tag'

export const CLIENTES = gql`
	query getClientes($search: String, $limite: Int, $offset: Int, $email: String, $orderBy: String, $asc: Int) {
		getClientes(search: $search, limite: $limite, offset: $offset, email: $email, orderBy: $orderBy, asc: $asc) {
			clientes {
				id
				razonSocial
				cuit
				telefono
				email
				debe
			}
			total
		}
	}
`

export const CLIENTE = gql`
	query getCliente($id: ID) {
		getCliente(id: $id) {
			id
			razonSocial
			cuit
			condicionIva
			telefono
			email
		}
	}
`
export const USUARIOS = gql`
	query obtenerUsuarios($search: String, $limit: Int, $offset: Int, $es_admin: Boolean, $asgin: Boolean, $orderBy: String, $asc: Int) {
		obtenerUsuarios(search: $search, limite: $limit, offset: $offset, es_admin: $es_admin, asgin: $asgin, orderBy: $orderBy, asc: $asc) {
			usuarios {
				id
				usuario
				nombre
				area
				rol
			}
			total
		}
	}
`
export const ADMINISTRADORES = gql`
	query obtenerUsuarios($search: String, $limit: Int, $offset: Int, $es_admin: Boolean) {
		obtenerUsuarios(search: $search, limite: $limit, offset: $offset, es_admin: $es_admin) {
			usuarios {
				id
				usuario
				nombre
				area
				rol
			}
			total
		}
	}
`
export const USUARIO_ACTUAL = gql`
	query obtenerUsuarioActual {
		obtenerUsuarioActual {
			id
			usuario
			nombre
			rol
			area
		}
	}
`

export const USUARIO = gql`
	query obtenerUsuario($id: ID!) {
		obtenerUsuario(id: $id) {
			id
			usuario
			nombre
			area
			rol
			token
			adjunto
		}
	}
`
export const USUARIO_NOMBRE = gql`
	query obtenerUsuario($id: ID!) {
		obtenerUsuario(id: $id) {
			nombre
		}
	}
`

export const SERVICIOS = gql`
	query getServicios($search: String, $limite: Int, $offset: Int, $orderBy: String, $asc: Int) {
		getServicios(search: $search, limite: $limite, offset: $offset, orderBy: $orderBy, asc: $asc) {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
			total
		}
	}
`
export const SERVICIOS_120 = gql`
	query getServicios120 {
		getServicios120 {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
			total
		}
		getServicios111 {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
		}
	}
`
export const SERVICIOS_Y_USUARIOS_AREA_ROL = gql`
	query obtenerDatos($area: String, $rol: String) {
		getServicios {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
		}
		getServicios111 {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
		}

		obtenerUsuariosAreaRol(area: $area, rol: $rol) {
			usuarios {
				id
				usuario
				nombre
				area
				rol
			}
		}
		getClientes {
			clientes {
				id
				razonSocial
				cuit
				telefono
				email
				debe
			}
		}
		getServicios120 {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
		}
	}
`

export const SERVICIO = gql`
	query getServicio($id: ID) {
		getServicio(id: $id) {
			id
			item
			nombre
			area
			sistema
			vigencia
		}
	}
`

export const SUCURSALES_CLIENTE = gql`
	query getSucursalesClientes($id: ID) {
		getSucursalesClientes(idCliente: $id) {
			id
			idCliente
			nombreEstablecimiento
			direccion
			localidad
			codigoPostal
		}
	}
`

export const SUCURSAL_CLIENTE = gql`
	query getSucursal($id: ID) {
		getSucursal(id: $id) {
			id
			idCliente
			nombreEstablecimiento
			direccion
			localidad
			codigoPostal
			auxiliarAsignado {
				id
			}
			modulo
		}
	}
`

export const SERVICIO_PRESTADO_SUCURSAL = gql`
	query getServicioPrestadoSucursal($search: String, $id: ID, $auxiliar: String, $email: String) {
		getServicioPrestadoSucursal(search: $search, idSucursal: $id, auxiliar: $auxiliar, email: $email) {
			id
			nOrden
			idSucursal
			idCliente
			fecha
			estado
			servicios {
				id
				fechaFin
				fechaInicio
				servicio
				cargado
			}
			adjunto {
				adjunto
				adjuntoNombreOriginal
			}
			visto
		}
	}
`

export const CLIENTE_Y_SUCURSAL = gql`
	query getClienteYSucursal($id: ID, $idSuc: ID) {
		getCliente(id: $id) {
			id
			razonSocial
			cuit
			condicionIva
			telefono
			email
		}
		getSucursal(id: $idSuc) {
			id
			nombreEstablecimiento
			localidad
			provincia
			codigoPostal
			email
			direccion
			auxiliarAsignado {
				usuario
				nombre
			}
		}
	}
`
export const SERVICIO_PRESTADO = gql`
	query getServicioPrestado($id: ID) {
		getServicioPrestado(id: $id) {
			id
			nOrden
			idCliente
			idSucursal
			fecha
			servicios {
				id
				servicio
				auxiliar {
					id
				}
				fechaInicio
				fechaFin
				estado
				cargado
			}
			estado
			observacion
			adjunto {
				adjunto
				adjuntoNombreOriginal
			}
		}
	}
`
export const SERVICIO_PRESTADO_120 = gql`
	query getServicioPrestado120($idOrden: ID) {
		getServicioPrestado120(idOrden: $idOrden) {
			id
			idCliente
			idSucursal
			idServicio
			idOrden
			servicio
		}
	}
`

export const INSTRUMENTALES = gql`
	query getInstrumentales($search: String, $limite: Int, $offset: Int, $orderBy: String, $asc: Int) {
		getInstrumentales(search: $search, limite: $limite, offset: $offset, orderBy: $orderBy, asc: $asc) {
			instrumentales {
				id
				nombre
				marca
				modelo
				numeroDeSerie
				vence
				adjunto
				activo
				eliminado
			}
			total
		}
	}
`
export const INSTRUMENTAL = gql`
	query getInstrumental($id: ID) {
		getInstrumental(id: $id) {
			id
			nombre
			marca
			modelo
			numeroDeSerie
			vence
			adjunto
			adjuntoNombreOriginal
		}
	}
`

export const OBTENER_105 = gql`
	query obtener105($id: ID!) {
		obtener105(id: $id) {
			id
			idCliente
			cliente {
				razonSocial
				cuit
			}
			idSucursal
			sucursal {
				direccion
				localidad
				codigoPostal
			}
			idServicio
			fechaMedicion
			horaInicio
			horaFin
			observacion
			datosMedicion {
				id
				numeroDeToma
				sector
				descripcion
				uso
				esquema
				valorObtenidoOhm
				cumple
				continuo
				tieneCapacidad
				proteccion
				desconectar
			}
			conclusiones
			recomendaciones
			croquis
			instrumentoDatos {
				nombre
				marca
				modelo
				numeroDeSerie
				vence
				adjunto
				adjuntoNombreOriginal
			}
			final
			visado
		}
	}
`
export const OBTENER_105_ORDEN = gql`
	query obtener105Orden($idServicio: ID, $idOrden: ID) {
		obtener105Orden(idServicio: $idServicio, idOrden: $idOrden) {
			id
			idCliente
			cliente {
				razonSocial
				cuit
			}
			idSucursal
			sucursal {
				direccion
				localidad
				codigoPostal
			}
			idServicio
			fechaMedicion
			horaInicio
			horaFin
			observacion
			datosMedicion {
				id
				numeroDeToma
				sector
				descripcion
				uso
				esquema
				valorObtenidoOhm
				cumple
				continuo
				tieneCapacidad
				proteccion
				desconectar
			}
			conclusiones
			recomendaciones
			croquis
			instrumentoDatos {
				nombre
				marca
				modelo
				numeroDeSerie
				vence
				adjunto
				adjuntoNombreOriginal
			}
			final
			visado
		}
	}
`
export const SERVICIO_PRESTADOS_GENERAL = gql`
	query getServiciosPrestados($search: String, $limite: Int, $offset: Int, $auxiliar: String, $email: String, $minDate: String, $maxDate: String) {
		getServiciosPrestados(search: $search, limit: $limite, offset: $offset, auxiliar: $auxiliar, email: $email, minDate: $minDate, maxDate: $maxDate) {
			id
			nOrden
			idCliente {
				id
				razonSocial
			}
			idSucursal {
				id
				nombreEstablecimiento
			}
			fecha
			servicios {
				id
				servicio
				fechaInicio
				fechaFin
				estado
			}
			estado
			observacion
			adjunto {
				adjunto
				adjuntoNombreOriginal
			}
			visto
		}
	}
`
export const SERVICIO_PRESTADOS_GENERAL_FOR_EXPORT = gql`
	query getServiciosPrestadosForExport($search: String, $limite: Int, $offset: Int, $auxiliar: String, $email: String, $minDate: String, $maxDate: String) {
		getServiciosPrestadosForExport(search: $search, limit: $limite, offset: $offset, auxiliar: $auxiliar, email: $email, minDate: $minDate, maxDate: $maxDate) {
			nOrden
			fecha
			idCliente {
				razonSocial
			}
			estado
			item
			auxiliarNew
			area
		}
	}
`
export const CLIENTE_Y_SUCURSAL_NOMBRES = gql`
	query getClienteYSucursal($id: ID, $idSuc: ID) {
		getCliente(id: $id) {
			razonSocial
		}
		getSucursal(id: $idSuc) {
			nombreEstablecimiento
		}
	}
`

export const OBTENER_GENERAL = gql`
	query obtenerGeneral($id: ID!) {
		obtenerGeneral(id: $id) {
			id
			idCliente
			idSucursal
			idServicio
			idOrden
			fechaMedicion
			horaInicio
			horaFin
			archivos {
				adjunto
				adjuntoNombreOriginal
			}
		}
	}
`

export const OBTENER_GENERAL111 = gql`
	query obtenerGeneral111($id: ID!) {
		obtenerGeneral111(id: $id) {
			id
			idCliente
			idSucursal
			idServicio
			idOrden
			fechaMedicion
			horaInicio
			horaFin
			periodo
			createAt
			archivos {
				adjunto
				adjuntoNombreOriginal
			}
		}
	}
`

export const OBTENER_111 = gql`
	query obtener111Orden($idServicio: ID, $idOrden: ID) {
		obtener111Orden(idServicio: $idServicio, idOrden: $idOrden) {
			id
			idCliente
			idSucursal
			idServicio
			idOrden
			fechaMedicion
			horaInicio
			horaFin
			adjunto
			nombre
		}
	}
`

export const OBTENER_111_L = gql`
	query obtenerFormL($id: ID!) {
		obtenerFormL(id: $id) {
			id
			idCliente
			idSucursal
			idServicio
			idOrden
			idSubServicio
			fecha
			hora
			observacionesRealizadas {
				observacion
				adjunto
				adjuntoNombreOriginal
			}
			periodo
			createAt
			final
		}
	}
`

export const LEGAJO = gql`
	query getLegajo($idSucursal: ID, $item: String) {
		getLegajo(idSucursal: $idSucursal, item: $item) {
			idOrden {
				nOrden
				idOrden
			}
			servicio {
				item
				nombre
			}
			idCargado
			cargado {
				adjunto
				adjuntoNombreOriginal
			}
			estado
			fecha
		}
	}
`
export const ID_SERVICIO = gql`
	query getIDServicioByItem($item: String) {
		getIDServicioByItem(item: $item) {
			id
		}
	}
`

export const SERVICIOS111 = gql`
	query getServicios($search: String, $limite: Int, $offset: Int) {
		getServicios111(search: $search, limite: $limite, offset: $offset) {
			servicios {
				id
				item
				nombre
				area
				sistema
				vigencia
			}
			total
		}
	}
`
export const SERVICIO111 = gql`
	query getServicio111($id: ID) {
		getServicio111(id: $id) {
			id
			item
			nombre
			area
			sistema
			vigencia
		}
	}
`

export const OBTENER_111_BY_ORDEN = gql`
	query obtener111Orden($idOrden: ID) {
		obtener120Orden(idOrden: $idOrden) {
			id
			idCliente
			cliente {
				razonSocial
			}
			idSucursal
			sucursal {
				direccion
			}
			idServicio
			idOrden
			servicio
			fechaInicio
			fechaFin
			estado
			cargado
		}
		obtener111Orden(idOrden: $idOrden) {
			id
			idCliente
			cliente {
				razonSocial
			}
			idSucursal
			sucursal {
				direccion
			}
			idServicio
			idOrden
			servicio
			periodicidad
			nombre
			serviciosPorPeriodo {
				id
				periodo
				idForm
				estado
				cargado
			}
		}
	}
`
export const OBTENER_120_BY_ORDEN = gql`
	query obtener120Orden($idOrden: ID) {
		obtener120Orden(idOrden: $idOrden) {
			id
			idCliente
			cliente {
				razonSocial
			}
			idSucursal
			sucursal {
				direccion
			}
			idServicio
			idOrden
			servicio
			fechaInicio
			fechaFin
			estado
			cargado
		}
		obtener111Orden(idOrden: $idOrden) {
			id
			idCliente
			cliente {
				razonSocial
			}
			idSucursal
			sucursal {
				direccion
			}
			idServicio
			idOrden
			servicio
			periodicidad
			nombre
			serviciosPorPeriodo {
				id
				periodo
				idForm
				estado
				cargado
			}
		}
	}
`

export const SUCURSAL_CLIENTE_AUX = gql`
	query getSucursalesClientesTodos($search: String, $limite: Int, $offset: Int) {
		getSucursalesClientesTodos(search: $search, limite: $limite, offset: $offset) {
			sucursales {
				id
				nombreEstablecimiento
				auxiliarAsignado {
					id
					nombre
				}
				modulo
				idCliente {
					razonSocial
				}
			}
			total
		}
	}
`

export const GET_AUX_SUC = gql`
	query getAuxSucursal($id: ID) {
		getAuxSucursal(id: $id) {
			auxiliarAsignado {
				id
			}
		}
	}
`
export const GET_NOTIFICACIONES = gql`
	query obtenerNotificaciones($limit: Int, $offset: Int, $usuario: ID!) {
		obtenerNotificaciones(limite: $limit, offset: $offset, usuario: $usuario) {
			notificaciones {
				id
				texto
				tipo
				createdAt
				vista
			}
			total
		}
	}
`
export const GET_AVISO = gql`
	query obtenerNotificacionesAviso($usuario: ID!) {
		obtenerNotificacionesAviso(usuario: $usuario) {
			total
		}
	}
`
