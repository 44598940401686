// import { useQuery } from '@apollo/client'
import { Query } from 'react-apollo'
import styled from 'styled-components'
import { USUARIO_ACTUAL } from '../queries'
import estudioLenge from './../img/log_lenge_new.png'

const DivError = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-direction: column;
	padding-top: 80px;
	p {
		margin: auto;
		padding: 1rem;
		background-color: #3498db;
		color: white;
		font-weight: 600;
		border-radius: 10px;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
	}
	.div_error {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			width: 800px;
		}
	}
	.estudio {
	}
`
const Session = (Compoment) => (props) => {
	return (
		<Query query={USUARIO_ACTUAL}>
			{({ loading, error, data, refetch }) => {
				if (loading) return null
				if (error) {
					if (process.env.NODE_ENV === 'development') {
						console.log(error)
					}
					return (
						<DivError>
							<div className="div_error">
								<img src={estudioLenge} alt="" className="estudio" />
								<p style={{ textAlign: 'center', marginTop: '20px' }}>
									Error al conectar a la base de datos, por favor actualice la página. Si el problema persiste comuniquese con soporte.
								</p>
							</div>
						</DivError>
					)
				}
				if (data.obtenerUsuarioActual?.id) {
					localStorage.setItem('idAux', data.obtenerUsuarioActual.id)
				}
				return <Compoment {...props} refetch={refetch} session={data} />
			}}
		</Query>
	)
}

export default Session
