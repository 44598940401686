import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { NUEVO_USUARIO } from '../../mutations'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState } from 'react'

const SetForm = styled.div`
	margin-top: 30px;
	form {
		width: 270px;
	}
	input {
		border-radius: 0;
	}
	button {
		margin-top: 5px;
		border-radius: 0;
		width: 100%;
	}
	p {
		margin-top: 5px;
		font-size: 12px;
		color: #868e96;
		cursor: pointer;
	}
	a {
		color: #fff;
		text-decoration: none;
	}
`
const SetError = styled.div`
	text-align: center;
	p {
		color: red;
	}
`

const exito = () => {
	toast.success('Registro correcto', {
		position: 'bottom-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	})
}
export const FormRegistro = () => {
	const history = useHistory()
	const [errorLogin, setErrorLogin] = useState(false)

	const validate = Yup.object({
		cuit: Yup.string().required('El campo email es obligatorio'),
		email: Yup.string().email('Correo invalido').max(320, 'Maximo 320 de caracteres').required('El campo email es obligatorio'),
		password: Yup.string()
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d]|[^ ]){6,50}$/, 'La contraseña debe tener al menos 6 caracteres, mayúsculas, minúsculas y números ')
			.required('La constraseña es requerida'),
		repeatPassword: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
			.required('Repetir contraseña es requerido'),
	})
	return (
		<>
			<SetForm>
				<Mutation
					mutation={NUEVO_USUARIO}
					onCompleted={() => {
						exito()
						setTimeout(() => {
							history.push('/login')
						}, 3000)
					}}
				>
					{(crearUsuario, { loading, error, data }) => (
						<Formik
							initialValues={{ cuit: '', email: '', password: '', repeatPassword: '' }}
							validationSchema={validate}
							onSubmit={(values) => {
								const { email, password, cuit } = values
								return crearUsuario({
									variables: { cuit, usuario: email, password: password },
								})
									.then((data) => {})
									.catch((e) => {
										console.log(e.message)
										if (e.message === 'GraphQL error: El usuario ya existe') {
											setErrorLogin('El usuario ya existe')
										}

										if (e.message === 'GraphQL error: El cliente ya tiene un usuario creado') {
											setErrorLogin('El cliente ya tiene un usuario creado')
										}
										if (e.message === "GraphQL error: Cannot read property 'usuario' of null") {
											setErrorLogin('El cuit no existe')
										}
									})
							}}
						>
							{({ errors, handleSubmit, handleChange, isSubmitting, touched }) => (
								<Form onSubmit={handleSubmit}>
									<Form.Group className="mb-3" controlId="formBasicCuit">
										<Form.Label>Cuit</Form.Label>
										<Form.Control onChange={handleChange} type="cuit" placeholder="Ingresar cuit" name="cuit" isInvalid={touched.cuit && errors.cuit} />
										<Form.Control.Feedback type="invalid">{touched.cuit && errors.cuit}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>Email</Form.Label>
										<Form.Control onChange={handleChange} type="email" placeholder="Registrar email" name="email" isInvalid={touched.email && errors.email} />
										<Form.Control.Feedback type="invalid">{touched.email && errors.email}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicPassword">
										<Form.Label>Contraseña</Form.Label>
										<Form.Control onChange={handleChange} type="password" placeholder="Ingresar contraseña" name="password" isInvalid={touched.password && errors.password} />
										<Form.Control.Feedback type="invalid">{touched.password && errors.password}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group className="mb-3" controlId="formBasicRepeatPassword">
										<Form.Label>Repetir Contraseña</Form.Label>
										<Form.Control onChange={handleChange} type="password" placeholder="Ingresar contraseña" name="repeatPassword" isInvalid={touched.repeatPassword && errors.repeatPassword} />
										<Form.Control.Feedback type="invalid">{touched.repeatPassword && errors.repeatPassword}</Form.Control.Feedback>
									</Form.Group>
									{errorLogin ? (
										<SetError>
											<p>{errorLogin}</p>
										</SetError>
									) : (
										''
									)}
									<Button variant="primary" type="submit" disabled={isSubmitting}>
										Registrar
									</Button>
									<Link to="/login">
										<p>&lt; Ya poseo usuario y contraseña </p>
									</Link>
								</Form>
							)}
						</Formik>
					)}
				</Mutation>
			</SetForm>
			<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	)
}
