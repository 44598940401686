import {
	Form,
	Row,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Col,
	ProgressBar,
} from 'react-bootstrap'
import { Formik } from 'formik'
import { Mutation, useMutation } from 'react-apollo'
import {
	FORMGENERAL,
	FORMGENERAL120,
	UPLOAD_FILE,
	FORMGENERAL111,
} from '../../../mutations'
import FormGeneralCampos from './FormGeneralCampos'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	& button {
		width: 150px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormGeneral = ({
	handleClose,
	id,
	idSuc,
	idServ,
	idOrden,
	nombreServ,
	refetch = () => {
		console.log('vino sin refetch')
	},
	es_120 = false,
	es_111 = false,
	idSubServicio = null,
	periodo = null,
	item = null,
}) => {
	const idCliente = id
	const idSucursal = idSuc
	const idServicio = idServ
	const [uploadFile] = useMutation(UPLOAD_FILE)

	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')
	const [conteo, setConteo] = useState(0)
	const [progreso, setProgreso] = useState(false)
	const handleFileChange = (valor) => {
		let archivosNombre = []
		let archivos = []
		for (let i = 0; i < valor.length; i++) {
			const extencion = valor[i].name.split('.').pop()
			const extraerNombreOriginal = valor[i].name
			var blob = valor[i].slice(0, valor[i].size, extencion)
			var newFile = new File([blob], uuidv4() + '.' + extencion, {
				type: valor[i].type,
			})
			archivosNombre.push({
				adjuntoNombreOriginal: extraerNombreOriginal,
				adjunto: newFile.name,
			})
			archivos.push({ adjunto: newFile })
		}
		setNombreOriginal(archivosNombre)
		setAdjunto(archivos)
	}
	let consulta = FORMGENERAL
	if (es_120 !== null && es_120 !== false) {
		consulta = FORMGENERAL120
	}
	if (es_111 !== null && es_111 !== false) {
		consulta = FORMGENERAL111
	}
	let titleFechaMedicion = 'Fecha de medición'
	if (item === 'A') {
		titleFechaMedicion = 'Fecha de cronograma'
	}
	if (item === 'H') {
		titleFechaMedicion = 'Fecha de capacitación'
	}
	if (item === 'K') {
		titleFechaMedicion = 'Fecha de visita'
	}
	if (item === 'O') {
		titleFechaMedicion = 'Fecha de procedimientos de seguridad'
	}

	if (item === 'L') {
		titleFechaMedicion = 'Fecha de recomendaciones técnicas'
	}

	if (item === 'M') {
		titleFechaMedicion = 'Fecha de exámenes periódicos'
	}

	if (item === 'N') {
		titleFechaMedicion = 'Fecha de investigación de accidentes/incidentes'
	}
	const date = new Date()
	const dateNew = date.toISOString().split('T')[0]
	const timeNew = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
		.toISOString()
		.split('T')[1]
		.split('.')[0]
		.slice(0, -3)

	return (
		<Modal show="true" centered size="xl" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{nombreServ}</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={consulta}
				onCompleted={async (e) => {
					let estado = ''
					if (es_120) estado = e.crearFormGeneral120.status
					if (es_111) estado = e.crearFormGeneral111.status
					if (!es_111 && !es_120) estado = e.crearFormGeneral.status

					if (estado === true) {
						setProgreso(true)
						let actual = 0
						adjunto.map(async (e, index) => {
							try {
								await uploadFile({
									variables: { file: e.adjunto, folder: 'documents' },
								}).then(() => {})
								actual = actual + 1
								setConteo(actual)
								if (actual === adjunto.length) {
									setConteo(0)
									setProgreso(false)
									refetch()
									handleClose()
								}
								return 'ok'
							} catch (error) {
								if (error) {
									return 'no'
								}
							}
						})
					} else {
						alert('Error al cargar Formulario')
					}
				}}
			>
				{(crearFormGeneral) => (
					<Formik
						initialValues={{
							fechaMedicion: dateNew,
							horaInicio: timeNew,
							horaFin: timeNew,
						}}
						onSubmit={(values) => {
							const { fechaMedicion, horaInicio, horaFin } = values
							let input
							if (es_111) {
								input = {
									idCliente,
									idSucursal,
									idServicio,
									idOrden,
									idSubServicio,
									fechaMedicion,
									horaInicio,
									horaFin,
									periodo: String(periodo),
									archivos: nombreOriginal,
									createdBy: localStorage.getItem('idAux'),
								}
							} else {
								input = {
									idCliente,
									idSucursal,
									idServicio,
									idOrden,
									fechaMedicion,
									horaInicio,
									horaFin,
									archivos: nombreOriginal,
									createdBy: localStorage.getItem('idAux'),
								}
							}
							return crearFormGeneral({
								variables: { input },
							})
						}}
					>
						{({
							errors,
							handleSubmit,
							handleChange,
							isSubmitting,
							touched,
						}) => (
							<Form
								onSubmit={handleSubmit}
								action="/upload"
								encType="multipart/form-data"
								method="POST"
							>
								<ModalBody>
									<FormGeneralCampos
										handleChange={handleChange}
										handleFileChange={handleFileChange}
									/>
									<Row>
										<Form.Group
											className="mb-3"
											as={Col}
											controlId={`fechaMedicion`}
										>
											<Form.Label>
												<b>{titleFechaMedicion}</b>
											</Form.Label>
											<Form.Control
												name="fechaMedicion"
												type="date"
												onChange={handleChange}
												defaultValue={dateNew}
											/>
										</Form.Group>
										<Form.Group
											className="mb-3"
											as={Col}
											controlId={`horaInicio`}
										>
											<Form.Label>
												<b>Hora de inicio</b>
											</Form.Label>
											<Form.Control
												name="horaInicio"
												type="time"
												onChange={handleChange}
												defaultValue={timeNew}
											/>
										</Form.Group>
										<Form.Group className="mb-3" as={Col} controlId={`horaFin`}>
											<Form.Label>
												<b>Hora de fin</b>
											</Form.Label>
											<Form.Control
												name="horaFin"
												type="time"
												onChange={handleChange}
												defaultValue={timeNew}
											/>
										</Form.Group>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button
												variant="success"
												disabled={isSubmitting}
												type="submit"
												className="enviar"
											>
												Guardar
											</Button>
										</FooterButton>
									</Row>
									{progreso && (
										<Row style={{ width: '100%', marginTop: '20px' }}>
											<ProgressBar
												variant="success"
												animated
												now={(conteo / adjunto.length) * 100}
												style={{ padding: '0' }}
											/>
										</Row>
									)}
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormGeneral
