import React from 'react'
import ReactDOM from 'react-dom'
import { RootSession } from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import ApolloClient, { InMemoryCache } from 'apollo-boost-upload'
import { ApolloProvider } from 'react-apollo'
const client = new ApolloClient({
	uri: process.env.NODE_ENV === 'development' ? 'http://localhost:5500/graphql' : 'https://lenge-heroku-22.herokuapp.com/graphql',
	// uri: process.env.NODE_ENV === 'development' ? 'http://3.143.207.21:3000/graphql' : 'https://lenge.onrender.com/graphql',
	// uri: process.env.NODE_ENV === 'development' ? 'http://localhost:5500/graphql' : 'http://3.143.207.21:3000/graphql',
	fetchOptions: {
		credentials: 'include',
	},
	request: (operation) => {
		const token = localStorage.getItem('token')
		operation.setContext({
			headers: {
				authorization: token,
				'Access-Control-Allow-Origin': true,
			},
		})
	},
	cache: new InMemoryCache({
		addTypename: false,
	}),
	onError: ({ networkError, graphQLErrors }) => {
		if (process.env.NODE_ENV === 'development') {
			console.error('Error GraphQL', graphQLErrors)
			console.error('Error de Conexión', networkError)
			alert('No se pudo conectar con el servidor')
		}
	},
})
ReactDOM.render(
	<ApolloProvider client={client}>
		<RootSession />
	</ApolloProvider>,
	document.getElementById('root')
)
