import Badge from 'react-bootstrap/Badge'
import { UlNotification } from './Styles'
import { GET_NOTIFICACIONES } from '../../queries'
import { Query, useMutation } from 'react-apollo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { ELIMINAR_NOTIFICACION } from '../../mutations'
const List = () => {
	const [eliminar] = useMutation(ELIMINAR_NOTIFICACION)
	return (
		<Query
			query={GET_NOTIFICACIONES}
			variables={{
				usuario: localStorage.getItem('idAux'),
			}}
			fetchPolicy={'network-only'}
		>
			{({ loading, error, data, refetch }) => {
				if (loading) return 'Cargando...'
				if (error) return console.log(error.message)
				return (
					<>
						<UlNotification style={{}}>
							{data.obtenerNotificaciones.notificaciones.length === 0 && <li style={{ justifyContent: 'center' }}>No hay notificaciones</li>}
							{data.obtenerNotificaciones.notificaciones.map((notificacion, index) => {
								let separador = '/'
								if (notificacion.createdAt.includes('-')) {
									separador = '-'
								}

								let badgeType = 'success'
								if (notificacion.tipo === 'Alta') {
									badgeType = 'info'
								}
								if (notificacion.tipo === 'Anulado') {
									badgeType = 'warning'
								}
								if (notificacion.tipo === 'Eliminado') {
									badgeType = 'danger'
								}

								return (
									<li key={index}>
										<div>
											{notificacion.vista === true && (
												<FontAwesomeIcon
													icon={faEnvelopeOpen}
													style={{ cursor: 'pointer', color: '#28A745' }}
													title="Eliminar notificación"
													onClick={() => {
														eliminar({ variables: { id: notificacion.id } }).then(() => {
															refetch()
														})
													}}
												/>
											)}
											<span>
												{notificacion.texto} -{' '}
												{notificacion.createdAt.split(separador)[1] +
													'/' +
													notificacion.createdAt.split(separador)[0] +
													'/' +
													notificacion.createdAt.split(separador)[2]}
											</span>
										</div>
										<Badge bg={badgeType}>{notificacion.tipo}</Badge>
									</li>
								)
							})}
						</UlNotification>
					</>
				)
			}}
		</Query>
	)
}

export default List
