import { SERVICIO_PRESTADO } from '../../queries'
import { Mutation, Query } from 'react-apollo'
import { Card, Button, Form, ModalBody, Row, ModalFooter, Modal } from 'react-bootstrap'
import Loading from '../Loading'
import { useState } from 'react'
import DetalleOrden from './DetalleOrden'
import { ACTUALIZAR_ADJUNTO_OT } from '../../mutations'
import FormVisado from '../Formularios/FormVisado/FormVisado'
import Formularios from './Formularios'
import ObservacionOT from './ObservacionOT'
import editBtn from './../../img/edit.svg'
import EditarOT from './EditarOT'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { permisosById } from '../Helpers/Permisos'
import { v4 as uuidv4 } from 'uuid'
import { Formik } from 'formik'
import { mostrarAdjuntos } from '../Helpers/MostrarAdjunto'
import { mostrarTabla } from '../Helpers/MostrarTabla'
import { DetalleStyleDiv, FooterButton } from '../Style/Style'

const DetalleOrdenDeTrabajo = ({ idOrden, idSucursal, idCliente, permiso, handleRefreshOT }) => {
	const id = idOrden
	const [showForm, setShowForm] = useState(false)
	const [showListar, setShowListar] = useState(false)
	const [idServ, setIdServ] = useState('')
	const [showFormVisado, setShowFormVisado] = useState(false)
	const [itemServicio, setItemServicio] = useState('')
	const [nombreServicio, setNombreServicio] = useState('')
	const [idForm, setIdForm] = useState('')
	const [showEditOT, setShowEditOT] = useState(false)
	const [adjunto, setAdjunto] = useState('')
	const [nombreOriginal, setNombreOriginal] = useState('')
	const [showAddAdjunto, setShowAddAdjunto] = useState(false)
	const handleFileChange = (valor) => {
		const extencion = valor.name.split('.').pop()
		const extraerNombreOriginal = valor.name
		var blob = valor.slice(0, valor.size, extencion)
		var newFile = new File([blob], uuidv4() + '.' + extencion, {
			type: valor.type,
		})
		setNombreOriginal(extraerNombreOriginal)
		setAdjunto(newFile)
	}
	const handleShowForm = () => {
		setShowForm(true)
	}
	const handleClose = () => {
		setIdServ('')
		setShowForm(false)
	}
	const handleShowListar = () => {
		setShowListar(true)
	}
	const handleCloseListar = () => {
		setShowListar(false)
	}
	const handleCloseFormVisado = () => {
		setShowFormVisado(false)
	}
	const handleShowEditOT = () => {
		setShowEditOT(true)
	}
	const handleShowEditAdjunto = () => {
		setShowAddAdjunto(true)
	}
	const handleCloseAddAdjunto = () => {
		setShowAddAdjunto(false)
	}

	const handleCloseEdit = () => {
		window.location.reload()
		setShowEditOT(false)
	}

	return (
		<Query query={SERVICIO_PRESTADO} variables={{ id }} fetchPolicy={'network-only'}>
			{({ loading, error, data, refetch }) => {
				if (loading) return <Loading width={'50%'} />
				if (error) return `Error: ${error.message}`
				const servicio = data.getServicioPrestado.servicios
				const idOrden = data.getServicioPrestado.id
				const color = data.getServicioPrestado.estado === 'COMPLETADO' ? '#28A745' : ''
				return (
					<>
						<DetalleStyleDiv>
							<Card style={{ width: '100%' }}>
								<Card.Header className="header">
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<span>N° de Orden: {data.getServicioPrestado.nOrden} </span>
										{permisosById(localStorage.getItem('idAux')) && (
											<button style={{ marginLeft: '10px' }}>
												<img
													src={editBtn}
													alt=""
													onClick={() => {
														handleShowEditOT()
													}}
												/>
											</button>
										)}
									</div>
									<span>
										Estado:{' '}
										<span className="estado" style={{ backgroundColor: color, borderRadius: '4px' }}>
											{data.getServicioPrestado.estado}
										</span>
									</span>
								</Card.Header>
								<Card.Body>
									<table>
										<tbody className="body_detalle">
											{mostrarTabla(servicio, id, refetch, permiso, handleShowForm, setItemServicio, setNombreServicio, setShowFormVisado, handleShowListar, setIdForm, setIdServ)}
										</tbody>
									</table>
								</Card.Body>
							</Card>
							{permiso !== 5 && (
								<div>
									<ObservacionOT observacion={data.getServicioPrestado.observacion} id={id} permiso={permiso} />
									<>
										<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
											<p style={{ margin: '0px', marginRight: '20px' }}>
												<b style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Archivo adjunto de la Órden de trabajo</b>
											</p>
											{permiso < 5 && (
												<>
													<Button style={{ fontSize: '13px', padding: '3px' }} onClick={handleShowEditAdjunto}>
														Agregar
														<FontAwesomeIcon icon={faFilePdf} style={{ marginLeft: '10px' }} />
													</Button>
													<br />
												</>
											)}
										</div>
										<br />
										{mostrarAdjuntos(data.getServicioPrestado.adjunto, refetch, idOrden, permiso)}
									</>
								</div>
							)}
						</DetalleStyleDiv>
						{showForm && (
							<Formularios
								itemServicio={itemServicio}
								handleClose={handleClose}
								idOrden={idOrden}
								idSucursal={idSucursal}
								idCliente={idCliente}
								idServ={idServ}
								nombre={nombreServicio}
								refetch={refetch}
							/>
						)}
						{showListar && <DetalleOrden id={idForm} item={itemServicio} handleCloseListar={handleCloseListar} nombre={nombreServicio} permiso={permiso} />}
						{showFormVisado && <FormVisado idServ={idServ} idOrden={idOrden} handleCloseFormVisado={handleCloseFormVisado} refetch={refetch} />}
						{showEditOT && <EditarOT data={data.getServicioPrestado} handleCloseEdit={handleCloseEdit} refetch={refetch} />}
						{showAddAdjunto && (
							<Modal show="true" centered size="lg" onHide={handleCloseAddAdjunto}>
								<Modal.Header closeButton>
									<Modal.Title>Agregar archivo adjunto</Modal.Title>
								</Modal.Header>
								<Mutation
									mutation={ACTUALIZAR_ADJUNTO_OT}
									onCompleted={(e) => {
										if (e.actualizarAdjuntoServicioPrestado === 'ok') {
											refetch()
											handleCloseAddAdjunto()
										} else {
											alert('ocurrio un error')
										}
									}}
								>
									{(crearServiciosPrestados) => (
										<div>
											<Formik
												initialValues={{
													adjuntoNombreOriginal: '',
													adjunto: '',
												}}
												onSubmit={(values) => {
													return crearServiciosPrestados({
														variables: {
															id: idOrden,
															adjunto: adjunto.name,
															adjuntoNombreOriginal: nombreOriginal,
															file: adjunto,
															folder: 'adjuntosot',
														},
													})
												}}
											>
												{({ handleSubmit, isSubmitting }) => (
													<Form onSubmit={handleSubmit}>
														<ModalBody>
															<Row>
																<Form.Group controlId="formFile" className="mb-3">
																	<Form.Label>Adjuntar Archivo</Form.Label>
																	<Form.Control type="file" name="adjunto" onChange={(e) => handleFileChange(e.target.files[0])} />
																</Form.Group>
															</Row>
														</ModalBody>
														<ModalFooter>
															<Row>
																<FooterButton>
																	<Button variant="secondary" onClick={handleCloseAddAdjunto}>
																		Cancelar
																	</Button>
																	<Button variant="success" disabled={isSubmitting} type="submit" className="enviar">
																		Guardar
																	</Button>
																</FooterButton>
															</Row>
														</ModalFooter>
													</Form>
												)}
											</Formik>
										</div>
									)}
								</Mutation>
							</Modal>
						)}
					</>
				)
			}}
		</Query>
	)
}

export default DetalleOrdenDeTrabajo
