import { Redirect, useParams } from 'react-router-dom'
import { USUARIO } from '../../queries'
import { Query } from 'react-apollo'
import FormConfirmar from './FormConfirmar'
import Loading from '../Loading'
import logo from './../../img/logo_el.svg'
import estudioLenge from './../../img/log_lenge_new.png'
import styled from 'styled-components'
const LoginPage = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	flex-direction: column;
	.estudio {
		width: 40%;
		/* border-bottom: 2px solid #000; */
	}
`

const ConfirmarAlta = ({ obtenerUsuario }) => {
	let { token, id, usuario } = useParams()

	return (
		<>
			{!obtenerUsuario ? '' : <Redirect to="/clientes" />}
			<Query query={USUARIO} variables={{ id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return <p>Cargando</p>
					if (!(data.obtenerUsuario.token === token && data.obtenerUsuario.id === id && data.obtenerUsuario.usuario === usuario)) {
						return <Redirect to="/login" />
					}
					if (loading) return <Loading />
					if (error) return `Error: ${error.message}`
					return (
						<LoginPage>
							<img src={estudioLenge} alt="" className="estudio" />
							<FormConfirmar cliente={data.obtenerUsuario} token={token} usuario={usuario} />
						</LoginPage>
					)
				}}
			</Query>
		</>
	)
}

export default ConfirmarAlta
