import styled from 'styled-components'
import plus from './../../img/plus.svg'
import repeat from './../../img/repeat.svg'

const LateralDiv = styled.div`
	width: 80px;
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
	margin-top: 60px;
	flex-wrap: wrap;
	position: fixed;
	top: 100px;
	right: 0;
	button {
		border: none;
		background: none;
		background: #28a745;
		border-radius: 99999px;
		/* padding: 20px; */
		width: 50px;
		height: 50px;
		-webkit-box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
		-moz-box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
		box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
		margin-bottom: 10px;
	}
`

const Lateral = ({ setShowAdd, setShowReasignar }) => {
	return (
		<LateralDiv>
			<button onClick={() => setShowAdd(true)}>
				<img src={plus} alt="" />
			</button>
			<button onClick={() => setShowReasignar(true)} style={{ display: 'none' }}>
				<img src={repeat} alt="" />
			</button>
		</LateralDiv>
	)
}

export default Lateral
