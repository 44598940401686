import { USUARIO_NOMBRE } from '../../queries'
import { Query } from 'react-apollo'
const UsuarioNombre = ({ id }) => {
	return id.map((e, index) => (
		<span key={index}>
			<Query query={USUARIO_NOMBRE} variables={{ id: e.id }} fetchPolicy={'network-only'}>
				{({ loading, error, data }) => {
					if (loading) return false
					if (error) return `Error: ${error.message}`
					return (
						<span key={index}>
							{index === 0 ? '' : ', '}
							{data.obtenerUsuario?.nombre}
						</span>
					)
				}}
			</Query>
		</span>
	))
}

export default UsuarioNombre
