import MenuLateral from './components/Menus/MenuLateral'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Login } from './components/Login/Login'
import ConfirmarAlta from './components/Login/ConfirmarAlta'
import Clientes from './components/Clientes/Clientes'
import Servicios from './components/Servicios/Servicios'
import Sucursales from './components/Sucursales/Sucursales'
import Sucursal from './components/Sucursal/Sucursal'
import Instrumental from './components/Instrumental/Instrumental'
import { Registro } from './components/Login/Registro'
import { Recuperar } from './components/Login/Recuperar'
import Session from './components/Session'
import styled from 'styled-components'
import Usuarios from './components/Usuarios/Usuarios'
import AsignarAuxiliar from './components/AsignarAuxiliar/AsignarAuxiliar'
import { UploadForm } from './components/UploadForm'
import Legajo from './components/Legajo/Legajo'
import Error404 from './components/Error404/Error404'
import { permisos, permisosOrdenesDeTrabajo, permisosNoti as permisoNotis } from './components/Helpers/Permisos'
import Home from './components/Home/Home'
const Theme = styled.div`
	font-family: 'Poppins', sans-serif;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 20%;
	height: 90vh;
	font-size: 14px;
	tr:hover {
		background-color: #c2c2c250;
	}

	*::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
		background-color: #ffffff;
	}

	*::-webkit-scrollbar {
		width: 3px;
		background-color: #ffffff;
	}

	*::-webkit-scrollbar-thumb {
		background-color: #28a745;
	}
`
const App = ({ refetch, session }) => {
	const { obtenerUsuarioActual } = session
	let permiso
	let permisoOrdenesDeTrabajo
	let permisosNoti
	if (obtenerUsuarioActual) {
		permiso = permisos(obtenerUsuarioActual)
		permisoOrdenesDeTrabajo = permisosOrdenesDeTrabajo(obtenerUsuarioActual)
		permisosNoti = permisoNotis(obtenerUsuarioActual)
	}

	return (
		<Theme>
			<Router>
				{obtenerUsuarioActual ? (
					<div>
						<MenuLateral
							usuario={obtenerUsuarioActual ? obtenerUsuarioActual.usuario : 'Usuario'}
							permiso={permiso}
							rolText={obtenerUsuarioActual.rol}
						/>
					</div>
				) : (
					''
				)}
				<Switch>
					<Route exact path="/subir" component={UploadForm} />
					<Route exact path="/confirmar_alta/:token/:id/:usuario" render={() => <ConfirmarAlta obtenerUsuario={obtenerUsuarioActual} />} />
					<Route
						exact
						path="/confirmar_recuperar_password/:token/:id/:usuario"
						render={() => <ConfirmarAlta obtenerUsuario={obtenerUsuarioActual} />}
					/>
					<Route exact path="/recuperar_password" render={() => <Recuperar obtenerUsuario={obtenerUsuarioActual} />} />
					<Route exact path="/registro" render={() => <Registro obtenerUsuario={obtenerUsuarioActual} />} />
					<Route exact path="/login" render={() => <Login refetch={refetch} obtenerUsuario={obtenerUsuarioActual} />} />
					<Route exact path="/" render={() => <Login refetch={refetch} obtenerUsuario={obtenerUsuarioActual} />} />
					<Route
						exact
						path="/home"
						render={() => {
							return (
								<Home
									obtenerUsuario={obtenerUsuarioActual}
									permiso={permiso}
									permisoOrdenesDeTrabajo={permisoOrdenesDeTrabajo}
									email={obtenerUsuarioActual?.usuario}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/clientes"
						render={() => {
							return <Clientes obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/locaciones"
						render={() => {
							return <Clientes obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/servicios"
						render={() => {
							return <Servicios obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
						title="Estudio Lenge - Servicios"
					/>
					<Route
						exact
						path="/usuarios"
						render={() => {
							return <Usuarios obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/servicios_hyst"
						render={() => {
							return <AsignarAuxiliar obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/administradores"
						render={() => {
							return <Usuarios obtenerUsuario={obtenerUsuarioActual} esAdmin={true} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/sucursales"
						render={() => {
							return <Sucursales obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
						title="Estudio Lenge - Sucursales"
					/>
					<Route
						exact
						path="/sucursal"
						render={() => {
							return (
								<Sucursal
									obtenerUsuario={obtenerUsuarioActual}
									permiso={permiso}
									permisosOrdenesDeTrabajo={permisoOrdenesDeTrabajo}
									permisosNoti={permisosNoti}
									email={obtenerUsuarioActual?.usuario}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/instrumental_de_medicion"
						render={() => {
							return <Instrumental obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/ordenes_de_trabajo"
						render={() => {
							return (
								<Sucursal
									obtenerUsuario={obtenerUsuarioActual}
									general={1}
									permisosOrdenesDeTrabajo={permisoOrdenesDeTrabajo}
									permiso={permiso}
									permisosNoti={permisosNoti}
									email={obtenerUsuarioActual?.usuario}
								/>
							)
						}}
					/>
					<Route
						exact
						path="/legajo/:area/:id/:sucursal"
						render={() => {
							return <Legajo obtenerUsuario={obtenerUsuarioActual} permiso={permiso} />
						}}
					/>
					<Route
						exact
						path="/*"
						render={() => {
							return <Error404 />
						}}
					/>
				</Switch>
			</Router>
		</Theme>
	)
}

const RootSession = Session(App)
export { RootSession }
