import styled from 'styled-components'
import { Link } from 'react-router-dom'
import DatosCliente from './DatosCliente'
import { Button, Card } from 'react-bootstrap'

const CardStyleDiv = styled.div`
	.header_card {
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			border-radius: 0;
		}
	}
`

const DatosSucursal = ({ idCliente, idSucursal, obtenerUsuario, permiso }) => {
	return (
		<CardStyleDiv>
			<Card style={{ width: '100%' }}>
				<Card.Header className="header_card">
					<span>Datos del Cliente</span>
					{permiso === 1 && (
						<Link
							to={{
								pathname: '/sucursales',
								state: { obtenerUsuario, agregar: 1, id: idCliente },
							}}
						>
							<Button variant="secondary">Agregar Sucursal</Button>
						</Link>
					)}
				</Card.Header>

				<Card.Body>
					<DatosCliente id={idCliente} idSuc={idSucursal} />
				</Card.Body>
			</Card>
		</CardStyleDiv>
	)
}

export default DatosSucursal
