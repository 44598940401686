import { Mutation, useMutation, useQuery } from 'react-apollo'
import { CLIENTES } from '../../queries'
import { ACTUALIZAR_DEBE, ELIMINAR_CLIENTE } from '../../mutations'
import { useEffect, useState } from 'react'

import editBtn from './../../img/edit.svg'
import deleteBtn from './../../img/delete.svg'

import AltaCliente from './AltaCliente'
import EditarCliente from './EditarCliente'
import EliminarCliente from './EliminarCliente'
import Paginador from '../Paginador'

import Lateral from '../Lateral/Lateral'
import { ToastContainer, toast } from 'react-toastify'
import { Redirect } from 'react-router'
import { NavLink } from 'react-router-dom'
import Menu from '../Menus/Menu'
import CheckBox from '../Helpers/CheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons'
import { ClientesStyleDiv } from '../Style/Style'
import Loading from '../Loading'

const Clientes = ({ obtenerUsuario, permiso }) => {
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [info, setInfo] = useState({})
	const [offset, setOffset] = useState(0)
	const [actual, setActual] = useState(1)
	const [showAdd, setShowAdd] = useState(false)
	const [noti, setNoti] = useState(false)
	const [notiEdit, setNotiEdit] = useState(false)
	const [notiDelete, setNotiDelete] = useState(false)
	const [search, setSearch] = useState(null)
	const email = permiso === 5 ? obtenerUsuario.usuario : ''
	const [actualizarDebe, { error }] = useMutation(ACTUALIZAR_DEBE)
	const [orderBy, setOrderBy] = useState('razonSocial')
	const [asc, setAsc] = useState(1)
	const limite = 10
	const paginaAnterior = () => {
		setOffset(offset - limite)
		setActual(actual - 1)
	}
	const paginaSiguiente = () => {
		setOffset(offset + limite)
		setActual(actual + 1)
	}
	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}
	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}
	const handleCloseAdd = () => {
		setShowAdd(false)
	}
	const handleConfirm = (eliminarCliente) => {
		eliminarCliente({ variables: { id } })
	}

	useEffect(() => {
		if (error) {
			console.log(error)
		}
	}, [error])

	const handleActualizarDebe = (id, valor) => {
		actualizarDebe({
			variables: {
				id,
				debe: valor,
			},
		}).then(() => {
			alert('Cliente actualizado con éxito')
		})
	}

	const handleNoti = (valor = false) => {
		if (valor === 1) {
			setNoti(true)
		}
		if (valor === 2) {
			setNotiEdit(true)
		}
		if (valor === 3) {
			setNotiDelete(true)
		}
	}
	const handleSearch = (e) => {
		setOffset(0)
		setActual(1)
		setSearch(e.target.value)
	}
	const title = permiso === 5 ? 'Locaciones' : 'Clientes'

	const exito = (valor) => {
		let text = ''
		if (valor === 1) {
			text = 'Cliente dado de alta con éxito'
			setNoti(false)
		}
		if (valor === 2) {
			text = 'Cliente editado con éxito'
			setNotiEdit(false)
		}
		if (valor === 3) {
			text = 'Cliente eliminado con éxito'
			setNotiDelete(false)
		}
		toast.success(text, {
			position: 'bottom-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		})
	}

	const handleOrderBy = (order, asc) => {
		if (orderBy === order && asc === 1) {
			setAsc(-1)
		} else {
			setAsc(1)
		}
		setOrderBy(order)
	}

	const { loading, data, refetch } = useQuery(CLIENTES, { variables: { search, limite, offset, email, orderBy, asc } })
	return (
		<>
			{permiso === 6 && <Redirect to="/login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo={title} handleSearch={handleSearch} />
			{noti && exito(1)}
			{notiEdit && exito(2)}
			{notiDelete && exito(3)}
			{loading && <Loading />}
			<>
				{/* <Permisos /> */}
				<ClientesStyleDiv>
					<div className="antes_tabla"></div>
					{showEdit && <EditarCliente handleClose={handleCloseEdit} id={id} refetch={refetch} handleNoti={handleNoti} />}
					{showAdd && <AltaCliente handleClose={handleCloseAdd} refetch={refetch} handleNoti={handleNoti} />}
					<div className="tabla">
						{showDelete && <EliminarCliente info={info} handleCloseDelete={handleCloseDelete} handleConfirm={handleConfirm} />}
						<table>
							<thead>
								<tr>
									<th onClick={() => handleOrderBy('cuit', asc)} className="orderBy">
										{orderBy === 'cuit' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'cuit' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Cuit
									</th>
									<th onClick={() => handleOrderBy('razonSocial', asc)} className="orderBy">
										{orderBy === 'razonSocial' && asc === 1 && <FontAwesomeIcon icon={faSortAmountDown} style={{ marginRight: '10px' }} />}
										{orderBy === 'razonSocial' && asc === -1 && <FontAwesomeIcon icon={faSortAmountUp} style={{ marginRight: '10px' }} />}
										Razón Social
									</th>
									<th>Sucursales</th>
									{permiso === 1 && <th>Debe</th>}
									{permiso === 1 && <th>Editar</th>}
									{permiso === 1 && <th>Eliminar</th>}
								</tr>
							</thead>
							<tbody>
								{!loading && data.getClientes.total === 0 && (
									<tr>
										<td colSpan={5}>No se encontraron resultados</td>
									</tr>
								)}
								{!loading &&
									data.getClientes.clientes.map((item) => {
										const { id, razonSocial, cuit, debe } = item
										return (
											<tr key={id}>
												<td>{cuit ? cuit : 'Sin definir'}</td>
												<td>{razonSocial}</td>
												<td className="centrado ver">
													<NavLink
														to={{
															pathname: '/sucursales',
															state: { id: id },
														}}
													>
														<button>Ver</button>
													</NavLink>
												</td>
												{permiso === 1 && (
													<td>
														<CheckBox estado={debe} id={id} handleActualizarDebe={handleActualizarDebe} />
													</td>
												)}
												{permiso === 1 && (
													<td className="centrado edit">
														<button>
															<img
																src={editBtn}
																alt=""
																onClick={() => {
																	setId(id)
																	setShowEdit(true)
																}}
															/>
														</button>
													</td>
												)}
												{permiso === 1 && (
													<td className="centrado delete">
														<Mutation
															mutation={ELIMINAR_CLIENTE}
															onCompleted={() => {
																handleCloseDelete()
																refetch()
																handleNoti(3)
															}}
														>
															{(eliminarCliente) => (
																<button
																	onClick={() => {
																		setShowDelete(true)
																		setId(id)
																		setInfo({ razonSocial, eliminarCliente })
																	}}
																>
																	<img src={deleteBtn} alt="" />
																</button>
															)}
														</Mutation>
													</td>
												)}
											</tr>
										)
									})}
							</tbody>
						</table>
						{!loading && data.getClientes.total > 0 && <Paginador actual={actual} total={data.getClientes.total} limite={limite} paginaAnterior={paginaAnterior} paginaSiguiente={paginaSiguiente} />}
					</div>
				</ClientesStyleDiv>
			</>

			{permiso === 1 && <Lateral setShowAdd={setShowAdd} />}
			<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
		</>
	)
}

export default Clientes
