import { Formik } from 'formik'
import { useState } from 'react'
import { Mutation } from 'react-apollo'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { ACTUALIZAR_OBSERVACION_OT } from '../../mutations'
import { permisosById } from '../Helpers/Permisos'

const ObservacionOT = ({ id, observacion, permiso }) => {
	const [showAdd, setShowAdd] = useState(false)
	const [stateObs, setStateObs] = useState(observacion)
	return (
		<Mutation
			mutation={ACTUALIZAR_OBSERVACION_OT}
			onCompleted={() => {
				setShowAdd(false)
			}}
		>
			{(actualizarObservacionServicioPrestado) => (
				<div>
					<Formik
						initialValues={{
							id,
							observacion,
						}}
						onSubmit={(values) => {
							const { id } = values
							return actualizarObservacionServicioPrestado({ variables: { id, observacion: stateObs } })
						}}
					>
						{({ errors, handleSubmit, isSubmitting, touched, handleChange }) => (
							<Form onSubmit={handleSubmit}>
								<Row>
									<Form.Group className="mb-3 mt-3" as={Col} controlId={`observacion`}>
										<Form.Label>Observaciones de la orden de trabajo</Form.Label>
										<Form.Control
											as="textarea"
											rows={3}
											isInvalid={touched.observacion && errors.observacion}
											disabled={permiso < 5 ? false : true}
											onChange={async (e) => {
												if (e.target.value !== observacion) {
													setStateObs(e.target.value)
													setShowAdd(true)
												} else {
													setShowAdd(false)
												}
												await handleChange
											}}
											defaultValue={observacion ? observacion : ''}
											onFocus={() => {}}
										/>
									</Form.Group>
								</Row>
								{showAdd && (
									<Row>
										<Col md={6}>
											<Button variant="success" disabled={isSubmitting} type="submit" className="enviar ">
												Actualizar observación
											</Button>
										</Col>
									</Row>
								)}
							</Form>
						)}
					</Formik>
				</div>
			)}
		</Mutation>
	)
}

export default ObservacionOT
