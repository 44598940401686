import { Col, Form } from 'react-bootstrap'
import Notification from '../Notification/Notification'
import styled from 'styled-components'
const bgMenu = '#007BFF'

const DivNav = styled.div`
	background: ${bgMenu};
	position: fixed;
	top: 0;
	left: 0;
	height: 70px;
	width: 100%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	color: #fff;
	h2 {
		font-size: 28px;
	}
	.buscador {
		position: absolute;
		right: 20px;
		top: 16px;
		display: flex;
		align-items: center;
		input {
			outline: none;
		}
	}
`
const Menu = ({ titulo, handleSearch }) => {
	return (
		<DivNav>
			<div>
				<h2>{titulo}</h2>
			</div>
			<div className="buscador">
				<Notification className="mb-3" />
				{titulo !== 'Inicio' && (
					<Form.Group className="mb-3" as={Col} controlId="buscador">
						<Form.Control type="search" name="buscador" onChange={(e) => handleSearch(e)} placeholder={`Buscar ${titulo.toLowerCase()}`} />
					</Form.Group>
				)}
			</div>
		</DivNav>
	)
}

export default Menu
