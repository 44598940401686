import ItemListar from './ItemListar'

const TablaServicios = ({ handleShow, idSucursal, area }) => {
	return (
		<>
			<table>
				<tbody>
					{area === 'hyst' && (
						<>
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="A" title="CRONOGRAMA ANUAL DE TRABAJO" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="B" title="RELEVAMIENTO GENERAL DE RIESGOS LABORALES RES. 463/09" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="C" title="RELEVAMIENTO DE AGENTES DE RIESGO" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="100,101" title="PROVISION DE AGUA POTABLE" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="103" title="CARGA TERMICA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="110" title="CONTAMINACION AMBIENTAL" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="D" title="RADIACIONES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="108" title="VENTILACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="104" title="ILUMINACION Y COLOR" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="106" title="RUIDOS Y VIBRACIONES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="105" title="INSTALACIONES ELECTRICAS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="E" title="MAQUINAS Y HERRAMIENTAS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="319" title="APARATOS QUE PUEDAN DESARROLLAR PRESION INTERNA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="F" title="TRABAJOS CON RIESGOS ESPECIALES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="102,113" title="PROTECCION CONTRA INCENDIOS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="G" title="EQUIPOS Y ELEMENTOS DE PROTECCION PERSONAL" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="H" title="CAPACITACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="109,138" title="ERGONOMIA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="I" title="HOJAS DE SEGURIDAD DE SUSTANCIAS PELIGROSAS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="J" title="PROTOCOLO COVID-19" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="K" title="VISITAS ART, MINISTERIO DE TRABAJO Y SRT" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="L" title="RECOMENDACIONES TECNICAS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="M" title="EXAMENES PERIODICOS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="N" title="ACCIDENTES / INCIDENTES INVESTIGACION E INFORMES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="O" title="PROCEDIMIENTOS DE SEGURIDAD" />
						</>
					)}
					{area === 'ma' && (
						<>
							<ItemListar
								handleShow={handleShow}
								idSucursal={idSucursal}
								item="337,338,368"
								title="CATEGORIZACION INDUSTRIAL - CLASIFICACION DE NIVEL DE COMPLEJIDAD AMBIENTAL"
							/>
							<ItemListar
								handleShow={handleShow}
								idSucursal={idSucursal}
								item="339,340,341,342,343"
								title="AUDITORIA AMBIENTAL - EVALUACION DE IMPACTO AMBIENTAL"
							/>
							<ItemListar
								handleShow={handleShow}
								idSucursal={idSucursal}
								item="315,316,310,355,309,311,312,357,313,362,361"
								title="EFLUENTES LIQUIDOS INDUSTRIALES"
							/>
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="304,305,306,353" title="EFLUENTES GASEOSOS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="300,301,345,302,303,354" title="RESIDUOS ESPECIALES/PELIGROSOS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="348" title="RESIDUOS INDUSTRIALES NO ESPECIALES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="381" title="RESIDUOS RECICLABLES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="999" title="RESIDUOS DE ARTEFACTOS ELECTRICOS Y ELECTRONICOS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="999" title="PASIVOS AMBIENTALES" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="359" title="SEGURO DE CAUCION AMBIENTAL DE INCIDENCIA COLECTIVA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="362" title="PERMISO DE EXPLOTACION DEL RECURSO HIDRICO SUBTERRANEO" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="999" title="MONITOREO DE RED FREATICA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="352" title="RUIDOS MOLESTOS AL VECINDARIO" />
							<ItemListar
								handleShow={handleShow}
								idSucursal={idSucursal}
								item="319,320,321,322,323,324,325,326,327,328,329,330"
								title="APARATOS SOMETIDOS A PRESION"
							/>
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="999" title="PLAN DE CONTINGENCIAS" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="999" title="REGISTRO DE CONTINGENCIAS AMBIENTALES" />
							<ItemListar
								handleShow={handleShow}
								idSucursal={idSucursal}
								item="331,332"
								title="INSPECCIONES ACUMAR – OPDS – ADA – INA – AYSA - MUNICIPALIDAD"
							/>
						</>
					)}
					{area === 'gm' && (
						<>
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="211,233" title="HABILITACION MUNICIPAL" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="200" title="CERTIFICADOS DE HABILITACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="205,206,207" title="TRANSFERENCIA DE HABILITACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="220" title="CERTIFICADO ANTISINIESTRAL" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="221" title="CROQUIS PARA HABILITACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="223" title="PLANO DE ELECTROMECANICA" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="225,227" title="FACTIBILIDAD DE RADICACION" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="230" title="PLANO DE INSTALACIONES CONTRA INCENDIO" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="238" title="CARTEL PUBLICITARIO" />
							<ItemListar handleShow={handleShow} idSucursal={idSucursal} item="218" title="DESCARGOS DE PARTE DE INSPECCION" />
						</>
					)}
				</tbody>
			</table>
		</>
	)
}

export default TablaServicios
