import { Form, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'react-bootstrap'
import { Mutation } from 'react-apollo'
import { REASIGNAR_OT } from '../../mutations'
import { Formik } from 'formik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { FooterButton } from '../Style/Style'
const ReasignarOT = ({ handleClose, data }) => {
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Reasignar OT</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={REASIGNAR_OT}
				onCompleted={(e) => {
					handleClose()
					// refetch()
					// handleNoti(2)
				}}
			>
				{(reasginarOT) => (
					<Formik
						initialValues={{
							idAuxiliarOld: '',
							idAuxiliarNew: '',
						}}
						onSubmit={(values) => {
							const input = {
								idAuxiliarOld: values.idAuxiliarOld,
								idAuxiliarNew: values.idAuxiliarNew,
							}
							if (!values.idAuxiliarOld || !values.idAuxiliarNew) {
								alert('Debe seleccionar un auxiliar')
								return
							}
							return reasginarOT({ variables: { input } })
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched, setFieldValue, setFieldTouched }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<Row>
										<Form.Group className="mb-3" as={Col} controlId="userOld">
											<Select
												onChange={(e) => {
													setFieldValue('idAuxiliarOld', e.id)
												}}
												onBlur={setFieldTouched}
												options={data.obtenerUsuarios.usuarios}
												isClearable
												isSearchable
												components={makeAnimated()}
												placeholder="Reasignar auxiliar"
												getOptionValue={(options) => options.id}
												getOptionLabel={(options) => {
													return options.nombre
												}}
												noOptionsMessage={() => 'No se encontraron Usuarios'}
											></Select>
										</Form.Group>
										<Form.Group className="mb-3" as={Col} controlId="userNew">
											<Select
												onChange={(e) => {
													setFieldValue('idAuxiliarNew', e.id)
												}}
												onBlur={setFieldTouched}
												options={data.obtenerUsuarios.usuarios}
												isClearable
												isSearchable
												components={makeAnimated()}
												placeholder="Reasignar auxiliar"
												getOptionValue={(options) => options.id}
												getOptionLabel={(options) => {
													return options.nombre
												}}
												noOptionsMessage={() => 'No se encontraron Usuarios'}
											></Select>
										</Form.Group>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit">
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default ReasignarOT
