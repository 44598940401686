import { Mutation } from 'react-apollo'
import { ELIMINAR_USUARIO } from '../../mutations'

import editBtn from './../../img/edit.svg'
import deleteBtn from './../../img/delete.svg'

const TableList = ({ data, handleCloseDelete, setId, setShowEdit, refetch, handleNoti, setShowDelete, setInfo }) => {
	if (data.obtenerUsuarios.total === 0) {
		return (
			<tr>
				<td colSpan={6}>No se encontraron resultados</td>
			</tr>
		)
	}
	return data.obtenerUsuarios.usuarios.map((item) => {
		const { id, usuario, nombre, rol, area } = item
		let rolText = ''
		let areaText = ''
		switch (rol) {
			case 'GERENTE':
				rolText = 'Gerente'
				break
			case 'ADMINISTRADOR':
				rolText = 'Administrador'
				break
			case 'RESPONSABLE':
				rolText = 'Responsable de Servicios'
				break
			case 'SUPERVISOR':
				rolText = 'Supervisor de Servicios'
				break
			case 'AUXILIAR':
				rolText = 'Auxiliar de Servicios'
				break
			case 'CALIDAD':
				rolText = 'Auditor interno de calidad'
				break
			case 'TEMPORAL':
				rolText = 'Temporal'
				break
			default:
				rolText = 'No definido'
				break
		}
		switch (area) {
			case 'HYST':
				areaText = 'Higiene y Seguridad en el Trabajo'
				break
			case 'GM':
				areaText = 'Gestoría Municipal'
				break
			case 'MA':
				areaText = 'Medio Ambiente'
				break
			case 'CLD':
				areaText = 'Calidad'
				break
			default:
				areaText = 'No definido'
				break
		}
		return (
			<tr key={id}>
				<td>{nombre}</td>
				<td>{usuario}</td>
				<td>{areaText}</td>
				<td>{rolText}</td>
				<td className="centrado edit">
					<button>
						<img
							src={editBtn}
							alt=""
							onClick={() => {
								setId(id)
								setShowEdit(true)
							}}
						/>
					</button>
				</td>
				<td className="centrado delete">
					<Mutation
						mutation={ELIMINAR_USUARIO}
						onCompleted={() => {
							handleCloseDelete()
							refetch()
							handleNoti(3)
						}}
					>
						{(eliminarUsuario) => (
							<button
								onClick={() => {
									setShowDelete(true)
									setId(id)
									setInfo({ usuario, eliminarUsuario })
								}}
							>
								<img src={deleteBtn} alt="" />
							</button>
						)}
					</Mutation>
				</td>
			</tr>
		)
	})
}

export default TableList
