import { Query } from 'react-apollo'
import { CLIENTE_Y_SUCURSAL } from '../../queries'
import { Card } from 'react-bootstrap'

import Loading from '../Loading'

const DatosCliente = ({ id, idSuc }) => {
	return (
		<Query
			query={CLIENTE_Y_SUCURSAL}
			variables={{ id, idSuc }}
			fetchPolicy={'network-only'}
		>
			{({ loading, error, data }) => {
				if (loading) return <Loading />
				if (error) return `Error: ${error.message}`
				const { cuit, razonSocial } = data.getCliente
				const { nombreEstablecimiento } = data.getSucursal
				return (
					<>
						<Card.Text>
							<b>Razon Social:</b> {razonSocial}
						</Card.Text>
						<Card.Text>
							<b>Sucursal:</b> {nombreEstablecimiento}
						</Card.Text>
						<Card.Text>
							<b>Cuit:</b> {cuit}
						</Card.Text>
					</>
				)
			}}
		</Query>
	)
}

export default DatosCliente
