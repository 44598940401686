import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import DetalleOrden111 from './DetalleOrden111'
const ContenedorPeriodo = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 10px 0;
	div {
		width: 25%;
		button {
			border: none;
			background: #007bff;
			color: #fff;
			padding: 5px;
		}
	}
`
const Listar111Periodo = ({ datos, item, handleShowGeneral, cliente, sucursal }) => {
	const { periodo, estado, cargado } = datos
	const [showListar, setShowListar] = useState(false)
	const [idForm, setIdForm] = useState('')
	const [itemServicio, setItemServicio] = useState('')
	const [nombreServicio, setNombreServicio] = useState('')
	const color = estado === 'COMPLETO' ? '#28A745' : ''
	const handleShowListar = () => {
		setShowListar(true)
	}

	const handleCloseListar = () => {
		setShowListar(false)
	}
	let label = ''
	switch (item) {
		case 'D':
		case 'E':
		case 'F':
		case 'G':
		case 'I':
		case 'J':
		case 'K':
		case 'M':
		case 'O':
			label = 'Demanda'
			break

		default:
			label = `Período ${periodo}`
			break
	}
	return (
		<>
			{showListar && (
				<DetalleOrden111
					id={idForm}
					item={itemServicio}
					handleCloseListar={handleCloseListar}
					nombre={nombreServicio}
					cliente={cliente}
					sucursal={sucursal}
				/>
			)}
			<ContenedorPeriodo>
				<div>{label}</div>
				<div>
					<button
						onClick={() => {
							handleShowGeneral(periodo)
						}}
					>
						CDS
					</button>
				</div>
				<div>
					<button style={{ backgroundColor: color }}>{estado}</button>
				</div>
				<div>
					{cargado && (
						<Button
							variant="primary"
							onClick={() => {
								handleShowListar()
								setIdForm(datos.cargado)
								setItemServicio(item)
								setNombreServicio(`Período ${datos.periodo}`)
							}}
						>
							Ver
						</Button>
					)}
				</div>
			</ContenedorPeriodo>
		</>
	)
}

export default Listar111Periodo
