import { Query, Mutation } from 'react-apollo'
import { SUCURSALES_CLIENTE } from '../../queries'
import { ELIMINAR_SUCURSAL } from '../../mutations'
import { useState } from 'react'

import editBtn from './../../img/edit.svg'
import deleteBtn from './../../img/delete.svg'

import AltaCliente from './AltaCliente'
import EditarSucursal from './Editar'
import EliminarSucursal from './Eliminar'

import Loading from '../Loading'
import styled from 'styled-components'
import Lateral from '../Lateral/Lateral'
import { Redirect } from 'react-router'

import { Link, useLocation } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import Menu from '../Menus/Menu'
import SinSucursal from './SinSucursal'

const SucursalesDiv = styled.div`
	width: 90%;
	height: calc(100vh - 80px);
	display: flex;
	margin-top: 80px;
	flex-wrap: wrap;
	margin-left: 6em;
	overflow: auto;
	padding-top: 20px;
	.card {
		width: 350px;
		margin-right: 20px;
		margin-bottom: 20px;
		height: 170px;
		.card-footer {
			display: flex;
			justify-content: space-between;
		}
	}
`
const Sucursales = ({ obtenerUsuario, permiso }) => {
	const location = useLocation()
	const id_cliente = location.state.id
	const agregar = location.state.agregar
	const [id, setId] = useState('')
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [info, setInfo] = useState({})

	const [showAdd, setShowAdd] = useState(false)

	const handleCloseEdit = () => {
		setShowEdit(false)
		setId('')
	}
	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}
	const handleCloseAdd = () => {
		setShowAdd(false)
	}

	const handleConfirm = (eliminarSucursal) => {
		eliminarSucursal({ variables: { id } })
	}

	return (
		<>
			{permiso === 6 && <Redirect to="/login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo="Sucursales" />
			<Query
				query={SUCURSALES_CLIENTE}
				variables={{
					id: id_cliente,
				}}
				fetchPolicy={'network-only'}
			>
				{({ loading, error, data, refetch }) => {
					if (loading) return <Loading />
					if (error) return console.log(error.message)
					if (data.getSucursalesClientes.length === 1 && agregar === undefined) {
						return (
							<Redirect
								to={{
									pathname: '/sucursal',
									state: {
										id: data.getSucursalesClientes[0].id,
										idCliente: data.getSucursalesClientes[0].idCliente,
									},
								}}
							/>
						)
					}
					return (
						<>
							{showEdit && <EditarSucursal handleClose={handleCloseEdit} id={id} refetch={refetch} />}
							{showAdd && <AltaCliente handleClose={handleCloseAdd} refetch={refetch} idCliente={id_cliente} />}
							<div className="tabla">
								{showDelete && <EliminarSucursal info={info} handleCloseDelete={handleCloseDelete} handleConfirm={handleConfirm} />}

								<SucursalesDiv>
									{data.getSucursalesClientes.length === 0 && <SinSucursal setShowAdd={setShowAdd} />}

									{data.getSucursalesClientes.map((item) => {
										const { id, nombreEstablecimiento, direccion, localidad, codigoPostal } = item
										return (
											<Card key={id}>
												<Card.Body>
													<Card.Title>{nombreEstablecimiento}</Card.Title>
													<Card.Subtitle className="mb-2 text-muted">{direccion}</Card.Subtitle>
													<Card.Text>
														{localidad} ({codigoPostal})
													</Card.Text>
												</Card.Body>
												<Card.Footer>
													<Link
														to={{
															pathname: '/sucursal',
															state: { id: id, idCliente: id_cliente },
														}}
													>
														<Button variant="primary">Seleccionar Sucursal</Button>
													</Link>
													<div className="otros">
														{permiso < 4 && (
															<>
																<Button
																	variant="secondary"
																	onClick={() => {
																		setId(id)
																		setShowEdit(true)
																	}}
																>
																	<img src={editBtn} alt="editar" />
																</Button>
																<Mutation
																	mutation={ELIMINAR_SUCURSAL}
																	onCompleted={() => {
																		handleCloseDelete()
																		refetch()
																	}}
																>
																	{(eliminarSucursal) => (
																		<Button
																			variant="danger"
																			onClick={() => {
																				setShowDelete(true)
																				setId(id)
																				setInfo({ direccion, eliminarSucursal })
																			}}
																			style={{ marginLeft: '10px' }}
																		>
																			<img src={deleteBtn} alt="eliminar" />
																		</Button>
																	)}
																</Mutation>
															</>
														)}
													</div>
												</Card.Footer>
											</Card>
										)
									})}
								</SucursalesDiv>
							</div>
							{permiso < 4 && <Lateral setShowAdd={setShowAdd} />}
						</>
					)
				}}
			</Query>
		</>
	)
}

export default Sucursales
