import { Mutation, useMutation, useQuery } from 'react-apollo'
import { SERVICIO_PRESTADOS_GENERAL, SERVICIO_PRESTADO_SUCURSAL } from '../../queries'
import { Button, Card } from 'react-bootstrap'
import Loading from '../Loading'
import Alta from './Ordenes/Alta'
import { useState } from 'react'
import deleteBtn from './../../img/delete.svg'

import { ANULAR_OT, VISTO_OT } from '../../mutations'
import AnularOT from './AnularOT'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faPaperclip, faCheck } from '@fortawesome/free-solid-svg-icons'
import ExcelExport from './ExcelExport'
import { OrdenesStyleDiv } from '../Style/Style'
import Filter from './Filter'
const OrdenesDeTrabajo = ({ idSucursal, idCliente, handleShowOrden, handleIdOrden, general, home, permisosOrdenesDeTrabajo, permiso, permisosNoti, search, email }) => {
	const [showAdd, setShowAdd] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [showFilter, setShowFilter] = useState(false)
	const [minDate, setMinDate] = useState('')
	const [maxDate, setMaxDate] = useState('')
	const [id, setId] = useState('')
	const [info, setInfo] = useState('')
	const download = true
	const [limit, setLimit] = useState(200)
	const [vistoOT] = useMutation(VISTO_OT)
	const handleCloseAdd = () => {
		setShowAdd(false)
	}

	const handleCloseDelete = () => {
		setShowDelete(false)
		setId('')
		setInfo('')
	}

	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const handleVisto = (id, visto, refetch, permiteNoti) => {
		if (permiteNoti === true && visto !== true) {
			vistoOT({ variables: { id } })
			refetch()
		}
	}

	let consulta
	if (idSucursal && idCliente) {
		consulta = SERVICIO_PRESTADO_SUCURSAL
	} else {
		consulta = SERVICIO_PRESTADOS_GENERAL
	}

	const variables = {
		search,
		id: idSucursal ? idSucursal : '',
		auxiliar: permisosOrdenesDeTrabajo ? permisosOrdenesDeTrabajo : '',
		email: permiso === 5 ? email : '',
		limite: limit,
		offset: 0,
	}
	const { loading, error, data, refetch } = useQuery(consulta, {
		variables: variables,
	})
	if (loading) return <Loading />
	if (error) return console.log(error.message)
	return (
		<>
			{showAdd && <Alta refetch={refetch} handleCloseAdd={handleCloseAdd} idSucursal={idSucursal} idCliente={idCliente} />}
			{showFilter && <Filter showFilter={showFilter} handleCloseFilter={handleCloseFilter} minDate={minDate} setMinDate={setMinDate} maxDate={maxDate} setMaxDate={setMaxDate} refetch={refetch} />}
			{showDelete && <AnularOT info={info} handleCloseDelete={handleCloseDelete} id={id} refetch={refetch} />}
			<OrdenesStyleDiv general={general} home={home}>
				<Card>
					<Card.Header className="title_orden">
						<div className="ambos">
							<div className="titulo">Órdenes de trabajo</div>
							{!home && permiso === 1 && (
								<div>
									<Button
										variant="success"
										onClick={() => {
											setShowFilter(true)
										}}
										style={{ fontSize: '0.8em' }}
									>
										Filtro
									</Button>
								</div>
							)}
							<div className="export">{download && <ExcelExport data={data} variables={variables} minDate={minDate} maxDate={maxDate} />}</div>
						</div>
						{home && (
							<Button className="mas_detalle" as={Link} to={`/ordenes_de_trabajo`}>
								Ver más
							</Button>
						)}
						{!home && permiso === 1 && (
							<span className="add_orden" onClick={() => setShowAdd(true)}>
								+
							</span>
						)}
					</Card.Header>
					<Card.Body>
						<div className="body_table">
							<table>
								<thead>
									<tr>
										<th>OT- N°</th>
										{general === 1 && home !== 0 && <th style={{ width: '18%' }}>Fecha</th>}
										{general !== 1 && home !== 1 && <th>Fecha</th>}

										{general === 1 && <th>{permiso !== 5 ? 'Cliente' : 'Locaciones'}</th>}
										{!home && <th>Estado</th>}
										{!home && <th></th>}
									</tr>
								</thead>
							</table>
							<div className="contenedor_table_body">
								<table>
									<tbody>
										{general === 1 &&
											data.getServiciosPrestados.map((item) => {
												const { id, nOrden, fecha, estado, adjunto, visto } = item
												let permiteNoti = false
												item.servicios.map((e) => {
													if (e.auxiliar === permisosNoti) return (permiteNoti = true)
													return false
												})
												const color = estado === 'COMPLETADO' ? '#28A745' : estado === 'ANULADO' ? '#dc3545' : ''
												return (
													<tr
														key={id}
														onClick={() => {
															handleShowOrden(item.idCliente.id, item.idSucursal.id)
															handleIdOrden(id)
															handleVisto(id, visto, refetch, permiteNoti)
														}}
													>
														<td>
															<span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{nOrden}</span>
														</td>
														<td>
															<span style={{ fontSize: '.93em' }}>{fecha.split(' ')[0]}</span>
														</td>
														<td>
															<span style={{ fontSize: '.93em' }}>{`${item.idCliente?.razonSocial} (${item.idSucursal?.nombreEstablecimiento})`}</span>
														</td>
														{!home && (
															<td className="centrado">
																<button
																	style={{ backgroundColor: color }}
																	onClick={() => {
																		handleShowOrden(item.idCliente.id, item.idSucursal.id)
																		handleIdOrden(id)
																		handleVisto(id, visto, refetch, permiteNoti)
																	}}
																>
																	{estado} {adjunto && adjunto.length > 0 && <FontAwesomeIcon icon={faPaperclip} />}
																</button>
															</td>
														)}
														{!home && (
															<td>
																{permiso < 4 && (
																	<>
																		<Mutation
																			mutation={ANULAR_OT}
																			onCompleted={() => {
																				handleCloseDelete()
																				refetch()
																			}}
																		>
																			{(anularServicioPrestado) => (
																				<button
																					onClick={() => {
																						setShowDelete(true)
																						setId(id)
																						setInfo({ nOrden, anularServicioPrestado })
																					}}
																				>
																					<img src={deleteBtn} alt="" />
																				</button>
																			)}
																		</Mutation>
																	</>
																)}
															</td>
														)}
													</tr>
												)
											})}
										{general === null &&
											data.getServicioPrestadoSucursal.map((item) => {
												const { id, nOrden, fecha, estado, adjunto, visto } = item
												let permiteNoti = false
												item.servicios.map((e) => {
													if (e.auxiliar === permisosNoti) return (permiteNoti = true)
													return false
												})
												const color = estado === 'COMPLETADO' ? '#28A745' : estado === 'ANULADO' ? '#dc3545' : ''
												return (
													<tr key={id}>
														<td>
															{!visto && <FontAwesomeIcon icon={faBell} style={{ color: '#dc3545', marginRight: '3px' }} />}
															{visto && <FontAwesomeIcon icon={faCheck} style={{ color: 'rgb(40, 167, 69)', marginRight: '3px' }} />}
															<span style={{ marginLeft: '10px', fontWeight: 'bold' }}>{nOrden}</span>
														</td>
														<td>
															<span style={{ fontSize: '.93em' }}>{fecha.split(' ')[0]}</span>
														</td>
														<td className="centrado">
															<button
																style={{ backgroundColor: color }}
																onClick={() => {
																	handleShowOrden()
																	handleIdOrden(id)
																	handleVisto(id, visto, refetch, permiteNoti)
																}}
															>
																{estado} {adjunto && adjunto.length > 0 && <FontAwesomeIcon icon={faPaperclip} />}
															</button>
														</td>
														<td>
															{permiso < 4 && (
																<button
																	className={`${estado === 'ANULADO' ? 'ocultar' : ''}`}
																	onClick={() => {
																		setShowDelete(true)
																		setId(id)
																		setInfo({ nOrden })
																	}}
																>
																	<img src={deleteBtn} alt="" />
																</button>
															)}
														</td>
													</tr>
												)
											})}
									</tbody>
								</table>
								{minDate === '' && maxDate === '' && limit > 0 && (
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
										<span style={{ fontSize: '13px', color: '#c2c2c2' }}>Mostrando las útimas 200 OT</span>
										<Button
											variant="link"
											className="ver_todo"
											onClick={() => {
												setLimit(0)
											}}
										>
											Mostrar todo
										</Button>
									</div>
								)}
							</div>
						</div>
					</Card.Body>
				</Card>
			</OrdenesStyleDiv>
		</>
	)
}

export default OrdenesDeTrabajo
