import React from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import Menu from '../Menus/Menu'
import Sucursal from '../Sucursal/Sucursal'
const ClientesStyleDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 80px;
	flex-direction: row;
	margin-bottom: 30px;
`

const Home = ({ obtenerUsuario, permisoOrdenesDeTrabajo, permiso, email }) => {
	return (
		<>
			{permiso === 6 && <Redirect to="/login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo="Inicio" />
			<Sucursal
				obtenerUsuario={obtenerUsuario}
				general={1}
				home={1}
				permisosOrdenesDeTrabajo={permisoOrdenesDeTrabajo}
				email={email}
				permiso={permiso}
			/>
			<ClientesStyleDiv>
				<div className="antes_tabla"></div>
			</ClientesStyleDiv>
		</>
	)
}

export default Home
