import { v4 as uuidv4 } from 'uuid'

export const handleFileChange = (valor, setNombreOriginal, setAdjunto) => {
	let archivosNombre = []
	let archivos = []
	for (let i = 0; i < valor.length; i++) {
		const extencion = valor[i].name.split('.').pop()
		const extraerNombreOriginal = valor[i].name
		var blob = valor[i].slice(0, valor[i].size, extencion)
		var newFile = new File([blob], uuidv4() + '.' + extencion, {
			type: valor[i].type,
		})
		archivosNombre.push({ adjuntoNombreOriginal: extraerNombreOriginal, adjunto: newFile.name })
		archivos.push({ adjunto: newFile })
	}
	setNombreOriginal(archivosNombre)
	setAdjunto(archivos)
}
