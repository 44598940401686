import {
	Form,
	Row,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { ACTUALIZAR_SERVICIO } from '../../mutations'
import styled from 'styled-components'
import FormServicios from './Form'

const validate = Yup.object({
	item: Yup.number().required('El campo Item debe ser un entero'),
	nombre: Yup.string().required('El campo Nombre es requerido'),
	area: Yup.string().required('El campo Area es requerido'),
	sistema: Yup.string().required('El campo Sistema es requerido'),
	vigencia: Yup.string().required('El campo Vigencia es requerido'),
})

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 100px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormEditarServicio = ({ cliente, handleClose, refetch }) => {
	const { id, item, nombre, area, sistema, vigencia } = cliente
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Servicio</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={ACTUALIZAR_SERVICIO}
				onCompleted={() => {
					handleClose()
					refetch()
				}}
			>
				{(crearServicio) => (
					<Formik
						initialValues={{
							id,
							item,
							nombre,
							area,
							sistema,
							vigencia,
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { item, nombre, area, sistema, vigencia } = values
							const input = {
								id,
								item,
								nombre,
								area,
								sistema,
								vigencia,
							}
							return crearServicio({ variables: { input } })
						}}
					>
						{({
							errors,
							handleSubmit,
							handleChange,
							isSubmitting,
							touched,
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormServicios
										errors={errors}
										handleChange={handleChange}
										touched={touched}
										values={cliente}
									/>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button
												variant="success"
												disabled={isSubmitting}
												type="submit"
											>
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormEditarServicio
