import { useState } from 'react'
import { Redirect } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import OrdenesDeTrabajo from './OrdenesDeTrabajo'
import DetalleOrdenDeTrabajo from './DetalleOrdenDeTrabajo'
import { Button } from 'react-bootstrap'
import Menu from '../Menus/Menu'
import DatosSucursal from './DatosSucursal'
import { SucursalStyleDiv, IzquierdaDiv, DerechaDiv } from './SucursalStyle'

const Sucursal = ({ obtenerUsuario, permiso, general = null, home = null, permisosOrdenesDeTrabajo, permisosNoti, email }) => {
	const location = useLocation()
	const [idOrden, setIdOrden] = useState('')
	const [showOrden, setShowOrden] = useState(false)
	const [newIdCliente, setNewIdCliente] = useState('')
	const [newIdSucursal, setNewIdSucursal] = useState('')
	const [search, setSearch] = useState(null)

	const handleSearch = (e) => {
		setShowOrden(false)
		setSearch(e.target.value)
	}
	const handleIdOrden = (id) => {
		setIdOrden(id)
	}

	let idCliente = ''
	let idSucursal = ''
	if (general === null) {
		idCliente = location.state.idCliente
		idSucursal = location.state.id
	}

	let titulo = 'Sucursal'
	if (general) {
		titulo = 'Órdenes de trabajo'
	}
	if (home) {
		titulo = 'Inicio'
	}

	const handleShowOrden = (generalIdCliente = null, generalIdSucursal = null) => {
		if (generalIdCliente !== null && generalIdSucursal !== null) {
			setNewIdCliente(generalIdCliente)
			setNewIdSucursal(generalIdSucursal)
		}
		setShowOrden(true)
	}

	return (
		<>
			{permiso === 6 && <Redirect to="/login" />}
			{!obtenerUsuario && <Redirect to="/login" />}
			<Menu titulo={titulo} handleSearch={handleSearch} />
			<SucursalStyleDiv>
				<IzquierdaDiv general={general} home={home} className="izq">
					<div className="superior">
						{general === null && (
							<div className="legajo">
								<Button style={{ borderRadius: '4px', padding: '5px' }} as={Link} to={`/legajo/hyst/${idCliente}/${idSucursal}`}>
									Legajo HYS
								</Button>
								<Button style={{ borderRadius: '4px', padding: '5px' }} as={Link} to={`/legajo/ma/${idCliente}/${idSucursal}`}>
									Legajo MA
								</Button>
								<Button style={{ borderRadius: '4px', padding: '5px' }} as={Link} to={`/legajo/gm/${idCliente}/${idSucursal}`}>
									Legajo GM
								</Button>
							</div>
						)}
						<div className="listado">
							<OrdenesDeTrabajo
								idSucursal={idSucursal}
								idCliente={idCliente}
								handleShowOrden={handleShowOrden}
								handleIdOrden={handleIdOrden}
								general={general}
								home={home}
								permisosOrdenesDeTrabajo={permisosOrdenesDeTrabajo}
								permiso={permiso}
								permisosNoti={permisosNoti}
								handleSearch={handleSearch}
								search={search}
								email={email}
							/>
						</div>
					</div>
					{general === null && (
						<div className="datos">
							<DatosSucursal idCliente={idCliente} idSucursal={idSucursal} obtenerUsuario={obtenerUsuario} permiso={permiso} />
						</div>
					)}
				</IzquierdaDiv>
				{!home && (
					<DerechaDiv general={general} className="derecha">
						{showOrden && (
							<DetalleOrdenDeTrabajo
								idOrden={idOrden}
								idSucursal={idSucursal !== '' ? idSucursal : newIdSucursal}
								idCliente={idCliente !== '' ? idCliente : newIdCliente}
								permiso={permiso}
							/>
						)}
					</DerechaDiv>
				)}
			</SucursalStyleDiv>
		</>
	)
}

export default Sucursal
