import { Form, Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { ACTUALIZAR_SUCURSAL } from '../../mutations'
import styled from 'styled-components'
import Formulario from './Form'

const validate = Yup.object({
	auxiliarAsignado: Yup.string().required('El campo Auxiliar es requerido'),
})

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 100px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormEditarAuxSucursal = ({ sucursal, handleClose, refetch, handleNoti }) => {
	const { id } = sucursal
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Reasignar Auxiliar</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={ACTUALIZAR_SUCURSAL}
				onCompleted={() => {
					handleClose()
					refetch()
					handleNoti(2)
				}}
			>
				{(actualizarAuxiliar) => (
					<Formik
						initialValues={{
							modulo: '',
							auxiliarAsignado: '',
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { auxiliarAsignado, modulo } = values

							const input = {
								id,
								auxiliarAsignado,
								modulo: modulo === '' ? null : parseFloat(modulo.toString().replace(',', '.')),
							}
							return actualizarAuxiliar({ variables: { input } })
						}}
					>
						{({ errors, handleSubmit, handleChange, isSubmitting, touched, setFieldTouched, setFieldValue }) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<Formulario
										errors={errors}
										handleChange={handleChange}
										touched={touched}
										values={sucursal}
										setFieldTouched={setFieldTouched}
										setFieldValue={setFieldValue}
									/>
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button variant="success" disabled={isSubmitting} type="submit">
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormEditarAuxSucursal
