import {
	Form,
	Row,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
} from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Mutation } from 'react-apollo'
import { ACTUALIZAR_CLIENTE } from '../../mutations'
import styled from 'styled-components'
import FormCliente from './FormCliente'

const validate = Yup.object({
	razonSocial: Yup.string().required('El campo Razón Social es obligatorio'),
	cuit: Yup.string()
		.max(11, 'Máximo 11 caracteres, no incluir guiones ni puntos')
		.required('El campo cuit es requerido'),
	condicionIva: Yup.string().required('El campo Condición Iva es requerido'),
	telefono: Yup.string().required('El campo Teléfono es requerido'),
	email: Yup.string().required('El campo Email es requerido'),
})

const FooterButton = styled.div`
	display: flex;
	justify-content: space-between;
	& button {
		width: 100px;
	}
	button:nth-child(1) {
		margin-right: 20px;
	}
`

const FormEditarCliente = ({ cliente, handleClose, refetch, handleNoti }) => {
	const { id, razonSocial, condicionIva, cuit, telefono, email } = cliente
	return (
		<Modal show="true" centered size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Cliente</Modal.Title>
			</Modal.Header>
			<Mutation
				mutation={ACTUALIZAR_CLIENTE}
				onCompleted={() => {
					handleClose()
					refetch()
					handleNoti(2)
				}}
			>
				{(crearCliente) => (
					<Formik
						initialValues={{
							id,
							razonSocial,
							cuit,
							condicionIva,
							telefono,
							email,
						}}
						validationSchema={validate}
						onSubmit={(values) => {
							const { razonSocial, cuit, condicionIva, telefono, email } =
								values

							const input = {
								id,
								razonSocial,
								cuit,
								condicionIva,
								telefono,
								email,
							}
							return crearCliente({ variables: { input } })
						}}
					>
						{({
							errors,
							handleSubmit,
							handleChange,
							isSubmitting,
							touched,
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<FormCliente
										errors={errors}
										handleChange={handleChange}
										touched={touched}
										values={cliente}
									/>
									{/* <Row>
										<Form.Group
											className="mb-3"
											as={Col}
											controlId="razonSocial"
										>
											<Form.Label>Nombre o Razón Social</Form.Label>
											<Form.Control
												type="text"
												name="razonSocial"
												onChange={handleChange}
												placeholder="Razón Social"
												isInvalid={touched.razonSocial && errors.razonSocial}
												defaultValue={razonSocial}
											/>
											<Form.Control.Feedback type="invalid">
												{touched.razonSocial && errors.razonSocial}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3" as={Col} controlId="cuit">
											<Form.Label>Cuit</Form.Label>
											<Form.Control
												type="text"
												name="cuit"
												onChange={handleChange}
												placeholder="Cuit"
												isInvalid={touched.cuit && errors.cuit}
												defaultValue={cuit}
											/>
											<Form.Control.Feedback type="invalid">
												{touched.cuit && errors.cuit}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row></Row>
									<Row>
										<Form.Group
											className="mb-3"
											as={Col}
											controlId="condicionIva"
										>
											<Form.Label>Condición Iva</Form.Label>
											<Form.Select
												aria-label="Default select example"
												type="text"
												name="condicionIva"
												onChange={handleChange}
												isInvalid={touched.condicionIva && errors.condicionIva}
												defaultValue={condicionIva}
											>
												<option value="">Seleccione una opción</option>
												<option value="RI">Responsable Inscripto</option>
												<option value="RM">Responsable Monotributo</option>
												<option value="EX">Exento de Iva</option>
												<option value="CF">Consumidor Final</option>
												<option value="NC">No categoriza</option>
											</Form.Select>
											<Form.Control.Feedback type="invalid">
												{touched.condicionIva && errors.condicionIva}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group className="mb-3" as={Col} controlId="telefono">
											<Form.Label>Teléfono</Form.Label>
											<Form.Control
												type="text"
												name="telefono"
												onChange={handleChange}
												placeholder="Teléfono"
												isInvalid={touched.telefono && errors.telefono}
												defaultValue={telefono}
											/>
											<Form.Control.Feedback type="invalid">
												{touched.telefono && errors.telefono}
											</Form.Control.Feedback>
										</Form.Group>
									</Row>
									<Row></Row>
									<Row>
										<Form.Group
											className="mb-3 col-md-6"
											as={Col}
											controlId="email"
										>
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="text"
												name="email"
												onChange={handleChange}
												placeholder="Email"
												isInvalid={touched.email && errors.email}
												defaultValue={email}
											/>
											<Form.Control.Feedback type="invalid">
												{touched.email && errors.email}
											</Form.Control.Feedback>
										</Form.Group>
									</Row> */}
								</ModalBody>
								<ModalFooter>
									<Row>
										<FooterButton>
											<Button variant="secondary" onClick={handleClose}>
												Cancelar
											</Button>
											<Button
												variant="success"
												disabled={isSubmitting}
												type="submit"
											>
												Editar
											</Button>
										</FooterButton>
									</Row>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				)}
			</Mutation>
		</Modal>
	)
}

export default FormEditarCliente
