import { useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import { LEGAJO } from '../../queries'
import chevron from './../../img/chevron.svg'

const ItemListar = ({ handleShow, idSucursal, item, title }) => {
	let consulta = LEGAJO

	const [legajo, { data }] = useLazyQuery(consulta, {
		variables: { idSucursal, item },
		fetchPolicy: 'network-only',
	})
	useEffect(() => {
		if (data) {
			handleShow(title, data)
		}
	}, [data]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<tr>
			<td
				onClick={() => {
					legajo()
				}}
			>
				{title} <img src={chevron} alt="" />
			</td>
		</tr>
	)
}

export default ItemListar
